import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import Grid from "@material-ui/core/Grid/Grid";

interface OwnProps {
  value: string[];
  name: string;
  options: string[];
  subSection?: boolean;
  noSeparator?: boolean;
  disabled?: boolean;
  disabledFrom?: null | number;
  enableThese?: number[];
  cols?: 1 | 2 | 3 | 4;

  onChange(name: string, value: string[]): void;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
      paddingLeft: {
        paddingLeft: theme.spacing(1),
      },
    });

type Props = OwnProps & WithStyles<typeof styles>;

class AnalysisCheckboxGroup extends React.Component<Props> {
  public render() {
    const {classes, value, options,subSection,noSeparator,disabled,disabledFrom,
      enableThese=[]} = this.props;
    const cols =
        this.props.cols === 1 ? 12 :
            this.props.cols === 2 ? 6 :
                this.props.cols === 3 ? 4 :
                    this.props.cols === 4 ? 3 :
                        12;
    return (
        <div className={subSection || noSeparator?undefined:classes.separator}>
          <FormGroup>
            <Grid container className={subSection?classes.paddingLeft:undefined}>
              {options.map((option: string, index) =>
                  <Grid key={index} item xs={cols}>
                    <FormControlLabel
                        key={index}
                        value={option}
                        control={<Checkbox
                            disabled={!enableThese.includes(index) && (disabled || !!(disabledFrom && disabledFrom <= index))}
                            checked={value.indexOf(option) !== -1}
                            color="primary"
                            onChange={this.handleChange}
                        />} label={option} />
                  </Grid>,
              )}
            </Grid>
          </FormGroup>
        </div>
    );
  }

  private handleChange = (e: React.ChangeEvent<any>) => {
    const {onChange, name, value: propsValue} = this.props;
    const {value} = e.target;
    const tmpArr = [...propsValue];
    const index = tmpArr.indexOf(value);
    index === -1 ?
        tmpArr.push(value) :
        tmpArr.splice(index, 1);
    onChange(name, tmpArr);
  }
}

// @ts-ignore
export default withStyles(styles)(AnalysisCheckboxGroup) as React.ComponentClass<OwnProps>;
