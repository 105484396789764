import {Theme, WithStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import * as React from "react";
import {WithAppContext, withAppContext} from "../../context/AppContext";
import '../../main.css';
import {WithModuleContext, withModuleContext} from "../../context/ModuleContext";
import Gap from "../Gap";
import Typography from "@material-ui/core/Typography/Typography";
import Card from "@material-ui/core/Card/Card";
import Grid from "@material-ui/core/Grid/Grid";


const styles = (theme: Theme) =>
    createStyles({
        card: {
            backgroundColor: theme.palette.primary.light,
            display: "flex",
            padding: "5px",
            margin: "25px 25px 25px 25px",
            justifyContent: "center",
            alignContent: "center",
            flexDirection: "column",
            width: "50%",
            height: "400px"
        },
        cardContent: {
            flexGrow: 1,
        },
        cardGrid: {
            padding: `${theme.spacing(8)}px 0`,
        },
        cardMedia: {
            paddingTop: "56.25%", // 16:9
        },
        grid: {
            transition: "width 0.7s",
            width: "auto",
        },
        avatar: {
            backgroundColor: theme.palette.secondary.main,
            margin: theme.spacing(1),
        },
        form: {
            marginTop: theme.spacing(1),
            width: "100%", // Fix IE11 issue.
        },
        layout: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            marginTop: theme.spacing(4),
            width: "auto",
            [theme.breakpoints.up(1100 + theme.spacing(6))]: {
                marginLeft: "auto",
                marginRight: "auto",
                width: 1100,
            },
        },
        paper: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(8),
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
            backgroundColor: '#ffffff',
        },
        submit: {
            marginTop: theme.spacing(3),
        },
        errorText: {
            marginTop: theme.spacing(1),
        },
        photoContainer: {
          width: "100%",
          height: "auto",
          textAlign: "center",
          justifyContent: "center"
        },
        imgPerson: {
            maxWidth: "100%",
            maxHeight: "100%"
        },
        twoPeople: {
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center"
        },
        person: {
            display: "flex",
            flexDirection: "column"
        }
    });

interface OwnProps {
}


interface Props extends OwnProps, WithStyles<typeof styles>, WithAppContext, WithModuleContext {
}

class About extends React.Component<Props> {

    public render() {
        const {classes, appContext: {loading} } = this.props;
        return (
            <main className={classes.layout}>
            <Paper className={classes.paper}>
                <Gap size={20} />
                <p>ECG ONLINE was developed by the Divisions of Cardiology and Clinical Skills at the University of Cape
                    Town to enhance the ECG learning experience of students and health care practitioners.</p>

                <p>ECG ONLINE enables the user to practise systematic ECG analysis and receive immediate structured
                    feedback. This interactive online platform offers modules of variable difficulty levels, with high
                    quality ECGs that were carefully selected by an experienced Faculty:</p>
                <p/><br/>
                <div className={classes.twoPeople}>
                    <Card className={classes.card}>
                        <div className="caption">
                            <h4>Rachel Weiss</h4>
                        </div>
                        <div className={classes.photoContainer}>
                            <img className={classes.imgPerson} src={require("../../assets/rachel.png")} alt="Rachel" />
                        </div>
                        <p>Rachel Weiss is the head of the UCT Clinical Skills Centre.
                            After having worked as a generalist medical doctor in various health care settings, she pursued
                            post-graduate degrees in Higher Education as well as Health Sciences Education.
                            Her research interests include patient-centered communication and curriculum development.
                        </p>
                    </Card>
                    <Card className={classes.card}>
                        <div className="caption">
                            <h4>Mpiko Ntsekhe</h4>
                        </div>
                        <div className={classes.photoContainer}>
                            <img className={classes.imgPerson} src={require("../../assets/mpiko.png")} alt="Mpiko" />
                        </div>
                        <p>Mpiko Ntsekhe is a clinical cardiologist with wide ranging interests.
                            He is Professor and Chair of Cardiology at UCT and
                            Head of Cardiology Clinical Services at Groote Schuur Hospital.
                        </p>
                    </Card>
                </div>
                <div className={classes.twoPeople}>
                    <Card className={classes.card}>
                        <div className="caption">
                            <h4>Rob Scott Millar</h4>
                        </div>
                        <div className={classes.photoContainer}>
                            <img className={classes.imgPerson} src={require("../../assets/rob.png")} alt="Rob" />
                        </div>
                        <p>Rob Scott Millar is an emeritus associate professor in the Division of Cardiology at UCT.
                            He has more than 40 years of experience in the investigation and treatment of arrhythmias.
                            He remains actively involved in undergraduate and postgraduate teaching of ECGs and is the author of
                            The ECG Atlas of Cardiac Rhythms.
                        </p>
                    </Card>
                    <Card className={classes.card}>
                        <div className="caption">
                            <h4>Ashley Chin</h4>
                        </div>
                        <div className={classes.photoContainer}>
                            <img className={classes.imgPerson} src={require("../../assets/ashley.png")} alt="Ashley" />
                        </div>
                        <p>Ashley Chin is a cardiologist and electrophysiologist at UCT with a special interest in the catheter
                            ablation of cardiac arrhythmias and implantation of cardiac devices.
                            He is the current president of the Cardiac Arrhythmia Society of Southern Africa (CASSA).
                        </p>
                    </Card>
                </div>
                <div className={classes.twoPeople}>
                    <Card className={classes.card}>
                        <div className="caption">
                            <h4>Charle Viljoen</h4>
                        </div>
                        <div className={classes.photoContainer}>
                            <img className={classes.imgPerson} src={require("../../assets/charle.png")} alt="Charl" />
                        </div>
                        <p>Charle Viljoen is a specialist physician with keen interest in medical education.
                            He is a clinical research fellow in the Division of Cardiology at UCT and reading for a PhD on novel
                            teaching modalities in electrocardiography.
                        </p>
                    </Card>

                </div>
                <div className="row margin-b-2">
                    <div className="col-xs-12 largerfont">
                        <p/><br/>
                        <p>For further ECG reading, we recommend The ECG Atlas of Cardiac Rhythms by Professor Rob Scott Millar. The
                            book contains high quality 12-lead ECGs with an analysis and explanation with important teaching points
                            for each ECG.</p>

                        <p>
                            The ECG Atlas of Cardiac Rhythms by Professor Rob Scott Millar can be ordered <a
                            href="http://ecgrhythmsatlas.com" target="_bew">here</a>.
                        </p>
                    </div>
                </div>
                <div><br/>If you have any comments or suggestions, please contact us on <a href="mailto:ecgonline.uct@gmail.com">
                    ecgonline.uct@gmail.com</a></div>
            </Paper>
            </main>

        )
    }

}

// @ts-ignore
export default withStyles(styles)(withAppContext(withModuleContext(About))) as unknown as React.ComponentClass<OwnProps>;





