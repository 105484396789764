import {LectureActions, ModuleActions} from "../../constants/Actions";

export const listLecturesFn = () => ({
    type: LectureActions.getLectures,
});

export const createLectureFn = (payload:any) => ({
    payload,
    type: LectureActions.createLecture,
});

export const deleteLectureFn = (payload:any) => ({
    payload,
    type: LectureActions.deleteLecture,
});
