import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import {AnalysisStep1, IrregularSelectionOptions, QRSWidthOptions, RateOptions, RegularityOptions} from "../../../types/Analysis";
import AnalysisRadioGroup from "../components/AnalysisRadioGroup";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";

interface OwnProps {
  values: AnalysisStep1;
  onChange: (values:AnalysisStep1)=>void
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
    });

type Props = OwnProps & WithStyles<typeof styles>;

class Step1 extends React.Component<Props> {

  public render() {
    const {values: {ratePerMinute, rate,regularity,qrsWidth,irregularSelection}} = this.props;
    return (
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h6" gutterBottom>Ventricular rate</Typography>
            <TextField
                label="Rate Per Minute"
                inputProps={{min: 0, type: "number",style: {fontSize: 12}}}
                value={ratePerMinute}
                onChange={this.handleTextInputChange("ratePerMinute")}
            />
            <AnalysisRadioGroup value={rate || null} onChange={this.handleChange} name="rate" options={RateOptions}/>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">Regularity</Typography>
            <AnalysisRadioGroup value={regularity || null} onChange={this.handleChange} name="regularity" options={RegularityOptions}/>
            {regularity === "Irregular" && <AnalysisCheckboxGroup subSection value={irregularSelection as string[]} onChange={this.handleChange} name="irregularSelection" options={IrregularSelectionOptions}/>}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6">QRS width</Typography>
            <AnalysisRadioGroup value={qrsWidth || null} onChange={this.handleChange} name="qrsWidth" options={QRSWidthOptions}/>
          </Grid>
        </Grid>
    );
  }

  private handleTextInputChange = (name: keyof AnalysisStep1) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values,[name]:value});
  };

  private handleChange = (name: keyof AnalysisStep1, value:string | string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "regularity") {
        if (value !== "Irregular") {
          // @ts-ignore
          this.props.onChange({...this.props.values,[name]:value,irregularSelection:[]});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  };
}

// @ts-ignore
export default withStyles(styles)(Step1) as React.ComponentClass<OwnProps>;
