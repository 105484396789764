import * as React from "react";
import makeStyles from '@material-ui/core/styles/makeStyles';

interface LoaderProps {
  width?: number;
  height?: number;
  loops?: number;
}

const useStyles = makeStyles({

      loader: {
        animation: [
          "draw",
          "2s",
          "linear"].join(" "),
        stroke: "#fff",
        strokeDasharray: 175,
        strokeDashoffset: "350px",
      },
    });

const dimensions = {
  height: 128,
  width: 108,
};

const Loader = (props: LoaderProps) => {
  const {width, height, loops} = props;
  const classes = useStyles();
  return (
      <svg width={width ? width : dimensions.width} height={height ? height : dimensions.height} viewBox="0 0 54 64" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <path
              className={classes.loader}
              style={{animationIterationCount: loops ? loops : "infinite"}}
              d="M0.5,38.5 L16,38.5 L19,25.5 L24.5,57.5 L31.5,7.5 L37.5,46.5 L43,38.5 L53.5,38.5"
              id="Path-2"
              strokeWidth="2" />
        </g>
      </svg>
  )
};

export default Loader;
