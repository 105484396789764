import React from "react";

interface Props {
  size?: number;
}
const Gap = (props:Props) => {
  const {
    size = 5,
  } = props;
  return(
    <div style={{padding: size}} />
  )
};

export default Gap;
