import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";
import {
  AnalysisTWave,
  TWaveAbnormalityOptions, TWaveAbnormalitySubItemsOptions,
    TWaveCol1Options, TWaveCol2Part1Options, TWaveCol2Part2Options, TWaveCol3Options, TWaveInversionOptions, TWaveInversionSubItemsOptions,
} from "../../../types/Analysis";
interface OwnProps {
  values: AnalysisTWave;
  onChange: (values:AnalysisTWave)=>void;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
      leftPadding: {
        paddingLeft: theme.spacing(2),
      },
    });


type Props = OwnProps & WithStyles<typeof styles>;

const initialState = {
  tWaveCol1: [],
  tWaveCol2Part1: [],
  tWaveInversion: [],
  tWaveInversionSubItems: [],
  tWaveCol2Part2: [],
  tWaveAbnormality: [],
  tWaveAbnormalitySubItems: [],
  tWaveCol3: [],
};

class TWaveStep extends React.Component<Props> {
  public render() {
    const {classes,values:{tWaveAbnormality,tWaveAbnormalitySubItems,tWaveCol1,tWaveCol2Part1,tWaveCol2Part2,tWaveCol3,tWaveInversion,tWaveInversionSubItems}} = this.props;
    const disabled = this.checkTWaveCol1Value();
    return (
        <Grid container>
          <Grid item xs={3}>
            <AnalysisCheckboxGroup value={tWaveCol1 as string[]} onChange={this.handleChange} name="tWaveCol1" options={TWaveCol1Options}/>
          </Grid>
          <Grid item xs={5} className={classes.leftPadding} >
            <AnalysisCheckboxGroup disabled={disabled} value={tWaveCol2Part1 as string[]} onChange={this.handleChange} name="tWaveCol2Part1" options={TWaveCol2Part1Options}/>
            <AnalysisCheckboxGroup disabled={disabled} noSeparator value={tWaveInversion as string[]} onChange={this.handleChange} name="tWaveInversion" options={TWaveInversionOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} cols={4} subSection value={tWaveInversionSubItems as string[]} onChange={this.handleChange} name="tWaveInversionSubItems" options={TWaveInversionSubItemsOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} noSeparator value={tWaveCol2Part2 as string[]} onChange={this.handleChange} name="tWaveCol2Part2" options={TWaveCol2Part2Options}/>
          </Grid>
          <Grid item xs={4} className={classes.leftPadding} >
            <AnalysisCheckboxGroup disabled={disabled} value={tWaveAbnormality as string[]} onChange={this.handleChange} name="tWaveAbnormality" options={TWaveAbnormalityOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} subSection value={tWaveAbnormalitySubItems as string[]} onChange={this.handleChange} name="tWaveAbnormalitySubItems" options={TWaveAbnormalitySubItemsOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} noSeparator value={tWaveCol3 as string[]} onChange={this.handleChange} name="tWaveCol3" options={TWaveCol3Options}/>
          </Grid>
        </Grid>
    );
  }

  private checkTWaveCol1Value = () => {
    const {tWaveCol1} = this.props.values;
    // @ts-ignore
    return tWaveCol1.indexOf("Normal T waves") !== -1;
  };

  private handleChange = (name: keyof AnalysisTWave, value:string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "tWaveCol1") {
        if (value.indexOf("Normal T waves") !== -1) {
          this.props.onChange({...initialState,[name]:["Normal T waves"]});
          this.setState({...initialState,[name]: ["Normal T waves"]});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  }

}

// @ts-ignore
export default withStyles(styles)(TWaveStep) as React.ComponentClass<OwnProps>;
