export const validatorIsEmail = (str:string) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(str);
export const validatorIsPassword = (str:string) => str.length >= 4;
export const hexToRgb = (hex: string) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    b: parseInt(result[3], 16),
    g: parseInt(result[2], 16),
    r: parseInt(result[1], 16),
  } : null;
};
export const hexToRgbA = (hex: string, alpha: number) => {
  const rgb = hexToRgb(hex);
  return rgb?`rgba(${rgb.r},${rgb.g},${rgb.b},${alpha})`:null;
};

export const convertObjectFieldsFromJSON = (obj:any) => {
  const newObj:any = {};
  Object.keys(obj).forEach(v => {
    try {
      newObj[v] = JSON.parse(obj[v]);
    } catch (e) {
      newObj[v] = (v !== "answer_id" && v !== "module_id" && typeof obj[v] !== "object") ? String(obj[v]) : obj[v];
      // newObj[v] = obj[v];
    }
  });
  return newObj;
};

/* Email Validator */
const rEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const validateEmail = (email:string) => rEmail.test(email);

/* Password Validator */
// 1 Number and 7 characters and no spaces
const rPassword = /^((?=.*[a-z])|(?=.*[A-Z]))(?=.*[0-9])(?=.{6,})/;
export const validatePassword = (password:string) => rPassword.test(password);

type ConsoleColors = 'lightGreen' | 'white' | 'lightBlue' | 'black';
export const helperRenderConsoleText = (
    message: string,
    background: ConsoleColors,
    color?: ConsoleColors
) => {
  return [`%c${message}`, `background: ${background};color: ${color ? color : 'black'};`];
};

export const onMobile = () => {
  const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const iw = (iOS) ? screen.width : window.innerWidth;
  console.log('onMobile ', iw);
  return iw < 578;
}
