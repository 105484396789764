import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {blue, deepOrange} from '@material-ui/core/colors';

export const Colors = {
  white: '#ffffff',
};

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue['200'],
      // main: blue["500"],
      // main: '#042466',
      main: '#184E85',
    },
    secondary: deepOrange,
  },
  typography: {
    fontSize: 12
  },
  overrides: {
    MuiDialog: {
      paperWidthSm: {
        minWidth: 400
      }
    },
    MuiRadio: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiInputLabel: {
      shrink: {
        transform: 'translate(0, 1.5px) scale(0.8)'
      }
    },
    MuiCheckbox: {
      root: {
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 8,
        paddingRight: 8,
      },
    },
    MuiSvgIcon: {
      root: {
        width: 15,
        height: 15,
      },
    },
    MuiFormControlLabel: {
      label: {
        fontSize: 12,
      },
    },
    MuiTab: {
      root: {
        '@media (min-width: 960px)': {
          minWidth: 100
        }
      }
    }
  },
});
