import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {createStyles, Theme} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import {AnalysesListProps} from "./AnalysesList.container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import {ECGSheet, Module, StudentResult} from "../../types/module";
import * as moment from "moment";
import Icon from "../../assets/icons";

type Data = {
  answerId: number,
  answerName: string,
  completed: string,
  datetime: string,
  moduleName: string,
  studentAnswerId: number,
  moduleId: number,
  student: string,
};

function createData(
  answerId: number,
  answerName: string,
  completed: boolean,
  datetime: string,
  moduleName: string,
  studentAnswerId: number,
  moduleId: number,
  studentName: string,
): Data {
  return {answerId, answerName, completed: completed ? "Finished" : "In Progress", datetime, moduleName, studentAnswerId, moduleId,student:studentName};
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K,
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface HeadRow {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headRows: HeadRow[] = [
  // {id: "studentAnswerId", numeric: true, disablePadding: false, label: "Student Answer ID"},
  {id: "moduleName", numeric: false, disablePadding: false, label: "Module"},
  {id: "answerName", numeric: false, disablePadding: false, label: "ECG Sheet"},
  {id: "datetime", numeric: false, disablePadding: false, label: "Date Time"},
  {id: "student", numeric: false, disablePadding: false, label: "Student"},
  {id: "completed", numeric: false, disablePadding: false, label: "Completed?"},
  // {id: "answerId", numeric: true, disablePadding: false, label: "Answer ID"},
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isStudent: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort, isStudent } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  const filteredHeadRows = isStudent?headRows.filter(v=>v.id !== 'student'):headRows;
  // const filteredHeadRows = headRows;
  return (
    <TableHead>
      <TableRow>
        {filteredHeadRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
  }),
);

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {numSelected} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    layout: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(4),
      width: "auto",
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 1100,
      },
    },
  }),
);

const AnalysesListLayout = (props: AnalysesListProps) => {

  /* State definitions */
  const [studentResults, setStudentResults] = useState<StudentResult[]>([]);
  const [modules, setModules] = useState<Module[]>([]);
  const [ecgSheets, setEcgSheets] = useState<ECGSheet[]>([]);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("studentAnswerId");
  const [selected, setSelected] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const {
    moduleContext: {
      getModulesFn,
      getStudentResults,
      getECGsFn,
      selectModule,
    },
    appContext: {
      isStudent
    },
    handleEditAnalysis,
  } = props;
  const loading = false;
  const classes = useStyles();

  useEffect(() => {
    const newResults: StudentResult[] = [];
    const newEcgSheets: ECGSheet[] = [];
    getModulesFn().then((value) => {
      setModules(value);
      value.forEach((mod) => {
        getECGsFn(mod.id).then(results => {
          results.forEach(ecg => {
            newEcgSheets.push(ecg);
          });
          setEcgSheets([...ecgSheets, ...newEcgSheets]);
        });
        getStudentResults(mod.id).then(results => {
          results.forEach(result => {
            newResults.push(result);
          });
          setStudentResults([...studentResults, ...newResults]);
        });
      })
    });
  }, []);

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Data) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  function handleSelectAllClick(event: React.ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      const newSelected = studentResults.map(n => String(n.studentAnswerId));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  }

  const handleClick = (answerId: number, studentAnswerId: number, moduleId: number, isMarking: boolean) => () => {
    const selectedModule = modules.find(v => v.id === moduleId);
    selectedModule !== undefined && selectModule(selectedModule).then(() => {
      const viewAnswer = handleEditAnalysis(answerId, studentAnswerId, isMarking);
      viewAnswer();
    });
  };

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }


  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, studentResults.length - page * rowsPerPage);
  const rows = studentResults.map(result => {
    const moduleName = modules.find(v => v.id === result.moduleId);
    const answerName = ecgSheets.find(v => v.id === result.answerId);
    return createData(
      result.answerId,
      answerName ? answerName.name : "",
      result.completed,
      result.datetime,
      moduleName ? moduleName.name : "",
      result.studentAnswerId,
      result.moduleId,
      [result.student.name,result.student.surname].join(' '),
    );
  });
  return (
    <React.Fragment>
      <main>
        {loading && <LinearProgress variant="query" />}
        <div className={classes.layout}>
          <Grid container={true} direction="column">
            <Typography variant="h4">Previous Analyses</Typography>
            <Grid container={true} spacing={8} style={{marginTop: 8}}>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <div className={classes.tableWrapper}>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        isStudent={isStudent || false}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={studentResults.length}
                      />
                      <TableBody>
                        {stableSort(rows, getSorting(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                          const isItemSelected = isSelected(String(row.studentAnswerId));
                          // @ts-ignore
                          const dateTime = moment(row.datetime).format("DD/MM/YYYY HH:MM");
                          // const dateTime = moment(row.datetime).format("YYYY-MM-DD HH:MM");
                          return (
                            <TableRow
                              hover
                              // onClick={handleClick(row.answerId, row.studentAnswerId, row.moduleId)}
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={String(row.studentAnswerId)}
                              selected={isItemSelected}
                            >
                              <TableCell>{row.moduleName}</TableCell>
                              <TableCell style={{fontSize: "80%"}}>{row.answerName}</TableCell>
                              <TableCell>{dateTime}</TableCell>
                              {!isStudent && <TableCell>{row.student}</TableCell>}
                              <TableCell>{row.completed}</TableCell>
                              <TableCell>
                                {(isStudent || (row.completed === "Finished")?
                                  <IconButton onClick={handleClick(row.answerId, row.studentAnswerId, row.moduleId, row.completed === "Finished")}>
                                  <Icon variant={row.completed === "Finished" ? "visibility" : "edit"} />
                                </IconButton>:null)}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 49 * emptyRows}}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
    </React.Fragment>
  );
};

export default AnalysesListLayout;
