import {AuthActions, PersistActions} from "../../constants/Actions";

interface MainState {
  reHydrated: boolean;
}

const initialState:MainState = {
  reHydrated: false,
};
interface Action {
  type: AuthActions.Logout & PersistActions.PersistRehydrate;
}

export default function reducer(state:MainState = initialState, action:Action) {
  const {type} = action;
  switch (type) {
    case PersistActions.PersistRehydrate: {
      return {...state, reHydrated: true}
    }
    case AuthActions.Logout: {
      return {...initialState}
    }
    default:
      return state;
  }
}
