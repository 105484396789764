import {Theme, WithStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";

import * as React from "react";
import {WithAppContext, withAppContext} from "../../context/AppContext";
import '../../main.css';
import {WithModuleContext, withModuleContext} from "../../context/ModuleContext";
import Loader from "../Loader";

const styles = (theme: Theme) =>
    createStyles({
        avatar: {
            backgroundColor: theme.palette.secondary.main,
            margin: theme.spacing(1),
        },
        form: {
            marginTop: theme.spacing(1),
            width: "100%", // Fix IE11 issue.
        },
        layout: {
            display: "block", // Fix IE11 issue.
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
            width: "auto",
            [theme.breakpoints.up(400 + theme.spacing(6))]: {
                marginLeft: "auto",
                marginRight: "auto",
                width: 400,
            },
            height: '100%'
        },
        paper: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            marginTop: theme.spacing(8),
            padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
            backgroundColor: '#92ceff',
        },
        submit: {
            marginTop: theme.spacing(3),
        },
        errorText: {
            marginTop: theme.spacing(1),
        }
    });

interface OwnProps {
    closeTerms : () => void
    showLoader: boolean
}


interface Props extends OwnProps, WithStyles<typeof styles>, WithAppContext, WithModuleContext {
}

class UCTTerms extends React.Component<Props> {

    public render() {
        const {classes, appContext: {loading}, closeTerms } = this.props;
        return (
            <div>
                <p>The user acknowledges and agrees to use ECG ONLINE on the following terms, and to immediately desist from doing so if the said terms do not apply for any reason whatsoever:</p>
                <ol>

                    <li>The information and content contained in ECG ONLINE, including templates, text, tables and illustrations, is to be used for educational purposes only and is not a substitute for sound clinical judgment or professional advice.</li>
                    <li>The authors have endeavoured to present the information in this web application in a form which is as complete and accurate as possible, but do not warrant or represent either that it is complete or accurate.</li>
                    <li>The user hereby indemnifies the authors and developers of the web application against any and all loss and damages (including, but not limited to; consequential, direct, indirect, special, punitive or incidental damages and damages or losses of any other kind) howsoever arising from any cause whatsoever arising out of or relation to the use of ECG ONLINE or the information contained therein.</li>
                    <li>The user undertakes not to reproduce or in any manner publish ECG ONLINE or the information contained therein.</li>
                    <li>The user undertakes not to copy or adapt or in any way reproduce or in any manner publish ECG ONLINE or the information contained therein.</li>
                </ol>
                    <p>© University of Cape Town</p>

                <Button
                        fullWidth={true}
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={closeTerms}
                    >
                        I agree
                    </Button> 
                </div>
           )
    }

}

// @ts-ignore
export default withStyles(styles)(withAppContext(withModuleContext(UCTTerms))) as unknown as React.ComponentClass<OwnProps>;
