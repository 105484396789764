import {LectureActions} from "../../constants/Actions";
import Lectures from "../../components/Lectures/Lectures";

export interface LectureReducer {
    lectures: [],
    lecture: Lecture
}
export interface Lecture {
    id: number;
    video: string;
}


export interface LectureState {
    lectures: Lecture[] | null,
    lecture: any | null,
    deleted: boolean | null,
    loading: boolean;
}

const initialState: LectureState = {
    lectures: null,
    lecture: null,
    deleted: null,
    loading: false,
};

interface Action {
    type: LectureActions;
    payload: any;
    data: any;
}

export default function reducer(state: LectureState = initialState, action: Action) {
    const {type, payload, data} = action;
    switch (type) {
        case LectureActions.getLecturesSuccess: {
            return {...state, lectures: data, deleted: null}
        }
        case LectureActions.createLectureSuccess: {
            return {...state, lecture: data, deleted: null}
        }
        case LectureActions.deleteLectureSuccess: {
            return {...state, deleted: true}
        }
        default:
            return state;
    }
}
