import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/Card/Card";
import CardActions from "@material-ui/core/CardActions/CardActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Grid from "@material-ui/core/Grid/Grid";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import Zoom from "@material-ui/core/Zoom/Zoom";
import * as React from "react";
import {ECGImage} from "../../../../redux/reducers/moduleReducer";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import Typography from "@material-ui/core/Typography/Typography";
import {ECGSheet, StudentResult} from "../../../../types/module";
import {WithAppContext, withAppContext} from "../../../../context/AppContext";
import {EditAnalysisFunction} from "../../Main";

const styles = () =>
    createStyles({
      card: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
      },
      cardContent: {
        flexGrow: 1,
      },
      grid: {
        transition: "width 0.7s",
        width: "auto",
      },
      ECGImage: {
        height: 100,
      },
      ECGImagePlaceHolder: {
        minHeight: 100,
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    });

// TODO: fix types
interface State {
  anchorEl: any;
}

interface OwnProps {
  removeFn: any;
  handleAddEditSheet: any;
  sheet: ECGSheet;
  image: ECGImage | undefined;
  editAnalysisFunction: EditAnalysisFunction;
  studentResults: StudentResult[]
}

interface Props extends OwnProps, WithStyles<typeof styles>, WithAppContext {}

class ECGCard extends React.Component<Props, State> {
  public state = {
    anchorEl: null,
  };

  public render() {
    const {anchorEl} = this.state;
    const {classes, sheet, image, editAnalysisFunction,
    appContext:{isStudent},studentResults} = this.props;
    const {name, overview, id} = sheet;
    // studentResults.length && console.log(studentResults);
    const filtered = studentResults.filter(v=>!v.completed);
    const testInProgressId = filtered.length?filtered[filtered.length -1].studentAnswerId:null;
    // console.log(this.props,filtered);
    return (
        <Grid className={classes.grid} item={true} sm={12} md={6}>
          <Zoom in={true}>
            <Card className={classes.card}>
              {image && <CardMedia image={image.image} className={classes.ECGImage} />}
              {!image && <Typography align="center" variant="subtitle1" className={classes.ECGImagePlaceHolder} />}
              <CardContent style={{flex: 1}}>
                {!isStudent && <Typography variant="h6" component="h2">
                  {name}
                </Typography>}
                {/*<Typography gutterBottom={true} variant="caption">{calibration ? `Scale 1:${calibration}` : "Not Calibrated"}</Typography>*/}
                <Typography component="p">
                  {overview}
                </Typography>
              </CardContent>
              <CardActions>
                <Grid container={true} direction="row" justify="space-evenly">
                  {!isStudent && <Button
                      size="small"
                      color="primary"
                      aria-haspopup="true"
                      onClick={this.handleClick}
                  >
                    Edit ECG
                  </Button>}
                  <Button size="small" color="primary" onClick={editAnalysisFunction(id,testInProgressId)}>
                    {isStudent?
                      testInProgressId !== null?"Continue analysis":"Analyse this ECG":"Edit Analysis"}
                  </Button>
                  {/*<Button size="small" color="primary" disabled={true}>*/}
                  {/*  {isStudent?*/}
                  {/*    "View Previous Analyses":"View Analyses"}*/}
                  {/*</Button>*/}
                </Grid>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                >
                  <MenuItem onClick={this.handleHandleAddEditSheet(true, sheet, 0)}>Edit ECG name</MenuItem>
                  <MenuItem onClick={this.handleHandleAddEditSheet(true, sheet, 1)}>Upload ECG</MenuItem>
                  {/*<MenuItem disabled={!image} onClick={this.handleHandleAddEditSheet(true, sheet, 1, true)}>Calibrate ECG</MenuItem>*/}
                  <MenuItem onClick={this.handleHandleAddEditSheet(true, sheet, 2)}>Upload take home message</MenuItem>
                  <MenuItem onClick={this.handleHandleAddEditSheet(true, sheet, 3)}>Edit case scenario</MenuItem>
                  <MenuItem onClick={this.handleRemoveModule(sheet)}>Remove ECG card</MenuItem>
                </Menu>
              </CardActions>
            </Card>
          </Zoom>
        </Grid>
    )
  }

  private handleRemoveModule = (sheet: any) => () => {
    const {removeFn} = this.props;
    this.handleClose();
    removeFn(sheet);
  };

  private handleHandleAddEditSheet = (addEdit: any, sheet: any, initialStep: any, calibrateMode?: boolean) => () => {
    const {handleAddEditSheet} = this.props;
    this.handleClose();
    handleAddEditSheet(addEdit, sheet, initialStep, calibrateMode)
  };

  private handleClick = (event: React.MouseEvent) => {
    this.setState({anchorEl: event.currentTarget});
  };

  private handleClose = () => {
    this.setState({anchorEl: null});
  };
}

// @ts-ignore
export default withStyles(styles)(withAppContext(ECGCard)) as React.ComponentClass<OwnProps>;
