import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
import * as React from 'react';
import "typeface-roboto";
import Login from "./containers/Login";
import Main from "./containers/Main/Main";
import {theme} from "./theme/mainTheme";
import {WithAppContext, withAppContext} from "./context/AppContext";
// @ts-ignore
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`


interface OwnProps {
}

interface Props extends OwnProps, WithAppContext {
}

class App extends React.Component<Props> {

  public render() {
    const {appContext: {authToken, terms}} = this.props;
    console.log('terms', terms);
    return (
        <MuiThemeProvider {...{theme}}>
          <CssBaseline />
          {(!authToken || (authToken && !terms)) && <Login />}
          {authToken && terms && <Main />}
        </MuiThemeProvider>
    );
  }
}

export default withAppContext(App) as unknown as React.ComponentClass<OwnProps>;
