import * as React from "react";

import Visibility from "@material-ui/icons/Visibility";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import Delete from "@material-ui/icons/Delete";

export const VisibilityIcon = Visibility;
export const EditIcon = Edit;

export type IconVariants =
  "delete" |
  "edit" |
  "remove" |
  "visibility";

const icons = {
  delete: Delete,
  edit: Edit,
  remove: Remove,
  visibility: Visibility,
};

interface Props {
  variant: IconVariants;
  className?: string;
  style?: React.CSSProperties;
}

const Icon = (props:Props)=> {
  const {variant} = props;
  const style = props.style || {} as React.CSSProperties;
  const TheIcon = icons[variant];
  return(
    <TheIcon style={style}/>
  )
};

export default Icon;
