import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import {AnalysisPRInterval, PRIntervalOptions, PRLengthOptions, PRSegmentOptions} from "../../../types/Analysis";
import AnalysisRadioGroup from "../components/AnalysisRadioGroup";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Grow from "@material-ui/core/Grow/Grow";

interface OwnProps {
  values: AnalysisPRInterval;
  onChange: (values:AnalysisPRInterval)=>void;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
    });


type Props = OwnProps & WithStyles<typeof styles>;

const initialState = {
  prInterval: null,
  prIntervalMeasure: "",
  prLength: null,
  prSegment: [],
};

class PRIntervalStep extends React.Component<Props> {

  public render() {
    const {classes,values: {prInterval, prIntervalMeasure, prLength, prSegment}} = this.props;
    return (
        <Grid container>
          <Grid item xs={5}>
            <Typography variant="h6" gutterBottom>Measuring the PR interval</Typography>
            <Grid container>
              <Grid item xs={6}>
                <AnalysisRadioGroup value={prInterval} onChange={this.handleChange} name="prInterval" options={PRIntervalOptions} />
              </Grid>
              <Grow in={prInterval === "Constant"}>
                <Grid item xs={6}>
                  <TextField
                      label="PR interval"
                    InputProps={{endAdornment:<InputAdornment position="end" ><Typography style={{fontSize: 12}}>ms</Typography></InputAdornment>}}
                    inputProps={{min: 0, type: "number",style: {fontSize: 12}}}
                      value={prIntervalMeasure}
                      onChange={this.handleTextInputChange("prIntervalMeasure")}
                  />
                  <AnalysisRadioGroup value={prLength} onChange={this.handleChange} name="prLength" options={PRLengthOptions} />
                </Grid>
              </Grow>
            </Grid>
            <div className={classes.separator} />
          </Grid>
          <Grid item xs={1} />
            <Grid item xs={5}>
              <Typography variant="h6">PR segment</Typography>
              <AnalysisCheckboxGroup value={prSegment as string[]} onChange={this.handleChange} name="prSegment" options={PRSegmentOptions} />
            </Grid>
        </Grid>
    );
  }

  private handleTextInputChange = (name: keyof AnalysisPRInterval) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values,[name]:value});
  };

  private handleChange = (name: keyof AnalysisPRInterval, value: string | string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "prInterval") {
        if (value !== "Constant") {
          // @ts-ignore
          this.props.onChange({...initialState,prSegment:this.props.values.prSegment,[name]:value});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  };
}

// @ts-ignore
export default withStyles(styles)(PRIntervalStep) as React.ComponentClass<OwnProps>;
