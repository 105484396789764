import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from "react-redux";
import App from './App';
import ConfirmEmail from "./components/ConfirmEmail";
import store from "./redux";
import registerServiceWorker from './registerServiceWorker';
import Context from "./context/Context";
import { BrowserRouter as Router, Route, Switch  } from 'react-router-dom';

const AppWithStore = <Provider store={store}>
  <Context>
    <Router>
      <Switch>
      <Route exact path="/confirm/:code" component={ConfirmEmail} />
      <Route exact path="/" component={App} />
      </Switch>
    </Router>
  </Context>
</Provider>;

// @ts-ignore
ReactDOM.render(AppWithStore, document.getElementById('root') as HTMLElement);
registerServiceWorker();

