import Dialog from '@material-ui/core/Dialog/Dialog';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {TransitionProps} from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide/Slide';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar/AppBar';
import {makeStyles} from '@material-ui/styles';
import {createStyles, Theme} from '@material-ui/core/styles';
import {User, UserTypes} from '../../types/app';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import {TextField} from '@material-ui/core';
import Gap from '../../components/Gap';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';


export const userTitles = ['Mr', 'Mrs', 'Ms', 'Dr', 'Prof'];
export const userTypes = ['Junior Lecturer', 'Student', 'Senior Lecturer', 'Admin', 'Tech Support'];
export const convertUserType = function (userType: string | undefined): UserTypes {
  return userType === 'Junior Lecturer' ?
    1 :
    userType === 'Student' ?
      2 :
      userType === 'Senior Lecturer' ?
        3 :
        userType === 'Admin' ?
          4 :
          5;
};

export type UserDialogActions = 'add' | 'edit' | 'delete' | 'register' | 'myAccount';

interface FormValidity {
  title: boolean;
  firstname: boolean;
  surname: boolean;
  email: boolean;
  userType: boolean;
  password?: boolean;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useUserDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      padding: 40,
      paddingTop: 20
    }
  }),
);

interface Props {
  open: boolean;
  action: UserDialogActions;
  onClose: () => void;
  user: User;
  handleSave: (user: User) => void;
  handleDelete?: (user: User) => void;
}

const UserDialog = (props: Props) => {
  const {
    open, action, user: {
      title = 'Mr',
      firstname = '',
      surname = '',
      uctNumber = '',
      email = '',
      userType = 'Student',
      activated = props.action === 'myAccount'
    }, user, handleSave,
    handleDelete = ()=>{}
  } = props;

  const [localUser, setLocalUser] = useState<User>({...user, title, firstname, surname, uctNumber, email, userType, activated});
  const [valid, setValid] = useState<FormValidity>({
    title: true,
    firstname: true,
    surname: true,
    email: true,
    userType: true,
    password: true,
  });
  const [isValid, setIsValid] = useState<boolean>(true);
  const [changePassword, setChangePassword] = useState<boolean>(false);
  const classes = useUserDialogStyles();

  const handleChangePassword = () => {
    setLocalUser({...localUser, password: ''});
    setChangePassword(true);
  };

  const handleClose = () => {
    const {onClose} = props;
    onClose();
  };

  const handleChangeInput = (field: keyof User) => (e: ChangeEvent<HTMLInputElement>) => {
    setLocalUser({...localUser, [field]: e.target.value});
  };

  const handleSelectChange = (field: keyof User) => (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    setLocalUser({...localUser, [field]: e.target.value});
  };
  const handleCheckboxChange = (field: keyof User) => () => {
    setLocalUser({...localUser, [field]: !localUser.activated});
  };

  useEffect(() => {
    const tmpValid = {
      title: true,
      firstname: true,
      surname: true,
      email: true,
      userType: true,
      password: true,
    };
    let tmpIsValid = true;
    tmpValid.title = userTitles.includes(localUser.title || '');
    tmpIsValid = tmpIsValid ? tmpValid.title : tmpIsValid;
    tmpValid.firstname = !!localUser.firstname;
    tmpIsValid = tmpIsValid ? tmpValid.firstname : tmpIsValid;
    tmpValid.surname = !!localUser.surname;
    tmpIsValid = tmpIsValid ? tmpValid.surname : tmpIsValid;
    tmpValid.email = (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(localUser.email));
    tmpIsValid = tmpIsValid ? tmpValid.email : tmpIsValid;
    tmpValid.userType = userTypes.includes(localUser.userType || '');
    tmpIsValid = tmpIsValid ? tmpValid.userType : tmpIsValid;
    tmpValid.password = action === 'add' ? !!localUser.password : action === 'myAccount' && changePassword ? !!localUser.password : true;
    tmpIsValid = tmpIsValid ? tmpValid.password : tmpIsValid;
    setValid(tmpValid);
    setIsValid(tmpIsValid);
  }, [localUser]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap={true} className={classes.title}>
            {action === 'add' ?
              'Add new user' :
              action === 'myAccount' ?
                'Update My Account' :
                action === 'register' ?
                  'Register new user' :
                  action === 'delete' ?
                    'Delete User' :
                    `Update - ${user.firstname} ${user.surname}`}
          </Typography>
          <IconButton onClick={handleClose}>
            <Close style={{color: 'white'}} />
          </IconButton>
        </Toolbar>
        {/*<Fade in={analysisLoading || imageLoading}><LinearProgress variant="query" /></Fade>*/}
      </AppBar>
      <DialogContent className={classes.content}>
        {action === 'delete' ?
          <Typography>Are you sure you would like to delete {localUser.firstname} {localUser.surname}?</Typography> :
          <>
            <FormControl>
              <InputLabel>Title</InputLabel>
              <Select
                value={localUser.title}
                onChange={handleSelectChange('title')}
              >
                <MenuItem value="Mr">Mr</MenuItem>
                <MenuItem value="Mrs">Mrs</MenuItem>
                <MenuItem value="Ms">Ms</MenuItem>
                <MenuItem value="Dr">Dr</MenuItem>
                <MenuItem value="Prof">Prof</MenuItem>
              </Select>
            </FormControl>
            <Gap />
            <TextField label="First Name" error={!valid.firstname} onChange={handleChangeInput('firstname')} value={localUser.firstname} />
            <Gap />
            <TextField label="Surname" error={!valid.surname} onChange={handleChangeInput('surname')} value={localUser.surname} />
            {/*<Gap />*/}
            {/*<TextField label="UCT Number" onChange={handleChangeInput("uctNumber")} value={localUser.uctNumber} />*/}
            <Gap />
            <TextField label="Email" error={!valid.email} onChange={handleChangeInput('email')} value={localUser.email} />
            <Gap />
            {action !== 'myAccount' && action !== 'register' && <FormControl>
              <InputLabel>User Role</InputLabel>
              <Select
                value={localUser.userType}
                onChange={handleSelectChange('userType')}
              >
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Junior Lecturer">Junior Lecturer</MenuItem>
                <MenuItem value="Senior Lecturer">Senior Lecturer</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </FormControl>}
            <Gap />
            {(action === 'add' || action === 'register' || changePassword) && <TextField label="Password" error={!valid.password} onChange={handleChangeInput('password')} type="password" value={localUser.password} />}
            {action !== 'myAccount' && action !== 'add' && action !== 'register' && <FormControlLabel
              control={
                <Checkbox checked={localUser.activated} onChange={handleCheckboxChange('activated')} value={true} />
              }
              label="Activated"
            />}
          </>
        }
      </DialogContent>
      <DialogActions>
        {action === 'myAccount' && !changePassword && <Button onClick={handleChangePassword} color="primary">
          Change Password
        </Button>}
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => action === 'delete'?handleDelete(localUser):handleSave(localUser)} color="primary" disabled={!isValid}>
          {action === 'edit' ?
            'Update' :
            action === 'register' ?
              'Register' :
              action === 'delete' ?
                'Confirm' :
                'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default UserDialog;
