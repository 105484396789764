import {Theme, WithStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import * as React from "react";
import Loader from "../components/Loader";
import {onMobile, validateEmail} from "../constants/Helpers";
import {WithAppContext, withAppContext} from "../context/AppContext";
import TextField from "@material-ui/core/TextField";
import '../main.css';
import ecgLogo from "../assets/ecgLogo.png";
import Gap from "../components/Gap";
import UserDialog from "./UserList/UserDialog";
import {User} from "../types/app";
import {WithModuleContext, withModuleContext} from "../context/ModuleContext";
import color from "@material-ui/core/colors/green";
import UCTTerms from "../components/UCTTerms/UCTTerms";

const version = require("../../package.json").version;

const styles = (theme: Theme) =>
  createStyles({
    avatar: {
      backgroundColor: theme.palette.secondary.main,
      margin: theme.spacing(1),
    },
    form: {
      marginTop: theme.spacing(1),
      width: "100%", // Fix IE11 issue.
    },
    layout: {
      display: "block", // Fix IE11 issue.
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
      [theme.breakpoints.up(400 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 400,
      },
      height: '100%'
    },
    paper: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      marginTop: theme.spacing(8),
      padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
      backgroundColor: '#92ceff',
    },
    submit: {
      marginTop: theme.spacing(3),
    },
    errorText: {
      marginTop: theme.spacing(1),
    }
  });

interface OwnProps {
}

interface LoginFields {
  email: string;
  password: string;
}

interface State extends LoginFields {
  valid: {
    email: boolean;
    password: boolean;
  },
  error: boolean,
  errorMessage: string,
  showUserDialog: boolean,
  showTerms: boolean,
  showLoader: boolean,
  registrationSuccess: boolean;
}

interface Props extends OwnProps, WithStyles<typeof styles>, WithAppContext, WithModuleContext {
}

class Login extends React.Component<Props, State> {

  public state: State = {
    // email: "jason@uct.ac.za",
    // password: "password",
    email: "",
    password: "",
    valid: {
      email: true,
      password: true,
    },
    error: false,
    errorMessage: "Invalid login details provided",
    showUserDialog: false,
    showTerms: false,
    showLoader: false,
    registrationSuccess: false
  };

  closeUserDialog = () => {
    this.setState({showUserDialog: false});
  };

  closeTerms = () => {
    console.log('here');
    const { appContext: {termsAccepted}} = this.props;
    // const {appContext: {loginFn}} = this.props;
    // const {email, password, error} = this.state;
    // this.setState({ showLoader: true, showTerms: false });
    // error && this.setState({error: false});
    // loginFn(email, password).then(e => {
    //   if (!e) this.setState({error: true});
    // });
    termsAccepted(true);
    this.setState({ showLoader: false, showTerms: false });

  };

  handleSave = (localUser: User) => {
    const {moduleContext:{
      registerUsersFn
    }} = this.props;

    registerUsersFn(localUser).then((res) => {
      console.log('res is', res);
      if (!res.hasOwnProperty('id')) {
        this.setState({error: true, registrationSuccess: false, errorMessage: "Registration fail: Email exists already"});
        this.closeUserDialog();
      } else {
        this.closeUserDialog();
        this.setState({registrationSuccess: true});

        setTimeout(()=> {
          this.setState({registrationSuccess: false});
        },6000);
      }

    });
  };

  public render() {
    const {classes, appContext: {loading}} = this.props;
    const {showTerms, email, errorMessage, password, valid, error,showUserDialog,registrationSuccess} = this.state;
    const canSubmit = valid.email && valid.password && !onMobile();
    return (<main className={classes.layout}>
      <Paper className={classes.paper}>
          <img src={ecgLogo} alt="ECG Logo" style={{width: 300}}/>
        {/*<Avatar className={classes.avatar}>*/}
        {/*  <Loader loops={1} />*/}
        {/*</Avatar>*/}
        {onMobile() && <div>For the optimal user experience, we recommend logging in from a device with a larger screen</div>}
        <Gap size={20} />
        {showTerms && <Typography variant="h5">Terms of Usage</Typography> }
        {!showTerms && !onMobile() && <Typography variant="h5">Sign In</Typography> }
        {registrationSuccess && <Typography variant="h5" style={{color: color["500"]}}>Thank you for your registration. To activate your account, please follow the link in the email that was sent to you.</Typography>}
        {!showTerms && !onMobile() && <form className={classes.form} onSubmit={this.handleSubmit}>
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Email address"
            id="email"
            name="email"
            autoComplete="email"
            autoFocus={true}
            onChange={this.handleOnChange}
            value={email}
            fullWidth
            error={!!email && !valid.email}
          />
          <TextField
            InputLabelProps={{
              shrink: true,
            }}
            label="Password"
            name="password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={this.handleOnChange}
            value={password}
            fullWidth
            error={!!password && !valid.password}
            style={{marginTop: 16}}
          />
          <Button
            disabled={!canSubmit}
            disableFocusRipple={canSubmit}
            type="submit"
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            onSubmit={this.handleSubmit}
          >
            {loading ? <Loader height={20}/> : "Sign In"}
          </Button>
          <Button
            fullWidth={true}
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={()=>this.setState({showUserDialog: true})}
          >
            Register
          </Button>
          {error && <Typography className={classes.errorText} color="error" align="center">{errorMessage}</Typography>}
        </form>}
        {showTerms && <UCTTerms showLoader={this.state.showLoader} closeTerms={this.closeTerms} />}
        <div style={{padding: "10px", marginTop: "10px"}}><a href={'mailto:ecgonline.uct@gmail.com'}>contact us</a> </div>
      </Paper>
      {showUserDialog && <UserDialog open={showUserDialog} onClose={this.closeUserDialog} action="register" user={{} as User} handleSave={this.handleSave} />}
      <div style={{position: "absolute", bottom: 10, right: 10}}><Typography variant="caption">V {version}</Typography></div>
    </main>)
  }

  private handleSubmit = (e: React.FormEvent) => {

    e.preventDefault();
    const {appContext: {loginFn}} = this.props;
    const {email, password, error} = this.state;
    this.setState({ showLoader: true, showTerms: false });
    error && this.setState({error: false});
    loginFn(email, password).then(e => {
      if (!e) {
        this.setState({error: true, errorMessage: "Invalid Login details provided"});
      } else {
        this.setState({showTerms: true, showUserDialog: false});
      }
    });
  };

  private handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {currentTarget} = e;
    const name = currentTarget.name as keyof LoginFields;
    const state: State = this.state;
    state[name] = currentTarget.value;
    state.valid[name] = currentTarget.name === "email" ? validateEmail(currentTarget.value) : true;
    this.setState({...state});
  };
}

// @ts-ignore
export default withStyles(styles)(withAppContext(withModuleContext(Login))) as unknown as React.ComponentClass<OwnProps>;
