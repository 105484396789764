import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import {AnalysisStep2} from "../../../types/Analysis";
import Typography from "@material-ui/core/Typography";

interface OwnProps {
  values: AnalysisStep2;
  onChange: (values: AnalysisStep2) => void
  isStudent: boolean;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
    });


type Props = OwnProps & WithStyles<typeof styles>;

class Step2 extends React.Component<Props> {

  public render() {
    const {values: {waveFormAnalysisMessage},isStudent} = this.props;
    return (
        <Grid container>
          <Grid item xs={12}>
            {isStudent?<Typography style={{paddingTop: 20}} align="center" variant="h5">{waveFormAnalysisMessage}</Typography>:<TextField
                label="Message"
                multiline
                rowsMax="8"
                value={waveFormAnalysisMessage}
                onChange={this.handleChange('waveFormAnalysisMessage')}
                margin="normal"
                fullWidth
            />}
          </Grid>
        </Grid>
    );
  }

  private handleChange = (name: keyof AnalysisStep2) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values,[name]:value});
  };
}

// @ts-ignore
export default withStyles(styles)(Step2) as React.ComponentClass<OwnProps>;
