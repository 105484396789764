import * as React from 'react';
import {Theme, WithStyles} from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';
import {AnalysisInterface, AnalysisPRInterval, AnalysisPWave, AnalysisQRSComplex, AnalysisQTInterval, AnalysisStep1, AnalysisStep2, AnalysisStep3, AnalysisSTSegment, AnalysisTWave} from '../../../types/Analysis';

interface OwnProps {
  values: {
    step1: AnalysisStep1;
    step2: AnalysisStep2;
    pWave: AnalysisPWave;
    prInterval: AnalysisPRInterval;
    qrsComplex: AnalysisQRSComplex;
    stSegment: AnalysisSTSegment;
    tWave: AnalysisTWave;
    qtInterval: AnalysisQTInterval;
    step3: AnalysisStep3;
  },
  correctAnswer: AnalysisInterface | null;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    nextSegment: {
      paddingTop: theme.spacing(2),
    },
  });

interface Props extends OwnProps, WithStyles<typeof styles> {
}

class AnalysisSummary extends React.Component<Props> {
  public render() {
    const {
      isMarking,
      correctAnswer
    } = this.props;
    return isMarking ?
      correctAnswer !== null ? this.renderMarkingSummary() : null :
      this.renderNormalSummary();
  }

  renderMarkingSummary = () => {
    const {
      values: {
        step1: {
          ratePerMinute, rate, regularity, irregularSelection, qrsWidth,
        },
        pWave: {
          pWave, pInRelationToQRS, pWaveAxis, pWaveMorphology,
        },
        prInterval: {
          prInterval, prIntervalMeasure, prLength, prSegment,
        },
        qrsComplex: {
          qrsComplexWidthMeasurement, qrsComplexWidth, qrsComplexAxisMeasurement, qrsComplexAxis, qrsComplexAxisCol1, qrsComplexAxisCol2,
        },
        stSegment: {
          stSegmentCol1, stSegmentElevation, stSegmentElevationSubItems, stSegmentDepression, stSegmentDepressionSubItems, stSegmentRepolarisationAbnormality, stSegmentRepolarisationAbnormalitySubItems, stSegmentCol3,
        },
        tWave: {
          tWaveCol1, tWaveCol2Part1, tWaveInversion, tWaveInversionSubItems, tWaveCol2Part2, tWaveAbnormality, tWaveAbnormalitySubItems, tWaveCol3,
        },
        qtInterval: {
          qtIntervalMeasurement, qtcIntervalMeasurement, qtInterval, wideQRSAdjustedMeasurement,
        },
      },
      classes,
      correctAnswer,
    } = this.props;
    const {
      step1: {
        ratePerMinute: correctRatePerMinute,
        rate: correctRate,
        regularity: correctRegularity,
        irregularSelection: correctIrregularSelection,
        qrsWidth: correctQrsWidth,
      },
      pWave: {
        pWave: correctPWave,
        pInRelationToQRS: correctPInRelationToQRS,
        pWaveAxis: correctPWaveAxis,
        pWaveMorphology: correctPWaveMorphology,
      },
      prInterval: {
        prInterval: correctPrInterval,
        prIntervalMeasure: correctPrIntervalMeasure,
        prLength: correctPrLength,
        prSegment: correctPrSegment,
      },
      qrsComplex: {
        qrsComplexWidthMeasurement: correctqrsComplexWidthMeasurement,
        qrsComplexWidth: correctqrsComplexWidth,
        qrsComplexAxisMeasurement: correctqrsComplexAxisMeasurement,
        qrsComplexAxis: correctqrsComplexAxis,
        qrsComplexAxisCol1: correctqrsComplexAxisCol1,
        qrsComplexAxisCol2: correctqrsComplexAxisCol2,
      },
      stSegment: {
        stSegmentCol1: correctstSegmentCol1,
        stSegmentElevation: correctstSegmentElevation,
        stSegmentElevationSubItems: correctstSegmentElevationSubItems,
        stSegmentDepression: correctstSegmentDepression,
        stSegmentDepressionSubItems: correctstSegmentDepressionSubItems,
        stSegmentRepolarisationAbnormality: correctstSegmentRepolarisationAbnormality,
        stSegmentRepolarisationAbnormalitySubItems: correctstSegmentRepolarisationAbnormalitySubItems,
        stSegmentCol3: correctstSegmentCol3,
      },
      tWave: {
        tWaveCol1: correcttWaveCol1,
        tWaveCol2Part1: correcttWaveCol2Part1,
        tWaveInversion: correcttWaveInversion,
        tWaveInversionSubItems: correcttWaveInversionSubItems,
        tWaveCol2Part2: correcttWaveCol2Part2,
        tWaveAbnormality: correcttWaveAbnormality,
        tWaveAbnormalitySubItems: correcttWaveAbnormalitySubItems,
        tWaveCol3: correcttWaveCol3,
      },
      qtInterval: {
        qtIntervalMeasurement: correctqtIntervalMeasurement,
        qtcIntervalMeasurement: correctqtcIntervalMeasurement,
        qtInterval: correctqtInterval,
        wideQRSAdjustedMeasurement: correctwideQRSAdjustedMeasurement,
      },
    } = correctAnswer as AnalysisInterface;

    return (
      <React.Fragment>
        <React.Fragment>
          {regularity !== correctRegularity && <Typography style={{textDecoration: 'line-through'}}>{regularity}</Typography>}
          {regularity && <Typography style={{fontWeight: regularity !== correctRegularity ? 'bold' : 'normal'}}>{correctRegularity}</Typography>}


          {qrsWidth !== correctQrsWidth && <Typography style={{textDecoration: 'line-through'}}>{qrsWidth}</Typography>}
          {qrsWidth && correctQrsWidth !== 'Various widths' && <Typography style={{fontWeight: qrsWidth !== correctQrsWidth ? 'bold' : 'normal'}}>{correctQrsWidth}</Typography>}


          {rate && ratePerMinute && <Typography>
            {
              <React.Fragment>
                {rate !== correctRate && <span style={{textTransform: 'capitalize', textDecoration: 'line-through'}}>{rate === 'Normal rate' ? 'Rhythm with a normal ' : rate} </span>}
                <span style={{textTransform: 'capitalize', fontWeight: rate !== correctRate ? 'bold' : 'normal'}}>{correctRate === 'Normal rate' ? 'Rhythm with a normal ' : correctRate}</span>
                <span> with a </span>
              </React.Fragment>}
            ventricular rate of
            {ratePerMinute !== correctRatePerMinute && <span style={{textDecoration: 'line-through'}}> {ratePerMinute}</span>}
            <span style={{fontWeight: ratePerMinute !== correctRatePerMinute ? 'bold' : 'normal'}}> {correctRatePerMinute}</span>
          </Typography>}


          {correctRegularity !== 'Irregular' && regularity === 'Irregular' && irregularSelection && irregularSelection.length > 0 &&
          <Typography style={{textDecoration: 'line-through'}}>The irregular rhythm is described as having {irregularSelection.length > 0 ?
            <span style={{textDecoration: 'line-through'}}> {irregularSelection.map((v, i) => <span key={i} style={{textDecoration: 'line-through'}}>{v}{(i + 1) >= irregularSelection.length ? '' : ', '}</span>)}</span> :
            null}</Typography>
          }
          {correctRegularity === 'Irregular' && regularity === 'Irregular' && correctIrregularSelection && correctIrregularSelection.length > 0 &&
          <Typography>The irregular rhythm is described as having {correctIrregularSelection.length > 0 ?
            <span> {irregularSelection && irregularSelection.map((v, i) => {
                const isWrong = correctIrregularSelection.findIndex(vv => vv === v) === -1;
                return (
                  <span style={{textDecoration: isWrong ? 'line-through' : 'none'}} key={i}>{v}{(i + 1) >= irregularSelection.length ? '' : ', '} </span>
                )
              }
            )}
              {correctIrregularSelection && correctIrregularSelection.map((v, i) => {
                const notPresent = irregularSelection && irregularSelection.findIndex(vv => vv === v) === -1;
                return notPresent ? <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correctIrregularSelection.length ? '' : ', '} </span> : null;
              })}
          </span> :
            null}</Typography>
          }
          {correctRegularity === 'Irregular' && regularity === 'Regular' && correctIrregularSelection && correctIrregularSelection.length > 0 &&
          <Typography style={{fontWeight: 'bold'}}>The irregular rhythm is described as having {correctIrregularSelection.length > 0 ?
            <span> {correctIrregularSelection.map((v, i) => <span key={i}>{v}{(i + 1) >= correctIrregularSelection.length ? '' : ', '}</span>)}</span> :
            null}</Typography>
          }


          {qrsWidth && qrsWidth === 'Various widths' && correctQrsWidth && correctQrsWidth !== 'Various widths' && <Typography style={{textDecoration: 'line-through'}}>There are various QRS widths</Typography>}
          {qrsWidth && qrsWidth !== 'Various widths' && correctQrsWidth === 'Various widths' && <Typography style={{fontWeight: 'bold'}}>There are various QRS widths</Typography>}
          {qrsWidth && qrsWidth === 'Various widths' && correctQrsWidth === 'Various widths' && <Typography>There are various QRS widths</Typography>}

        </React.Fragment>
        <div className={classes.nextSegment}>
          <React.Fragment>
            {correctPWave && correctPWave === 'Present' && pWave && pWave !== 'Present' && <Typography style={{textDecoration: 'line-through'}}>Absent P waves / unsure if present</Typography>}
            {correctPWave && correctPWave !== 'Present' && <Typography>Absent P waves / unsure if present</Typography>}


            {pWaveAxis && pWaveAxis.length > 0 ? pWaveAxis.map((v, i) => {
              const isWrong = correctPWaveAxis && correctPWaveAxis.findIndex(vv => v === vv) === -1;
              return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>P wave {(v as string).charAt(0).toLowerCase() + (v as string).slice(1)}</Typography> : null;
            }) : null}
            {correctPWaveAxis && correctPWaveAxis.map((v, i) => {
              const notPresent = pWaveAxis && pWaveAxis.findIndex(vv => vv === v) === -1;
              return <Typography style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>P wave {(v as string).charAt(0).toLowerCase() + (v as string).slice(1)}</Typography>;
            })}


            {pInRelationToQRS && pInRelationToQRS.map((v, i) => {
                const isWrong = correctPInRelationToQRS && correctPInRelationToQRS.findIndex(vv => v === vv) === -1;
                return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>
                  {v === 'P wave before every QRS' ? 'P wave before every QRS complex' :
                    v === 'QRS after every P wave' ? 'QRS complex after every P wave' :
                      v === 'P wave after QRS' ? 'P wave after QRS complex' :
                        v === 'More P waves than QRS' ? 'More P waves than QRS complexes' :
                          v === 'More QRS than P waves' ? 'More QRS complexes than P waves' :
                            v === 'P wave superimposed on QRS' ? 'P wave superimposed on QRS complex' :
                              v}
                </Typography> : null;
              }
            )}
            {correctPInRelationToQRS && correctPInRelationToQRS.map((v, i) => {
                const notPresent = pInRelationToQRS && pInRelationToQRS.findIndex(vv => vv === v) === -1;
                return <Typography key={i} style={{fontWeight: notPresent ? 'bold' : 'normal'}}>
                  {v === 'P wave before every QRS' ? 'P wave before every QRS complex' :
                    v === 'QRS after every P wave' ? 'QRS complex after every P wave' :
                      v === 'P wave after QRS' ? 'P wave after QRS complex' :
                        v === 'More P waves than QRS' ? 'More P waves than QRS complexes' :
                          v === 'More QRS than P waves' ? 'More QRS complexes than P waves' :
                            v === 'P wave superimposed on QRS' ? 'P wave superimposed on QRS complex' :
                              v}
                </Typography>;
              }
            )}


            {pWaveMorphology && pWaveMorphology.map((v, i) => {
              const isWrong = correctPWaveMorphology && correctPWaveMorphology.findIndex(vv => v === vv) === -1;
              return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>
                {v === 'Normal morphology' ? 'Normal P wave morphology' :
                  v === 'Widened / bifid in II' ? 'Widened / bifid P wave in II' :
                    v === 'Increased amplitude' ? 'Increased P wave amplitude' :
                      v === 'Decreased amplitude' ? 'Decreased P wave amplitude' :
                        v}
              </Typography> : null;
            })}
            {correctPWaveMorphology && correctPWaveMorphology.map((v, i) => {
              const notPresent = pWaveMorphology && pWaveMorphology.findIndex(vv => vv === v) === -1;
              return <Typography key={i} style={{fontWeight: notPresent ? 'bold' : 'normal'}}>
                {v === 'Normal morphology' ? 'Normal P wave morphology' :
                  v === 'Widened / bifid in II' ? 'Widened / bifid P wave in II' :
                    v === 'Increased amplitude' ? 'Increased P wave amplitude' :
                      v === 'Decreased amplitude' ? 'Decreased P wave amplitude' :
                        v}
              </Typography>;
            })}
          </React.Fragment>
        </div>
        <div className={classes.nextSegment}>
          {prInterval && prInterval === 'Irrelevant' && correctPrInterval !== 'Irrelevant' && <Typography style={{textDecoration: 'line-through'}}>The PR interval is irrelevant</Typography>}


          {correctPrInterval && correctPrInterval !== 'Irrelevant' &&
          <Typography>
            {prInterval !== correctPrInterval && prInterval !== 'Irrelevant' && <span style={{textDecoration: 'line-through'}}>{prInterval} </span>}
            {prInterval !== correctPrInterval && <span style={{fontWeight: 'bold'}}>{correctPrInterval} </span>}
            {prInterval === correctPrInterval && <span>{prInterval} </span>}
            PR interval
            {(prInterval === correctPrInterval && prIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure !== correctPrIntervalMeasure) ||
            (prInterval === correctPrInterval && correctPrIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure !== correctPrIntervalMeasure) ||
            (prInterval === correctPrInterval && correctPrIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure === correctPrIntervalMeasure) ||
            (prInterval !== correctPrInterval && correctPrIntervalMeasure && correctPrInterval === 'Constant') ? <span> measuring </span> : null}
            {prInterval === correctPrInterval && prIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure !== correctPrIntervalMeasure && <span style={{textDecoration: 'line-through'}}>{`${prIntervalMeasure} ms`}</span>}
            {prInterval === correctPrInterval && correctPrIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure !== correctPrIntervalMeasure && <span style={{fontWeight: 'bold'}}>{`${correctPrIntervalMeasure} ms`}</span>}
            {prInterval === correctPrInterval && correctPrIntervalMeasure && prInterval === 'Constant' && prIntervalMeasure === correctPrIntervalMeasure && <span>{`${correctPrIntervalMeasure} ms`}</span>}
            {prInterval !== correctPrInterval && correctPrIntervalMeasure && correctPrInterval === 'Constant' && <span style={{fontWeight: 'bold'}}>{`${correctPrIntervalMeasure} ms`}</span>}
          </Typography>}


          {prInterval && prInterval === 'Irrelevant' && correctPrInterval === 'Irrelevant' && <Typography>The PR interval is irrelevant</Typography>}


          {prLength && correctPrLength === prLength && <Typography>The PR interval is {prLength.charAt(0).toLowerCase() + prLength.slice(1)}</Typography>}
          {prLength && correctPrLength !== prLength && <Typography style={{textDecoration: 'line-through'}}>The PR interval is {prLength.charAt(0).toLowerCase() + prLength.slice(1)}</Typography>}
          {correctPrLength && correctPrLength !== prLength && <Typography style={{fontWeight: 'bold'}}>The PR interval is {correctPrLength.charAt(0).toLowerCase() + correctPrLength.slice(1)}</Typography>}


          {prSegment && <Typography>{prSegment.map((v, i) => {
            const isWrong = correctPrSegment && correctPrSegment.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{
              v === 'PR segment elevated in aVR' ? 'Elevated PR segment in aVR' :
                v === 'PR segment depressed in II' ? 'Depressed PR segment in II' :
                  v
            }</span> : null;
          })}</Typography>}
          {correctPrSegment && <Typography>{correctPrSegment.map((v, i) => {
            const notPresent = prSegment && prSegment.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{
              v === 'PR segment elevated in aVR' ? 'Elevated PR segment in aVR' :
                v === 'PR segment depressed in II' ? 'Depressed PR segment in II' :
                  v
            }<br/></span>;
          })}</Typography>}
        </div>
        <div className={classes.nextSegment}>
          {qrsComplexWidthMeasurement && <Typography>QRS width
            {correctqrsComplexWidthMeasurement === qrsComplexWidthMeasurement && <span> {qrsComplexWidthMeasurement} ms</span>}
            {correctqrsComplexWidthMeasurement !== qrsComplexWidthMeasurement && <span style={{textDecoration: 'line-through'}}> {qrsComplexWidthMeasurement} ms</span>}
            {correctqrsComplexWidthMeasurement && correctqrsComplexWidthMeasurement !== qrsComplexWidthMeasurement && <span style={{fontWeight: 'bold'}}> {correctqrsComplexWidthMeasurement} ms</span>}
          </Typography>}


          {qrsComplexWidth && qrsComplexWidth.map((v, i) => {
            const isWrong = correctqrsComplexWidth && correctqrsComplexWidth.findIndex(vv => v === vv) === -1;
            return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>{v === 'Normal morphology' ? 'Normal QRS morphology' : v}</Typography> : null;
          })}
          {correctqrsComplexWidth && correctqrsComplexWidth.map((v, i) => {
            const notPresent = qrsComplexWidth && qrsComplexWidth.findIndex(vv => vv === v) === -1;
            return <Typography style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v === 'Normal morphology' ? 'Normal QRS morphology' : v}</Typography>;
          })}


          {qrsComplexAxisMeasurement && <Typography>QRS axis
            {correctqrsComplexAxisMeasurement === qrsComplexAxisMeasurement && <span> {qrsComplexAxisMeasurement} degrees</span>}
            {correctqrsComplexAxisMeasurement !== qrsComplexAxisMeasurement && <span style={{textDecoration: 'line-through'}}> {qrsComplexAxisMeasurement} degrees</span>}
            {correctqrsComplexAxisMeasurement && correctqrsComplexAxisMeasurement !== qrsComplexAxisMeasurement && <span style={{fontWeight: 'bold'}}> {correctqrsComplexAxisMeasurement} degrees</span>}
          </Typography>}

          {qrsComplexAxis && correctqrsComplexAxis !== qrsComplexAxis && <Typography style={{textDecoration: 'line-through'}}>{qrsComplexAxis}</Typography>}
          {correctqrsComplexAxis && correctqrsComplexAxis !== qrsComplexAxis && <Typography style={{fontWeight: 'bold'}}>{correctqrsComplexAxis}</Typography>}
          {qrsComplexAxis && correctqrsComplexAxis === qrsComplexAxis && <Typography>{qrsComplexAxis}</Typography>}


          {qrsComplexAxisCol1 && qrsComplexAxisCol1.map((v, i) => {
            const isWrong = correctqrsComplexAxisCol1 && correctqrsComplexAxisCol1.findIndex(vv => v === vv) === -1;
            return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>{v}</Typography> : null;
          })}
          {correctqrsComplexAxisCol1 && correctqrsComplexAxisCol1.map((v, i) => {
            const notPresent = qrsComplexAxisCol1 && qrsComplexAxisCol1.findIndex(vv => vv === v) === -1;
            return <Typography style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</Typography>;
          })}


          {qrsComplexAxisCol2 && qrsComplexAxisCol2.map((v, i) => {
            const isWrong = correctqrsComplexAxisCol2 && correctqrsComplexAxisCol2.findIndex(vv => v === vv) === -1;
            return isWrong ? <Typography style={{textDecoration: 'line-through'}} key={i}>{
              v === 'Premature narrow complex' ? 'Premature narrow QRS complex' :
                v === 'Premature wide complex' ? 'Premature wide QRS complex' :
                  v
            }</Typography> : null;
          })}
          {correctqrsComplexAxisCol2 && correctqrsComplexAxisCol2.map((v, i) => {
            const notPresent = qrsComplexAxisCol2 && qrsComplexAxisCol2.findIndex(vv => vv === v) === -1;
            return <Typography style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{
              v === 'Premature narrow complex' ? 'Premature narrow QRS complex' :
                v === 'Premature wide complex' ? 'Premature wide QRS complex' :
                  v
            }</Typography>;
          })}
        </div>
        <div className={classes.nextSegment}>
          {stSegmentCol1 && <Typography>{stSegmentCol1.map((v, i) => {
            const isWrong = correctstSegmentCol1 && correctstSegmentCol1.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}</span> : null;
          })}</Typography>}
          {correctstSegmentCol1 && <Typography>{correctstSegmentCol1.map((v, i) => {
            const notPresent = stSegmentCol1 && stSegmentCol1.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>;
          })}</Typography>}


          {stSegmentElevation && <Typography>{stSegmentElevation.map((v, i) => {
            const isWrong = correctstSegmentElevation && correctstSegmentElevation.findIndex(vv => v === vv) === -1;
            return isWrong ?
              <React.Fragment>
                <span style={{textDecoration: 'line-through'}} key={i}>{v}</span>
                {stSegmentElevation && stSegmentElevation.length > 0 && <span style={{textDecoration: 'line-through'}}> (in {stSegmentElevationSubItems.map((v, i) => {
                  const isWrong = correctstSegmentElevationSubItems && correctstSegmentElevationSubItems.findIndex(vv => v === vv) === -1;
                  return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentElevationSubItems.length ? '' : ', '}</span> : null;
                })})</span>}
              </React.Fragment>
              : null;
          })}</Typography>}
          {correctstSegmentElevation && <Typography>{correctstSegmentElevation.map((v, i) => {
            const notPresent = stSegmentElevation && stSegmentElevation.findIndex(vv => vv === v) === -1;
            return (
              <React.Fragment>
                <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>
                {(stSegmentElevation && stSegmentElevation.length > 0) ||
                (correctstSegmentElevation && correctstSegmentElevation.length > 0) ? <span> {`(in `}
                  {stSegmentElevation && stSegmentElevation.length > 0 && stSegmentElevationSubItems.map((v, i) => {
                    const isWrong = correctstSegmentElevationSubItems && correctstSegmentElevationSubItems.findIndex(vv => v === vv) === -1;
                    return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentElevationSubItems.length ? '' : ', '} </span> : null;
                  })}
                  {correctstSegmentElevation && correctstSegmentElevation.length > 0 && correctstSegmentElevationSubItems.map((v, i) => {
                    const notPresent = stSegmentElevationSubItems && stSegmentElevationSubItems.findIndex(vv => vv === v) === -1;
                    return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correctstSegmentElevationSubItems.length ? '' : ', '} </span>;
                  })})</span> : null}
              </React.Fragment>);
          })}</Typography>}


          {stSegmentDepression && <Typography>{stSegmentDepression.map((v, i) => {
            const isWrong = correctstSegmentDepression && correctstSegmentDepression.findIndex(vv => v === vv) === -1;
            return isWrong ?
              <React.Fragment>
                <span style={{textDecoration: 'line-through'}} key={i}>{v}</span>
                {stSegmentDepression && stSegmentDepression.length > 0 && <span style={{textDecoration: 'line-through'}}> (in {stSegmentDepressionSubItems.map((v, i) => {
                  const isWrong = correctstSegmentDepressionSubItems && correctstSegmentDepressionSubItems.findIndex(vv => v === vv) === -1;
                  return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentDepressionSubItems.length ? '' : ', '}</span> : null;
                })})</span>}
              </React.Fragment>
              : null;
          })}</Typography>}
          {correctstSegmentDepression && <Typography>{correctstSegmentDepression.map((v, i) => {
            const notPresent = stSegmentDepression && stSegmentDepression.findIndex(vv => vv === v) === -1;
            return (
              <React.Fragment>
                <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>
                {(stSegmentDepression && stSegmentDepression.length > 0) ||
                (correctstSegmentDepression && correctstSegmentDepression.length > 0) ? <span> {`(in `}
                  {stSegmentDepression && stSegmentDepression.length > 0 && stSegmentDepressionSubItems.map((v, i) => {
                    const isWrong = correctstSegmentDepressionSubItems && correctstSegmentDepressionSubItems.findIndex(vv => v === vv) === -1;
                    return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentDepressionSubItems.length ? '' : ', '} </span> : null;
                  })}
                  {correctstSegmentDepression && correctstSegmentDepression.length > 0 && correctstSegmentDepressionSubItems.map((v, i) => {
                    const notPresent = stSegmentDepressionSubItems && stSegmentDepressionSubItems.findIndex(vv => vv === v) === -1;
                    return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correctstSegmentDepressionSubItems.length ? '' : ', '} </span>;
                  })})</span> : null}
              </React.Fragment>);
          })}</Typography>}


          {stSegmentRepolarisationAbnormality && <Typography>{stSegmentRepolarisationAbnormality.map((v, i) => {
            const isWrong = correctstSegmentRepolarisationAbnormality && correctstSegmentRepolarisationAbnormality.findIndex(vv => v === vv) === -1;
            return isWrong ?
              <React.Fragment>
                <span style={{textDecoration: 'line-through'}} key={i}>{v}</span>
                {stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.length > 0 && <span style={{textDecoration: 'line-through'}}> (in {stSegmentRepolarisationAbnormalitySubItems.map((v, i) => {
                  const isWrong = correctstSegmentRepolarisationAbnormalitySubItems && correctstSegmentRepolarisationAbnormalitySubItems.findIndex(vv => v === vv) === -1;
                  return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentRepolarisationAbnormalitySubItems.length ? '' : ', '}</span> : null;
                })})</span>}
              </React.Fragment>
              : null;
          })}</Typography>}
          {correctstSegmentRepolarisationAbnormality && <Typography>{correctstSegmentRepolarisationAbnormality.map((v, i) => {
            const notPresent = stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.findIndex(vv => vv === v) === -1;
            return (
              <React.Fragment>
                <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>
                {(stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.length > 0) ||
                (correctstSegmentRepolarisationAbnormality && correctstSegmentRepolarisationAbnormality.length > 0) ? <span> {`(`}
                  {stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.length > 0 && stSegmentRepolarisationAbnormalitySubItems.map((v, i) => {
                    const isWrong = correctstSegmentRepolarisationAbnormalitySubItems && correctstSegmentRepolarisationAbnormalitySubItems.findIndex(vv => v === vv) === -1;
                    return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= stSegmentRepolarisationAbnormalitySubItems.length ? '' : ', '} </span> : null;
                  })}
                  {correctstSegmentRepolarisationAbnormality && correctstSegmentRepolarisationAbnormality.length > 0 && correctstSegmentRepolarisationAbnormalitySubItems.map((v, i) => {
                    const notPresent = stSegmentRepolarisationAbnormalitySubItems && stSegmentRepolarisationAbnormalitySubItems.findIndex(vv => vv === v) === -1;
                    return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correctstSegmentRepolarisationAbnormalitySubItems.length ? '' : ', '}  </span>;
                  })})</span> : null}
              </React.Fragment>);
          })}</Typography>}


          {stSegmentCol3 && <Typography>{stSegmentCol3.map((v, i) => {
            const isWrong = correctstSegmentCol3 && correctstSegmentCol3.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}; </span> : null;
          })}</Typography>}
          {correctstSegmentCol3 && <Typography>{correctstSegmentCol3.map((v, i) => {
            const notPresent = stSegmentCol3 && stSegmentCol3.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correctstSegmentCol3.length ? '' : ', '} </span>;
          })}</Typography>}
        </div>
        <div className={classes.nextSegment}>

          {tWaveCol1 && <Typography>{tWaveCol1.map((v, i) => {
            const isWrong = correcttWaveCol1 && correcttWaveCol1.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveCol1.length ? '' : ', '} </span> : null;
          })}</Typography>}
          {correcttWaveCol1 && <Typography>{correcttWaveCol1.map((v, i) => {
            const notPresent = tWaveCol1 && tWaveCol1.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correcttWaveCol1.length ? '' : ', '}  </span>;
          })}</Typography>}


          {tWaveCol2Part1 && <Typography>{tWaveCol2Part1.map((v, i) => {
            const isWrong = correcttWaveCol2Part1 && correcttWaveCol2Part1.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveCol2Part1.length ? '' : ', '} </span> : null;
          })}</Typography>}
          {correcttWaveCol2Part1 && <Typography>{correcttWaveCol2Part1.map((v, i) => {
            const notPresent = tWaveCol2Part1 && tWaveCol2Part1.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correcttWaveCol2Part1.length ? '' : ', '} </span>;
          })}</Typography>}


          {tWaveInversion && <Typography>{tWaveInversion.map((v, i) => {
            const isWrong = correcttWaveInversion && correcttWaveInversion.findIndex(vv => v === vv) === -1;
            return isWrong ?
              <React.Fragment>
                <span style={{textDecoration: 'line-through'}} key={i}>{v}</span>
                {tWaveInversion && tWaveInversion.length > 0 && <span style={{textDecoration: 'line-through'}}> (in {tWaveInversionSubItems.map((v, i) => {
                  const isWrong = correcttWaveInversionSubItems && correcttWaveInversionSubItems.findIndex(vv => v === vv) === -1;
                  return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveInversionSubItems.length ? '' : ', '}</span> : null;
                })})</span>}
              </React.Fragment>
              : null;
          })}</Typography>}
          {correcttWaveInversion && <Typography>{correcttWaveInversion.map((v, i) => {
            const notPresent = tWaveInversion && tWaveInversion.findIndex(vv => vv === v) === -1;
            return (
              <React.Fragment>
                <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>
                {(tWaveInversion && tWaveInversion.length > 0) ||
                (correcttWaveInversion && correcttWaveInversion.length > 0) ? <span> {`(in `}
                  {tWaveInversion && tWaveInversion.length > 0 && tWaveInversionSubItems.map((v, i) => {
                    const isWrong = correcttWaveInversionSubItems && correcttWaveInversionSubItems.findIndex(vv => v === vv) === -1;
                    return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveInversionSubItems.length ? '' : ', '} </span> : null;
                  })}
                  {correcttWaveInversion && correcttWaveInversion.length > 0 && correcttWaveInversionSubItems.map((v, i) => {
                    const notPresent = tWaveInversionSubItems && tWaveInversionSubItems.findIndex(vv => vv === v) === -1;
                    return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correcttWaveInversionSubItems.length ? '' : ', '} </span>;
                  })})</span> : null}
              </React.Fragment>);
          })}</Typography>}


          {tWaveCol2Part2 && <Typography>{tWaveCol2Part2.map((v, i) => {
            const isWrong = correcttWaveCol2Part2 && correcttWaveCol2Part2.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}</span> : null;
          })}</Typography>}
          {correcttWaveCol2Part2 && <Typography>{correcttWaveCol2Part2.map((v, i) => {
            const notPresent = tWaveCol2Part2 && tWaveCol2Part2.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>;
          })}</Typography>}


          {tWaveAbnormality && <Typography>{tWaveAbnormality.map((v, i) => {
            const isWrong = correcttWaveAbnormality && correcttWaveAbnormality.findIndex(vv => v === vv) === -1;
            return isWrong ?
              <React.Fragment>
                <span style={{textDecoration: 'line-through'}} key={i}>{v}</span>
                {tWaveAbnormality && tWaveAbnormality.length > 0 && <span style={{textDecoration: 'line-through'}}> (in {tWaveAbnormalitySubItems.map((v, i) => {
                  const isWrong = correcttWaveAbnormalitySubItems && correcttWaveAbnormalitySubItems.findIndex(vv => v === vv) === -1;
                  return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveAbnormalitySubItems.length ? '' : ', '}</span> : null;
                })})</span>}
              </React.Fragment>
              : null;
          })}</Typography>}
          {correcttWaveAbnormality && <Typography>{correcttWaveAbnormality.map((v, i) => {
            const notPresent = tWaveAbnormality && tWaveAbnormality.findIndex(vv => vv === v) === -1;
            return (
              <React.Fragment>
                <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>
                {(tWaveAbnormality && tWaveAbnormality.length > 0) ||
                (correcttWaveAbnormality && correcttWaveAbnormality.length > 0) ? <span> {`(in `}
                  {tWaveAbnormality && tWaveAbnormality.length > 0 && tWaveAbnormalitySubItems.map((v, i) => {
                    const isWrong = correcttWaveAbnormalitySubItems && correcttWaveAbnormalitySubItems.findIndex(vv => v === vv) === -1;
                    return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}{(i + 1) >= tWaveAbnormalitySubItems.length ? '' : ', '} </span> : null;
                  })}
                  {correcttWaveAbnormality && correcttWaveAbnormality.length > 0 && correcttWaveAbnormalitySubItems.map((v, i) => {
                    const notPresent = tWaveAbnormalitySubItems && tWaveAbnormalitySubItems.findIndex(vv => vv === v) === -1;
                    return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}{(i + 1) >= correcttWaveAbnormalitySubItems.length ? '' : ', '} </span>;
                  })})</span> : null}
              </React.Fragment>);
          })}</Typography>}


          {tWaveCol3 && <Typography>{tWaveCol3.map((v, i) => {
            const isWrong = correcttWaveCol3 && correcttWaveCol3.findIndex(vv => v === vv) === -1;
            return isWrong ? <span style={{textDecoration: 'line-through'}} key={i}>{v}</span> : null;
          })}</Typography>}
          {correcttWaveCol3 && <Typography>{correcttWaveCol3.map((v, i) => {
            const notPresent = tWaveCol3 && tWaveCol3.findIndex(vv => vv === v) === -1;
            return <span style={{fontWeight: notPresent ? 'bold' : 'normal'}} key={i}>{v}</span>;
          })}</Typography>}
        </div>
        <div className={classes.nextSegment}>

          {qtIntervalMeasurement && <Typography>Measured QT interval of
            {correctqtIntervalMeasurement === qtIntervalMeasurement && <span> {qtIntervalMeasurement} ms</span>}
            {correctqtIntervalMeasurement !== qtIntervalMeasurement && <span style={{textDecoration: 'line-through'}}> {qtIntervalMeasurement} ms</span>}
            {correctqtIntervalMeasurement !== qtIntervalMeasurement && <span style={{fontWeight: 'bold'}}> {correctqtIntervalMeasurement} ms</span>}
          </Typography>}

          {qtcIntervalMeasurement && qtcIntervalMeasurement !== '0' && <Typography>
            {qtInterval && qtInterval === correctqtInterval && <span>{correctqtInterval}</span>}
            {qtInterval && qtInterval !== correctqtInterval && <span style={{textDecoration: 'line-through'}}>{qtInterval}</span>}
            {qtInterval && qtInterval !== correctqtInterval && <span style={{fontWeight: 'bold'}}> {correctqtInterval}</span>}
            {!qtInterval && correctqtInterval && <span style={{fontWeight: 'bold'}}>{correctqtInterval}</span>}
            <span> QTc interval of </span>
            {correctqtcIntervalMeasurement === qtcIntervalMeasurement && <span> {qtcIntervalMeasurement} ms</span>}
            {correctqtcIntervalMeasurement !== qtcIntervalMeasurement && <span style={{textDecoration: 'line-through'}}> {qtcIntervalMeasurement} ms</span>}
            {correctqtcIntervalMeasurement !== qtcIntervalMeasurement && <span style={{fontWeight: 'bold'}}> {correctqtcIntervalMeasurement} ms</span>}
          </Typography>}


          {correctwideQRSAdjustedMeasurement && correctwideQRSAdjustedMeasurement === wideQRSAdjustedMeasurement && <Typography>Wide QRS adjusted QTc {correctwideQRSAdjustedMeasurement} ms</Typography>}
          {wideQRSAdjustedMeasurement && correctwideQRSAdjustedMeasurement !== wideQRSAdjustedMeasurement && <Typography style={{textDecoration: 'line-through'}}>Wide QRS adjusted QTc {wideQRSAdjustedMeasurement} ms</Typography>}
          {correctwideQRSAdjustedMeasurement && correctwideQRSAdjustedMeasurement !== wideQRSAdjustedMeasurement && <Typography style={{fontWeight: 'bold'}}>Wide QRS adjusted QTc {correctwideQRSAdjustedMeasurement} ms</Typography>}
        </div>
      </React.Fragment>
    );
  };

  renderNormalSummary = () => {
    const {
      values: {
        step1: {
          ratePerMinute, rate, regularity, irregularSelection, qrsWidth,
        },
        pWave: {
          pWave, pInRelationToQRS, pWaveAxis, pWaveMorphology,
        },
        prInterval: {
          prInterval, prIntervalMeasure, prLength, prSegment,
        },
        qrsComplex: {
          qrsComplexWidthMeasurement, qrsComplexWidth, qrsComplexAxisMeasurement, qrsComplexAxis, qrsComplexAxisCol1, qrsComplexAxisCol2,
        },
        stSegment: {
          stSegmentCol1, stSegmentElevation, stSegmentElevationSubItems, stSegmentDepression, stSegmentDepressionSubItems, stSegmentRepolarisationAbnormality, stSegmentRepolarisationAbnormalitySubItems, stSegmentCol3,
        },
        tWave: {
          tWaveCol1, tWaveCol2Part1, tWaveInversion, tWaveInversionSubItems, tWaveCol2Part2, tWaveAbnormality, tWaveAbnormalitySubItems, tWaveCol3,
        },
        qtInterval: {
          qtIntervalMeasurement, qtcIntervalMeasurement, qtInterval, wideQRSAdjustedMeasurement,
        },
      },
      classes,
    } = this.props;
    return (
      <React.Fragment>
        {regularity && <Typography>{regularity}</Typography>}
        {qrsWidth && qrsWidth !== 'Various widths' && <Typography>{qrsWidth}</Typography>}
        {rate && ratePerMinute && <Typography>
          {rate === 'Normal rate' ?
            'Rhythm with a normal ' : <React.Fragment><span style={{textTransform: 'capitalize'}}>{rate}</span><span> with a </span></React.Fragment>}
          ventricular rate of {ratePerMinute}</Typography>}
        {regularity === 'Irregular' && irregularSelection && irregularSelection.length > 0 &&
        <Typography>The irregular rhythm is described as having {irregularSelection.length > 0 ?
          <span> {irregularSelection.map((v, i) => <span key={i}>{v}{(i + 1) >= irregularSelection.length ? '' : ', '}</span>)}</span> :
          null}</Typography>
        }
        {qrsWidth && qrsWidth === 'Various widths' && <Typography>There are various QRS widths</Typography>}
        <div className={classes.nextSegment}>
          {pWave && pWave !== 'Present' && <Typography>Absent P waves / unsure if present</Typography>}
          {pWaveAxis && pWaveAxis.length > 0 ? pWaveAxis.map((v, i) =>
            <Typography key={i}>P wave {(v as string).charAt(0).toLowerCase() + (v as string).slice(1)}</Typography>) : null}
          {pInRelationToQRS && pInRelationToQRS.length > 0 ? pInRelationToQRS.map((v, i) =>
            <Typography key={i}>
              {v === 'P wave before every QRS' ? 'P wave before every QRS complex' :
                v === 'QRS after every P wave' ? 'QRS complex after every P wave' :
                  v === 'P wave after QRS' ? 'P wave after QRS complex' :
                    v === 'More P waves than QRS' ? 'More P waves than QRS complexes' :
                      v === 'More QRS than P waves' ? 'More QRS complexes than P waves' :
                        v === 'P wave superimposed on QRS' ? 'P wave superimposed on QRS complex' :
                          v}
            </Typography>
          ) : null
          }
          {pWaveMorphology && pWaveMorphology.length > 0 ? pWaveMorphology.map((v, i) =>
            <Typography key={i}>
              {v === 'Normal morphology' ? 'Normal P wave morphology' :
                v === 'Widened / bifid in II' ? 'Widened / bifid P wave in II' :
                  v === 'Increased amplitude' ? 'Increased P wave amplitude' :
                    v === 'Decreased amplitude' ? 'Decreased P wave amplitude' :
                      v}
            </Typography>) : null}
        </div>
        <div className={classes.nextSegment}>
          {prInterval && prInterval !== 'Irrelevant' && <Typography>{prInterval} PR interval {prIntervalMeasure && prInterval === 'Constant' ? `measuring ${prIntervalMeasure} ms` : null}</Typography>}
          {prInterval && prInterval === 'Irrelevant' && <Typography>The PR interval is irrelevant</Typography>}
          {prLength && <Typography>The PR interval is {prLength.charAt(0).toLowerCase() + prLength.slice(1)}</Typography>}
          {prSegment && prSegment.length > 0 ? <Typography>{prSegment.map((v, i) => <span key={i}>{
            v === 'PR segment elevated in aVR' ? 'Elevated PR segment in aVR' :
              v === 'PR segment depressed in II' ? 'Depressed PR segment in II' :
                v
          }{(i + 1) >= prSegment.length ? '' : ', '}</span>)}</Typography> : null}
        </div>
        <div className={classes.nextSegment}>
          {qrsComplexWidthMeasurement && <Typography>QRS width {qrsComplexWidthMeasurement} ms</Typography>}
          {qrsComplexWidth && qrsComplexWidth.map((v, i) => <Typography key={i}>{v === 'Normal morphology' ? 'Normal QRS morphology' : v}</Typography>)}
          {qrsComplexAxisMeasurement && <Typography>QRS axis {qrsComplexAxisMeasurement} degrees</Typography>}
          {qrsComplexAxis && <Typography>{qrsComplexAxis}</Typography>}
          {qrsComplexAxisCol1 && qrsComplexAxisCol1.map((v, i) => <Typography key={i}>{v}</Typography>)}
          {qrsComplexAxisCol2 && qrsComplexAxisCol2.length > 0 ? <Typography>{qrsComplexAxisCol2.map((v, i) => <span key={i}>{
            v === 'Premature narrow complex' ? 'Premature narrow QRS complex' :
              v === 'Premature wide complex' ? 'Premature wide QRS complex' :
                v
          }{(i + 1) >= qrsComplexAxisCol2.length ? '' : ', '}</span>)}</Typography> : null}
        </div>
        <div className={classes.nextSegment}>
          {stSegmentCol1 && stSegmentCol1.length > 0 ? <Typography>{stSegmentCol1.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentCol1.length ? '' : ', '}</span>)}</Typography> : null}
          {stSegmentElevation && stSegmentElevation.length > 0 ? <Typography>{stSegmentElevation.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentElevation.length ? '' : ', '}</span>)} {stSegmentElevation && stSegmentElevationSubItems.length > 0 ?
            <span> (in {stSegmentElevationSubItems.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentElevationSubItems.length ? '' : ', '}</span>)})</span> :
            null}</Typography> : null}
          {stSegmentDepression && stSegmentDepression.length > 0 ? <Typography>{stSegmentDepression.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentDepression.length ? '' : ', '}</span>)} {stSegmentDepression && stSegmentDepressionSubItems.length > 0 ?
            <span> (in {stSegmentDepressionSubItems.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentDepressionSubItems.length ? '' : ', '}</span>)})</span> :
            null}</Typography> : null}
          {stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.length > 0 ? <Typography>{stSegmentRepolarisationAbnormality.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentRepolarisationAbnormality.length ? '' : ', '}</span>)} {stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormalitySubItems.length > 0 ?
            <span> {stSegmentRepolarisationAbnormalitySubItems.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentRepolarisationAbnormalitySubItems.length ? '' : ' / '}</span>)}</span> :
            null}</Typography> : null}
          {stSegmentCol3 && stSegmentCol3.length > 0 ? <Typography>{stSegmentCol3.map((v, i) => <span key={i}>{v}{(i + 1) >= stSegmentCol3.length ? '' : ', '}</span>)}</Typography> : null}
        </div>
        <div className={classes.nextSegment}>
          {tWaveCol1 && tWaveCol1.length > 0 ? <Typography>{tWaveCol1.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveCol1.length ? '' : ', '}</span>)}</Typography> : null}
          {tWaveCol2Part1 && tWaveCol2Part1.map((v, i) => <Typography key={i}>{v}</Typography>)}
          {tWaveInversion && tWaveInversion.length > 0 ? <Typography>{tWaveInversion.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveInversion.length ? '' : ', '}</span>)} {tWaveInversion && tWaveInversionSubItems.length > 0 ?
            <span> (in {tWaveInversionSubItems.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveInversionSubItems.length ? '' : ', '}</span>)})</span> :
            null}</Typography> : null}
          {tWaveCol2Part2 && tWaveCol2Part2.map((v, i) => <Typography key={i}>{v}</Typography>)}
          {tWaveAbnormality && tWaveAbnormality.length > 0 ? <Typography>{tWaveAbnormality.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveAbnormality.length ? '' : ', '}</span>)} {tWaveAbnormality && tWaveAbnormalitySubItems.length > 0 ?
            <span> {tWaveAbnormalitySubItems.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveAbnormalitySubItems.length ? '' : ' / '}</span>)}</span> :
            null}</Typography> : null}
          {tWaveCol3 && tWaveCol3.length > 0 ? <Typography>{tWaveCol3.map((v, i) => <span key={i}>{v}{(i + 1) >= tWaveCol3.length ? '' : ', '}</span>)}</Typography> : null}
        </div>
        <div className={classes.nextSegment}>
          {qtIntervalMeasurement && <Typography>Measured QT interval of {qtIntervalMeasurement} ms</Typography>}
          {qtcIntervalMeasurement && qtcIntervalMeasurement !== '0' && <Typography>{qtInterval ? `${qtInterval} ` : ''}QTc interval of {qtcIntervalMeasurement} ms</Typography>}
          {wideQRSAdjustedMeasurement && <Typography>Wide QRS adjusted QTc {wideQRSAdjustedMeasurement} ms</Typography>}
        </div>
      </React.Fragment>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(AnalysisSummary) as React.ComponentClass<OwnProps>;
