import * as React from "react";
import AppContext from "./AppContext";
import ModuleContext from "./ModuleContext";
import ECGContext from "./ECGContext";

interface OwnProps {
}

type Props = OwnProps;

class Context extends React.Component<Props> {
  public render() {
    const {children} = this.props;
    return (
      <AppContext>
        <ModuleContext>
          <ECGContext>
            {children}
          </ECGContext>
        </ModuleContext>
      </AppContext>
    );
  }
}

export default (Context) as React.ComponentClass<OwnProps>;
