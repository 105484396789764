import * as React from "react";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import {Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {Document, Page} from "react-pdf";

interface OwnProps {
  docData: any;
  handleSubmit: (e: React.FormEvent)=>void;

  handleChange(e: React.ChangeEvent<any>): void;

}

const styles = (theme: Theme) =>
    createStyles({
      button: {
        margin: theme.spacing(1),
      },
      form: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
      },
      input: {
        display: "none",
      },
    });

class StepThreeUploadTakeHome extends React.Component<OwnProps & WithStyles<typeof styles>> {

  private formRef = React.createRef<HTMLFormElement>();
  private gridRef = React.createRef<HTMLDivElement>();


  public render() {
    const {classes, handleSubmit, handleChange, docData} = this.props;
    const formNode: HTMLFormElement | null = this.formRef.current;
    const gridNode: HTMLDivElement | null = this.gridRef.current;
    const gridHeight = gridNode && gridNode.offsetHeight ? gridNode.offsetHeight : 0;
    const imageMaxHeight = formNode && formNode.offsetHeight ? formNode.offsetHeight : 0;
    return (
        <React.Fragment>
          <form onSubmit={handleSubmit} className={classes.form} ref={this.formRef}>
            <div ref={this.gridRef}>
              <Grid container={true} direction="row" alignItems="center">
                <Grid item={true}>
                  <input
                      accept="application/pdf"
                      className={classes.input}
                      id="contained-button-file-pdf"
                      multiple={true}
                      type="file"
                      name="takeHomeUpload"
                      // onChange={this.onImageChange}
                      onChange={handleChange}
                  />
                  <label htmlFor="contained-button-file-pdf">
                    <Button variant="contained" component="span" className={classes.button}>
                      Upload
                    </Button>
                  </label>
                </Grid>
                <Grid item={true}>
                  <Typography variant="caption">Support only *.pdf</Typography>
                </Grid>
              </Grid>
            </div>
            <Grid container={true} alignItems="center" justify="center">
              <Document
                  file={docData}
                  renderMode="canvas"
                  noData=""
                  renderAnnotationLayer={false}
              >
                <Page
                    pageNumber={1}
                    height={imageMaxHeight - (gridHeight + 1)}
                    renderAnnotationLayer={false}
                    renderInteractiveForms={false}
                    renderMode="canvas"
                />
              </Document>
            </Grid>
          </form>
        </React.Fragment>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(StepThreeUploadTakeHome) as React.ComponentClass<OwnProps>;
