import * as React from "react";
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {AnalysisQTInterval, QTIntervalOptions} from "../../../types/Analysis";
import AnalysisRadioGroup from "../components/AnalysisRadioGroup";
import Typography from "@material-ui/core/Typography/Typography";

interface OwnProps {
  values: AnalysisQTInterval;
  onChange: (values: AnalysisQTInterval) => void;
  showWideQRS: boolean;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    separator: {
      marginTop: theme.spacing(2),
    },
    leftPadding: {
      paddingLeft: theme.spacing(4),
    },
    rootContainer: {
      display: "flex",
      flexDirection: "row",
    },
    rootBody: {
      position: "relative",
      backgroundImage: `url(${require("../../../assets/rootTop.png")})`,
      backgroundRepeat: "repeat-x",
      width: "100%",
    },
    rrInterval: {
      position: "absolute",
      marginTop: theme.spacing(1),
    },
    equal: {
      display: "flex",
      alignItems: "flex-end",
      marginBottom: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    labelShrink: {
      fontSize: 21
    }
  });


type Props = OwnProps & WithStyles<typeof styles>;

class QTIntervalStep extends React.Component<Props> {

  public render() {
    const {showWideQRS, classes, values: {qtcIntervalMeasurement, qtIntervalMeasurement, qtInterval, wideQRSAdjustedMeasurement}} = this.props;
    return (
      <Grid container>
        <Grid item xs={5}>
          <div className={classes.rootContainer}>
            <TextField
              label="QT interval"
              InputLabelProps={{
                classes: {
                  shrink: classes.labelShrink
                }
              }}
              InputProps={{endAdornment: <InputAdornment position="end"><Typography style={{fontSize: 12}}>ms</Typography></InputAdornment>}}
              inputProps={{min: 0, type: "number", style: {fontSize: 12}}}
              value={qtIntervalMeasurement}
              onChange={this.handleChange("qtIntervalMeasurement")}
            />
          </div>
          <div className={classes.separator} />
        </Grid>
        <Grid item xs={4} className={classes.leftPadding}>
          <div style={{marginLeft: 20, marginTop: 20}}>
            <AnalysisRadioGroup value={qtInterval} onChange={this.handleRadioChange} name="qtInterval" options={QTIntervalOptions} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.rootContainer} style={{paddingTop: 10}}>
            <Typography variant="subtitle1">QTc interval by Bazett's formula <span style={{fontWeight: 600}}>{qtcIntervalMeasurement}</span> ms</Typography>
          </div>
        </Grid>
        {showWideQRS && <React.Fragment>
          <div className={classes.rootContainer} style={{paddingTop: 10}}>
            <Typography variant="subtitle1">QTc adjusted for wide QRS complex by using the formula: QTc – (QRS – 100ms) = <span style={{fontWeight: 600}}>{wideQRSAdjustedMeasurement}</span> ms</Typography>
            {/*<Typography variant="subtitle1">Wide QRS adjusted QTc = QTc – (QRS – 100ms) = <span style={{fontWeight: 600}}>{wideQRSAdjustedMeasurement}</span> ms</Typography>*/}
          </div>
        </React.Fragment>}
      </Grid>
    );
  }

  private handleChange = (name: keyof AnalysisQTInterval) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values, [name]: value});
  };

  private handleRadioChange = (name: keyof AnalysisQTInterval, value: string) => {
    const {isMarking} = this.props;
    !isMarking && this.props.onChange({...this.props.values, [name]: value});
  };

}

// @ts-ignore
export default withStyles(styles)(QTIntervalStep) as React.ComponentClass<OwnProps>;
