export enum AuthActions {
  Login = "Auth/LOGIN",
  LoginFailed = "Auth/LOGIN_FAILED",
  LoginSuccess = "Auth/LOGIN_SUCCESS",
  Logout = "Auth/LOGOUT",
  SetDetails = "Auth/SET_DETAILS",
  ConfirmEmail = "Auth/COMFIRM_EMAIL",
  ConfirmEmailSuccess = "Auth/COMFIRM_EMAIL_SUCCESS",
  ConfirmEmailFailed = "Auth/COMFIRM_EMAIL_FAILED"
}

export enum EcgActions {
  GetAnswer = "Ecg/GET_ANSWER",
  GetAnswerFailed = "Ecg/GET_ANSWER_FAILED",
  GetAnswerSuccess = "Ecg/GET_ANSWER_SUCCESS",
  GetStudentAnswer = "Ecg/GET_STUDENT_ANSWER",
  GetStudentAnswerFailed = "Ecg/GET_STUDENT_ANSWER_FAILED",
  GetStudentAnswerSuccess = "Ecg/GET_STUDENT_ANSWER_SUCCESS",
  SetAnswer = "Ecg/SET_ANSWER",
  SetCurrentStep = "Ecg/SET_CURRENT_STEP",
  SetCurrentWaveForm = "Ecg/SET_CURRENT_WAVEFORM",
  SetSelectedAnswer = "Ecg/SET_SELECTED_ANSWER",
  ToggleEnlargeImage = "Ecg/TOGGLE_ENLARGE_IMAGE",
  SaveStudentAnswer = "Ecg/SAVE_STUDENT_ANSWER",
  SaveStudentAnswerFailed = "Ecg/SAVE_STUDENT_ANSWER_FAILED",
  SaveStudentAnswerSuccess = "Ecg/SAVE_STUDENT_ANSWER_SUCCESS",
  SaveAnswer = "Ecg/SAVE_ANSWER",
  SaveAnswerFailed = "Ecg/SAVE_ANSWER_FAILED",
  SaveAnswerSuccess = "Ecg/SAVE_ANSWER_SUCCESS",
}

export enum ModuleActions {
  SetLoading = "Module/SET_LOADING",
  CreateModule = "Module/CREATE_MODULE",
  CreateModuleFailed = "Module/CREATE_MODULE_FAILED",
  CreateModuleSuccess = "Module/CREATE_MODULE_SUCCESS",
  CreateSheet = "Module/CREATE_SHEET",
  CreateSheetFailed = "Module/CREATE_SHEET_FAILED",
  CreateSheetSuccess = "Module/CREATE_SHEET_SUCCESS",
  DeselectModule = "Module/DESELECT_MODULE",
  GetModules = "Module/GET_MODULES",
  GetModulesFailed = "Module/GET_MODULES_FAILED",
  GetModulesSuccess = "Module/GET_MODULES_SUCCESS",
  GetSheets = "Module/GET_SHEETS",
  GetSheetsFailed = "Module/GET_SHEETS_FAILED",
  GetSheetsSuccess = "Module/GET_SHEETS_SUCCESS",
  SelectModule = "Module/SELECT_MODULE",
  Update = "Module/UPDATE",
  UpdateFailed = "Module/UPDATE_FAILED",
  UpdateSuccess = "Module/UPDATE_SUCCESS",
  UpdateSheet = "Module/UPDATE_SHEET",
  UpdateSheetFailed = "Module/UPDATE_SHEET_FAILED",
  UpdateSheetSuccess = "Module/UPDATE_SHEET_SUCCESS",
  Remove = "Module/REMOVE",
  RemoveFailed = "Module/REMOVE_FAILED",
  RemoveSuccess = "Module/REMOVE_SUCCESS",
  RemoveSheet = "Module/REMOVE_SHEET",
  RemoveSheetFailed = "Module/REMOVE_SHEET_FAILED",
  RemoveSheetSuccess = "Module/REMOVE_SHEET_SUCCESS",
  PublishUnPublish = "Module/PUBLISH_UNPUBLISH_MODULE",
  PublishUnPublishSuccess = "Module/PUBLISH_UNPUBLISH_MODULE_SUCCESS",
  PublishUnPublishFailed = "Module/PUBLISH_UNPUBLISH_MODULE_FAILED",
  UploadECG = "Module/UPLOAD_ECG",
  UploadECGSuccess = "Module/UPLOAD_ECG_SUCCESS",
  UploadECGFailed = "Module/UPLOAD_ECG_FAILED",
  UploadTakeHomeDoc = "Module/UPLOAD_TAKEHOMEDOC",
  UploadTakeHomeDocSuccess = "Module/UPLOAD_TAKEHOMEDOC_SUCCESS",
  UploadTakeHomeDocFailed = "Module/UPLOAD_TAKEHOMEDOC_FAILED",
  UpdateCaseScenario = "Module/UPDATE_CASE_SCENARIO",
  UpdateCaseScenarioSuccess = "Module/UPDATE_CASE_SCENARIO_SUCCESS",
  UpdateCaseScenarioFailed = "Module/UPDATE_CASE_SCENARIO_FAILED",
  FetchECGImage = "Module/FETCH_ECG_IMAGE",
  FetchECGImageSuccess = "Module/FETCH_ECG_IMAGE_SUCCESS",
  FetchECGImageFailed = "Module/FETCH_ECG_IMAGE_FAILED",
  FetchTakeHomeDoc = "Module/FETCH_TAKE_HOME_DOC",
  FetchTakeHomeDocSuccess = "Module/FETCH_TAKE_HOME_DOC_SUCCESS",
  FetchTakeHomeDocFailed = "Module/FETCH_TAKE_HOME_DOC_FAILED",
  SetECGCalibration = "Module/SET_ECG_CALIBRATION",
  SetECGCalibrationSuccess = "Module/SET_ECG_CALIBRATION_SUCCESS",
  SetECGCalibrationFailed = "Module/SET_ECG_CALIBRATION_FAILED",
}
export enum LectureActions {
  SetLoading = "Lecture/SET_LOADING",
  getLectures = "Lecture/GET_LECTURES",
  getLecturesSuccess = "Lecture/GET_LECTURES_SUCCESS",
  getLecturesError = "Lecture/GET_LECTURES_ERROR",
  createLecture = "Lecture/CREATE_LECTURE",
  createLectureSuccess = "Lecture/CREATE_LECTURE_SUCCESS",
  createLectureError = "Lecture/CREATE_LECTURE_ERROR",
  deleteLecture = "Lecture/DELETE_LECTURE",
  deleteLectureSuccess = "Lecture/DELETE_LECTURE_SUCCESS",
  deleteLectureError = "Lecture/DELETE_LECTURE_ERROR",
}
export enum PersistActions {
  PersistRehydrate = "persist/REHYDRATE",
}
