import {combineReducers} from 'redux';
import auth, {AuthState} from "./auth";
import ecg, {EcgState} from "./ecg";
import main from './main';
import moduleReducer, {ModuleState} from "./moduleReducer";
import LectureReducer, {LectureState} from "./lecture";

export interface AppState {
  auth: AuthState;
  ecg: EcgState;
  moduleReducer: ModuleState,
  LectureReducer: LectureState
}

export default combineReducers({
  auth,
  ecg,
  main,
  moduleReducer,
  LectureReducer,
});
