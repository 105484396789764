import axios from "axios";
import {all, call} from "redux-saga/effects";
import {authSaga} from "./auth";
import {ecgSaga} from "./ecg";
import {moduleSagas} from "./moduleSagas";
import {lectureSagas} from "./lectureSagas";

export const makeAxiosCall = (axiosData: any) => {
  return axios(axiosData);
};

export default function* rootSaga() {
  yield all([
    call(moduleSagas),
    call(ecgSaga),
    call(authSaga),
    call(lectureSagas)
  ]);
}
