import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {AnalysisPWave, PInRelationToQRSOptions, PWaveAxisOptions, PWaveMorphologyOptions, PWaveOptions} from "../../../types/Analysis";
import AnalysisRadioGroup from "../components/AnalysisRadioGroup";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";

interface OwnProps {
  values: AnalysisPWave;
  onChange: (values:AnalysisPWave)=>void
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
    });

type Props = OwnProps & WithStyles<typeof styles>;

const initialState = {
  pWave: null,
  pWaveAxis: [],
  pInRelationToQRS: [],
  pWaveMorphology: [],
};

class PWaveStep extends React.Component<Props> {

  public render() {
    const {classes,values: {pWave, pWaveAxis, pInRelationToQRS, pWaveMorphology}} = this.props;
    return (
        <Grid container>
          <Grid item xs={4}>
            <AnalysisRadioGroup value={pWave || null} onChange={this.handleChange} name="pWave" options={PWaveOptions} />
            <div className={classes.separator} />
            <Typography variant="h6" gutterBottom style={{opacity: pWave !== "Present"?.5:1}}>P wave axis</Typography>
            <AnalysisCheckboxGroup disabled={pWave !== "Present"} value={pWaveAxis as string[]} onChange={this.handleChange} name="pWaveAxis" options={PWaveAxisOptions} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" style={{opacity: pWave !== "Present"?.5:1}}>P in relation to QRS</Typography>
            <AnalysisCheckboxGroup disabled={pWave !== "Present"} value={pInRelationToQRS as string[]} onChange={this.handleChange} name="pInRelationToQRS" options={PInRelationToQRSOptions} />
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h6" style={{opacity: pWave !== "Present"?.5:1}}>P wave morphology</Typography>
            <AnalysisCheckboxGroup disabled={pWave !== "Present"} enableThese={this.checkPWaveValue()} disabledFrom={this.checkPWaveMorphologyValue()} value={pWaveMorphology as string[]} onChange={this.handleChange} name="pWaveMorphology" options={PWaveMorphologyOptions} />
          </Grid>
        </Grid>
    );
  }

  private checkPWaveMorphologyValue = () => {
    const {values:{pWaveMorphology}} = this.props;
    // @ts-ignore
    return pWaveMorphology.indexOf("Normal morphology") !== -1?1:null;
  };
  private checkPWaveValue = () => {
    const {values:{pWave}} = this.props;
    // @ts-ignore
    return pWave === 'Absent / unsure if present'?[6,7]:[];
  };

  private handleChange = (name: keyof AnalysisPWave, value: string | string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "pWave") {
        if (value === "Absent / unsure if present") {
          this.props.onChange({...initialState,[name]:value});
          return;
        }
      }
      if (name === "pWaveMorphology") {
        if (value.indexOf("Normal morphology") !== -1) {
          this.props.onChange({...this.props.values,[name]:["Normal morphology"]});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  };

}

// @ts-ignore
export default withStyles(styles)(PWaveStep) as React.ComponentClass<OwnProps>;
