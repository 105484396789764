import React, {Fragment, useEffect, useState} from 'react';
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Loader from "../Loader";
import Typography from "@material-ui/core/Typography/Typography";
import AppBar from "@material-ui/core/AppBar/AppBar";
import { Link } from 'react-router-dom';
const ConfirmEmail = ({match, auth, fnConfirmEmail}) => {
    const [confirmCode, setConfirmCode] = useState(null);
    useEffect(() => {
        if (!match) return;
        console.log('match', match);
        const { params } = match;
        if (!params) return;
        console.log('params', params);
        const { code } = params;
        if (!code) return;
        fnConfirmEmail(code);
        setConfirmCode(code);
    },[match])

    useEffect(() => {
        if (auth.confirmed === null) return;
        console.log('confirmed ', auth.confirmed)
    }, [auth.confirmed])
    return(
        <Fragment>
            <AppBar position="static" style={{position: "relative"}}>
                <Toolbar>
                    {auth.confirmed === null && <Loader height={40} width={50} />}
                    <Typography variant="h6" color="inherit" noWrap={true} style={{flexGrow: 1}}>
                        ECG ONLINE
                    </Typography>
                </Toolbar>
            </AppBar>
            {auth.confirmed === false && (
                <>
                    <div style={{}}>Email confirmation failed.</div>
                    <div style={{}}><Link to="/">Register again</Link></div>
                </>)}
            {auth.confirmed === true && (
                <>
                    <div style={{}}>Email confirmation success.</div>
                    <div style={{}}><Link to="/">Login here</Link></div>
                </>)}
        </Fragment>)
}

export default ConfirmEmail;
