import * as React from "react";
import API from "../constants/API";
import {generateApiKey, WithAppContext, withAppContext} from "./AppContext";
import {ECGImage, ECGSheet, GetECGsResponse, Module, StudentResult} from "../types/module";
import {AnalysisInterface} from "../types/Analysis";
import {DeleteUserResponse, GetUserResponse, PostUserResponse, User} from "../types/app";
import {convertUserType} from "../containers/UserList/UserDialog";
import {error} from "util";

interface ModuleContextInterface {
  selectedModule: Module | null;
  modules: Module[];
  users: User[];
  ecgImages: ECGImage[];
  ecgThumbs: ECGImage[];
  lastCreatedECGId: number | null;
  clearLastCreatedECGIdFn: () => void;
  getModulesFn: () => Promise<Module[]>;
  getUsersFn: (searchTerm?: string, page?: number) => Promise<GetUserResponse>;
  postUsersFn: (user: User) => Promise<PostUserResponse>;
  deleteUserFn: (userId: string) => Promise<DeleteUserResponse>;
  registerUsersFn: (user: User) => Promise<PostUserResponse>;
  getECGImageFn: (moduleId: number, ecgId: number) => Promise<string>;
  getECGThumbFn: (moduleId: number, ecgId: number) => void;
  getTakeHomeDocFn: (moduleId: number, ecgId: number) => Promise<string>;
  getECGsFn: (moduleId: number) => Promise<ECGSheet[]>;
  getAnswersFn: (ecgId: number) => Promise<boolean>;
  getStudentResults: (moduleId: number) => Promise<StudentResult[]>;
  addEditModuleFn: (module: Partial<Module>) => void;
  addEditECGFn: (ecg: Partial<AnalysisInterface>) => void;
  uploadECGFn: (ecgUpload: File, answerId: number, moduleId: number) => void;
  uploadTakeHomeFn: (takeHomeFile: File, answerId: number, moduleId: number) => void;
  publishUnPublishModuleFn: (module: Partial<Module>) => void;
  removeModuleFn: (module: Partial<Module>) => void;
  removeECGFn: (answerId: number) => void;
  selectModule: (module: Module | null) => Promise<any>
}

const ctxt = React.createContext<ModuleContextInterface | null>(null);

const ModuleContextProvider = ctxt.Provider;

const ModuleContextConsumer = ctxt.Consumer;

interface Props extends WithAppContext {
}

class ModuleContext extends React.Component<Props, ModuleContextInterface> {
  // noinspection JSUnusedGlobalSymbols
  public state: ModuleContextInterface = {
    selectedModule: null,
    modules: [],
    users: [],
    ecgImages: [],
    ecgThumbs: [],
    lastCreatedECGId: null,
    clearLastCreatedECGIdFn: () => {
      this.setState({lastCreatedECGId: null});
    },
    getModulesFn: () => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(API.modules.url, {
        ...API.modules, headers: {
          ...API.modules.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {status, response} = res;
        status === "ok" && this.setState({modules: (response as Module[])});
        return response as Module[];
      });
    },
    getUsersFn: (searchTerm = '', page = 1) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      let url = `${API.getUsers.url}?page=${page}`;
      if (searchTerm !== '') {
        url = `${url}&searchTerm=${searchTerm}`
      }
      return callApi(url, {
        ...API.getUsers, headers: {
          ...API.getUsers.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {status, response} = res;
        status === "ok" && this.setState({users: (response.users as User[])});
        return response as GetUserResponse;
      });
    },
    deleteUserFn: (userId: string) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.deleteUser.url}/${userId}`, {
        ...API.deleteUser, headers: {
          ...API.deleteUser.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {response} = res;
        // console.log(res);
        return response as DeleteUserResponse;
      });
    },
    postUsersFn: (user: User) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(API.postUsers.url, {
        ...API.postUsers, headers: {
          ...API.postUsers.headers,
          "X-AUTH-TOKEN": authToken || "",
        }
      }, {...user, userType: convertUserType(user.userType)}).then(res => {
        const {response} = res;
        return response as PostUserResponse;
      });
    },
    registerUsersFn: (user: User) => {
      const {appContext:{
        callApi}
      } = this.props;
      const generatedApiKey = generateApiKey({...user, userType: convertUserType(user.userType)});
      return callApi(API.register.url, {
        ...API.register,
        headers: {
          ...API.register.headers,
          "API-KEY": generatedApiKey.apiKey,
          "REQUEST-TIME": generatedApiKey.newDate,
        },
      }, {...user, userType: convertUserType(user.userType)}).then(res => {
        console.log('res');
        const {response} = res;
        return response as PostUserResponse;
      });
    },
    getECGThumbFn: (moduleId, ecgId) => {
      // console.log(...helperRenderConsoleText('getECGThumbFn', 'lightBlue'));
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.fetchECGThumb.url}/${moduleId}/${ecgId}`, {
        ...API.fetchECGThumb, headers: {
          ...API.fetchECGThumb.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {ecgThumbs} = this.state;
        const {status, response} = res;
        const tmpImages = [...ecgThumbs];
        if (status === "ok") {
          const image = {id: ecgId, moduleId, image: `data:image/jpeg;base64,${new Buffer((response as any), "binary").toString("base64")}`};
          const idIndex = tmpImages.findIndex(img => img.id === ecgId && img.moduleId === moduleId);
          idIndex === -1 ?
            tmpImages.push(image) :
            tmpImages[idIndex] = image;
          this.setState({ecgThumbs: tmpImages});
          // sessionStorage.setItem("ecg_images", JSON.stringify(tmpImages))
        }
        return status === "ok";
      });
    },
    getECGImageFn: (moduleId, ecgId) => {
      // console.log(...helperRenderConsoleText('getECGImageFn', 'lightBlue'));
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.fetchECGImage.url}/${moduleId}/${ecgId}`, {
        ...API.fetchECGImage, headers: {
          ...API.fetchECGImage.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {ecgImages} = this.state;
        const {status, response} = res;
        const tmpImages = [...ecgImages];
        if (status === "ok") {
          const image = {id: ecgId, moduleId, image: `data:image/jpeg;base64,${new Buffer((response as any), "binary").toString("base64")}`};
          const idIndex = tmpImages.findIndex(img => img.id === ecgId && img.moduleId === moduleId);
          idIndex === -1 ?
            tmpImages.push(image) :
            tmpImages[idIndex] = image;
          this.setState({ecgImages: tmpImages});
          // sessionStorage.setItem("ecg_images", JSON.stringify(tmpImages))
          return image.image;
        } else {
          return "";
        }
      });
    },
    getTakeHomeDocFn: (moduleId, ecgId) => {
      // console.log(...helperRenderConsoleText('getTakeHomeDocFn', 'lightBlue'));
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.fetchTakeHomeDoc.url}/${ecgId}/${moduleId}`, {
        ...API.fetchTakeHomeDoc, headers: {
          ...API.fetchTakeHomeDoc.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        console.log('here',res);
        if (res.status === "error") {
          return "";
        } else {
          return `data:application/pdf;base64,${new Buffer(res.response, "binary").toString("base64")}`;
        }
      });
    },
    getECGsFn: (moduleId) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.sheets.url}/${moduleId}`, {
        ...API.sheets, headers: {
          ...API.sheets.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {status, response} = res;
        const {ecgThumbs, getECGThumbFn, selectedModule} = this.state;
        if (status === "ok") {
          (response as GetECGsResponse).ecgSheets.forEach(ecg => {
            ecg.ecgImage && ecgThumbs.findIndex(img => img.id === ecg.id && img.moduleId === moduleId) === -1 && getECGThumbFn(moduleId, ecg.id);
          });
          const newSelectedModule = {...(selectedModule as Module), ecgSheets: (response as any).ecgSheets};
          this.setState({selectedModule: newSelectedModule});
          sessionStorage.setItem("ecg_selectedModule", JSON.stringify(newSelectedModule))
        }
        return res.response.ecgSheets;
      });
    },
    getAnswersFn: ecgId => {
      // console.log(...helperRenderConsoleText('getAnswersFn', 'lightBlue'));
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.getStudentAnswers.url}/${ecgId}`, {
        ...API.getStudentAnswers, headers: {
          ...API.getStudentAnswers.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(() => {
        // console.log(res);
        return true;
      });
    },
    getStudentResults: moduleId => {
      // console.log(...helperRenderConsoleText('getStudentResults', 'lightBlue'));
      const {
        appContext: {
          callApi,
          authToken,
          id,
          isStudent,
        },
      } = this.props;
      return callApi(`${API.getStudentResults.url}/${moduleId}/${isStudent ? id : 0}`, {
        ...API.getStudentResults, headers: {
          ...API.getStudentResults.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        return res.response;
      });
    },
    addEditModuleFn: module => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(API.addEditModule.url, {
        ...API.addEditModule, headers: {
          ...API.addEditModule.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, {...module}).then(res => {
        const {getModulesFn} = this.state;
        getModulesFn();
        return res.status === "ok";
      });
    },
    addEditECGFn: ecg => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(API.addAnswer.url, {
        ...API.addAnswer, headers: {
          ...API.addAnswer.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, {analysis: {...ecg}}).then(res => {
        const {selectedModule, getECGsFn} = this.state;
        getECGsFn(selectedModule !== null ? selectedModule.id : 0);
        !ecg.answerId && this.setState({lastCreatedECGId: res.response.answer_id});
        return res.status === "ok";
      });
    },
    uploadECGFn: (ecgUpload, answerId, moduleId) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      const formData = new FormData();
      formData.append("ecgfile", ecgUpload);
      formData.append("module_id", String(moduleId));
      formData.append("answer_id", String(answerId));
      return callApi(API.uploadECG.url, {
        ...API.uploadECG, headers: {
          ...API.uploadECG.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, formData, "form").then(res => {
        const {getECGThumbFn, getECGImageFn} = this.state;
        getECGThumbFn(moduleId, answerId);
        getECGImageFn(moduleId, answerId).then(null);
        return res.status === "ok";
      });
    },
    uploadTakeHomeFn: (takeHomeFile, answerId, moduleId) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      const formData = new FormData();
      formData.append("takehomefile", takeHomeFile);
      formData.append("module_id", String(moduleId));
      formData.append("answer_id", String(answerId));
      return callApi(API.uploadTakeHomeDoc.url, {
        ...API.uploadTakeHomeDoc, headers: {
          ...API.uploadTakeHomeDoc.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, formData, "form").then(res => {
        return res.status === "ok";
      });
    },
    publishUnPublishModuleFn: module => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.publishUnPublishModule.url}/${module.isPublished ? 0 : 1}/${module.id}`, {
        ...API.publishUnPublishModule, headers: {
          ...API.publishUnPublishModule.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {getModulesFn} = this.state;
        getModulesFn();
        return res.status === "ok";
      });
    },
    removeModuleFn: module => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.removeModule.url}/${module.id}`, {
        ...API.removeModule, headers: {
          ...API.removeModule.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {getModulesFn} = this.state;
        getModulesFn();
        return res.status === "ok";
      });
    },
    removeECGFn: (answerId) => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.deleteEcgSheet.url}/${answerId}`, {
        ...API.deleteEcgSheet, headers: {
          ...API.deleteEcgSheet.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {selectedModule, getECGsFn} = this.state;
        getECGsFn(selectedModule !== null ? selectedModule.id : 0);
        return res.status === "ok";
      });
    },
    selectModule: async mod => {
      this.setState({selectedModule: mod});
      sessionStorage.removeItem("ecg_selectedModule");
      return await true;
    }
  };

  componentWillMount(): void {
    const images: ECGImage[] = JSON.parse(sessionStorage.getItem("ecg_images") || "[]");
    const selectedModule: Module = JSON.parse(sessionStorage.getItem("ecg_selectedModule") || "{}");
    images && this.setState({ecgImages: images, selectedModule: selectedModule.id ? selectedModule : null});
  }

  public render() {
    const {children} = this.props;
    return (
      <ModuleContextProvider value={this.state}>
        {children}
      </ModuleContextProvider>
    )
  }

}

export interface WithModuleContext {
  moduleContext: ModuleContextInterface;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withModuleContext<P extends { moduleContext?: ModuleContextInterface },
  R = Omit<P, "moduleContext">>(
  Component: React.ComponentClass<P> | React.StatelessComponent<P>
): React.SFC<R> {
  return function BoundComponent(props: R) {
    return (
      <ModuleContextConsumer>
        {value => {
          // @ts-ignore
          return <Component {...props} moduleContext={value} />;
        }}
      </ModuleContextConsumer>
    );
  };
}

export default withAppContext(ModuleContext);
