import {AuthActions, LectureActions} from "../../constants/Actions";

export const setAuthDetails = (value: any) => ({
  payload: value,
  type: AuthActions.SetDetails,
});

export interface AuthLoginFnPayload {
  email: string;
  password: string;
}

export interface AuthLoginFn {
  payload:AuthLoginFnPayload;
  type: AuthActions.Login;
}

export const authLoginFn = (payload: AuthLoginFnPayload):AuthLoginFn => ({
  payload,
  type: AuthActions.Login,
});
export const logoutFn = () => ({
  type: AuthActions.Logout,
});

export const confirmEmailFn = (payload:any) => ({
  payload,
  type: AuthActions.ConfirmEmail,
});
