import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide/Slide";
import * as React from "react";
import {ECGSheet} from "../../../types/module";

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

// TODO: fix types
interface Props {
  close: any;
  confirm: any;
  sheet: ECGSheet | null;
  showRemoveDialog: any;
}

const RemoveSheetDialog = (props: Props) => {
  const {sheet, showRemoveDialog, close, confirm} = props;

  const handleClose = () => {
    close();
  };
  const handleConfirm = () => {
    if (sheet!== null) {
      confirm(sheet.id);
      close();
    }
  };
  return (
      <React.Fragment>
        {sheet !== null && <Dialog
            TransitionComponent={Transition}
            open={showRemoveDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to remove {sheet.name}?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to remove <b>{sheet.name}.</b> This cannot be undone, are you sure you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus={true}>
              Remove
            </Button>
          </DialogActions>
        </Dialog>}
      </React.Fragment>);
};

export default RemoveSheetDialog;
