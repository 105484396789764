import axios from "axios";
import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {ModuleActions} from "../../constants/Actions";
import Api from "../../constants/API";
import {ModuleFetchECGImageAction, ModuleFetchTakeHomeAction, ModuleSetECGCalibration, ModuleUpdateCaseScenarioAction, ModuleUploadECGAction, ModuleUploadTakeHomeDocAction, PublishUnPublishModuleFn} from "../actions/moduleActions";
import store from "../index";
import {makeAxiosCall} from "./index";
import {AppState} from "../reducers";

const getModuleState = (state: AppState) => state.moduleReducer;

function* setLoading(val: boolean) {
  yield put({type: ModuleActions.SetLoading, payload: {loading: val}});
}

// TODO: fix types
function* createModule(action: any) {
  const {module} = action;
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    // @ts-ignore
    const data = yield call(axios, {...Api.addEditModule, data: {...module}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: ModuleActions.CreateModuleSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.CreateModuleFailed, message: e.message});
  }
}

function* updateModule(action: any) {
  const {module} = action;
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    // @ts-ignore
    const data = yield call(axios, {...Api.addEditModule, data: {...module}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: ModuleActions.UpdateSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.UpdateFailed, message: e.message});
  }
}

function* getModules() {
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {...Api.modules, headers: {...Api.modules.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: ModuleActions.GetModulesSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.GetModulesFailed, message: e.message});
  }
}

function* createSheet(action: any) {
  const {sheet} = action;
  const {name: ecgName} = sheet;
  const {auth, moduleReducer} = store.getState();
  const {selectedModule} = moduleReducer;
  const {id} = selectedModule;
  const {authToken} = auth;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {...Api.addAnswer, data: {ecgName, module_id: id}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: ModuleActions.CreateSheetSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.CreateSheetFailed, message: e.message});
  }
}

function* updateSheet(action: any) {
  const {sheet} = action;
  const {name: ecgName, id} = sheet;
  const {auth, moduleReducer} = store.getState();
  const {selectedModule} = moduleReducer;
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  try {
    // @ts-ignore
    const data = yield call(axios,
        {
          ...Api.addAnswer,
          data: {
            ecgName,
            module_id: moduleId,
            answer_id: id,
          },
          headers: {
            ...Api.addEditModule.headers,
            "X-AUTH-TOKEN": authToken,
          },
        });
    yield put({type: ModuleActions.UpdateSheetSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.UpdateSheetFailed, message: e.message});
  }
}

function* getSheets() {
  const {auth, moduleReducer} = store.getState();
  const {selectedModule} = moduleReducer;
  const {id} = selectedModule;
  const {authToken} = auth;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {
      ...Api.sheets,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.sheets.url}/${id}`,
    });
    yield put({type: ModuleActions.GetSheetsSuccess, ecgSheets: [...data.ecgSheets]});
    while (data.ecgSheets.length) {
      const ecgSheet = data.ecgSheets.shift();
      if (ecgSheet.ecgImage) {
        yield put({type: ModuleActions.FetchECGImage, id: ecgSheet.id})
      }
    }
  } catch (e) {
    yield put({type: ModuleActions.GetSheetsFailed, message: e.message});
  }
}

function* removeModule(action: any) {
  const {auth} = store.getState();
  const {authToken} = auth;
  const {id} = action;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {
      ...Api.removeModule,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.removeModule.url}/${id}`,
    });
    yield put({type: ModuleActions.RemoveSuccess, id: data.id});
  } catch (e) {
    yield put({type: ModuleActions.RemoveFailed, message: e.message});
  }
}

function* removeSheet(action: any) {
  const {auth} = store.getState();
  const {authToken} = auth;
  const {id} = action;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {
      ...Api.deleteEcgSheet,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.deleteEcgSheet.url}/${id}`,
    });
    yield put({type: ModuleActions.RemoveSheetSuccess, id: data.id});
  } catch (e) {
    yield put({type: ModuleActions.RemoveSheetFailed, message: e.message});
  }
}

function* publishUnpublishModule(action: PublishUnPublishModuleFn) {
  const {payload} = action;
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    const {data} = yield makeAxiosCall({
      ...Api.publishUnPublishModule,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.publishUnPublishModule.url}/${payload.isPublished ? 0 : 1}/${payload.id}`,
    });
    yield put({type: ModuleActions.PublishUnPublishSuccess, ecgSheets: data.ecgSheets});
  } catch (e) {
    yield put({type: ModuleActions.PublishUnPublishFailed, message: e.message});
  }
}

function* uploadECGSaga(action: ModuleUploadECGAction) {
  yield call(setLoading, true);
  const {id, ECG} = action;
  // console.log(ECG);
  const {auth} = store.getState();
  const {selectedModule} = yield select(getModuleState);
  // console.log(selectedModule);
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  const formData = new FormData();
  formData.append("module_id", moduleId);
  formData.append("answer_id", id.toString());
  formData.append("ecgfile", ECG);
  try {
    const {data} = yield makeAxiosCall({
      ...Api.uploadECG,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      data: formData,
    });
    yield put({type: ModuleActions.UploadECGSuccess, data});
    yield put( {type: ModuleActions.GetSheets,payload: moduleId});
  } catch (e) {
    yield put({type: ModuleActions.UploadECGFailed, message: e.message});
  } finally {
    yield call(setLoading, false);
  }
}

function* updateCaseScenarioSaga(action: ModuleUpdateCaseScenarioAction) {
  yield call(setLoading, true);
  const {id, caseScenario} = action;
  const {auth, moduleReducer} = store.getState();
  const {selectedModule} = moduleReducer;
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  try {
    // @ts-ignore
    const data = yield call(axios,
        {
          ...Api.addAnswer,
          data: {
            overview: caseScenario,
            module_id: moduleId,
            answer_id: id,
          },
          headers: {
            ...Api.addEditModule.headers,
            "X-AUTH-TOKEN": authToken,
          },
        });
    yield put({type: ModuleActions.UpdateCaseScenarioSuccess, data});
    yield put ( {type: ModuleActions.GetSheets,payload: moduleId});
  } catch (e) {
    yield put({type: ModuleActions.UpdateCaseScenarioFailed, message: e.message});
  } finally {
    yield call(setLoading, false);
  }
}

function* uploadTakeHomeDocSaga(action: ModuleUploadTakeHomeDocAction) {
  yield call(setLoading, true);
  const {id, file} = action;
  const {auth} = store.getState();
  const {selectedModule} = yield select(getModuleState);
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  const formData = new FormData();
  formData.append("module_id", moduleId);
  formData.append("answer_id", id.toString());
  formData.append("takehomefile", file);
  try {
    const {data} = yield makeAxiosCall({
      ...Api.uploadTakeHomeDoc,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      data: formData,
    });
    yield put({type: ModuleActions.UploadTakeHomeDocSuccess, data});
  } catch (e) {
    yield put({type: ModuleActions.UploadTakeHomeDocFailed, message: e.message});
  } finally {
    yield call(setLoading, false);
  }
}

function* fetchECGImageSaga(action: ModuleFetchECGImageAction) {
  const {id} = action;
  const {auth} = store.getState();
  const {selectedModule} = yield select(getModuleState);
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  try {
    const {data} = yield makeAxiosCall({
      ...Api.fetchECGImage,
      headers: {...Api.fetchECGImage.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.fetchECGImage.url}/${moduleId}/${id}`,
    });
    const image = `data:image/jpeg;base64,${yield new Buffer(data, "binary").toString("base64")}`;
    yield put({type: ModuleActions.FetchECGImageSuccess, data: {image, id}});
  } catch (e) {
    yield put({type: ModuleActions.FetchECGImageFailed, message: e.message});
  }
}

function* fetchTakeHomeDocSaga(action: ModuleFetchTakeHomeAction) {
  const {id} = action;
  const {auth} = store.getState();
  const {selectedModule} = yield select(getModuleState);
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  try {
    const {data} = yield makeAxiosCall({
      ...Api.fetchTakeHomeDoc,
      headers: {...Api.fetchTakeHomeDoc.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.fetchTakeHomeDoc.url}/${moduleId}/${id}`,
    });
    const doc = `data:application/pdf;base64,${yield new Buffer(data, "binary").toString("base64")}`;
    yield put({type: ModuleActions.FetchTakeHomeDocSuccess, data: {doc, id}});
  } catch (e) {
    yield put({type: ModuleActions.FetchTakeHomeDocFailed, message: e.message});
  }
}

function* setECGCalibrationSaga(action: ModuleSetECGCalibration) {
  yield call(setLoading, true);
  const {id, calibration} = action;
  const {auth, moduleReducer} = store.getState();
  const {selectedModule} = moduleReducer;
  const {id: moduleId} = selectedModule;
  const {authToken} = auth;
  try {
    // @ts-ignore
    const data = yield call(axios,
        {
          ...Api.addAnswer,
          data: {
            calibration,
            module_id: moduleId,
            answer_id: id,
          },
          headers: {
            ...Api.addEditModule.headers,
            "X-AUTH-TOKEN": authToken,
          },
        });
    yield put({type: ModuleActions.SetECGCalibrationSuccess, data});
    yield put ( {type: ModuleActions.GetSheets,payload: moduleId});
  } catch (e) {
    yield put({type: ModuleActions.SetECGCalibrationFailed, message: e.message});
  } finally {
    yield call(setLoading, false);
  }
}

export function* moduleSagas() {
  yield takeLatest(ModuleActions.CreateModule, createModule);
  yield takeLatest(ModuleActions.Update, updateModule);
  yield takeLatest(ModuleActions.UpdateSuccess, getModules);
  yield takeLatest(ModuleActions.GetModules, getModules);
  yield takeLatest(ModuleActions.CreateModuleSuccess, getModules);
  yield takeLatest(ModuleActions.CreateSheet, createSheet);
  yield takeLatest(ModuleActions.UpdateSheet, updateSheet);
  yield takeLatest(ModuleActions.CreateSheetSuccess, getSheets);
  yield takeLatest(ModuleActions.GetSheets, getSheets);
  yield takeLatest(ModuleActions.RemoveSheetSuccess, getSheets);
  yield takeLatest(ModuleActions.UpdateSheetSuccess, getSheets);
  yield takeLatest(ModuleActions.PublishUnPublish, publishUnpublishModule);
  yield takeLatest(ModuleActions.PublishUnPublishSuccess, getModules);
  yield takeLatest(ModuleActions.Remove, removeModule);
  yield takeLatest(ModuleActions.RemoveSheet, removeSheet);
  yield takeLatest(ModuleActions.RemoveSuccess, getModules);
  yield takeLatest(ModuleActions.UploadECG, uploadECGSaga);
  yield takeLatest(ModuleActions.UploadTakeHomeDoc, uploadTakeHomeDocSaga);
  yield takeLatest(ModuleActions.UpdateCaseScenario, updateCaseScenarioSaga);
  yield takeEvery(ModuleActions.FetchECGImage, fetchECGImageSaga);
  yield takeEvery(ModuleActions.FetchTakeHomeDoc, fetchTakeHomeDocSaga);
  yield takeEvery(ModuleActions.SetECGCalibration, setECGCalibrationSaga);
}
