import * as React from "react";
import {Dialog, List} from "@material-ui/core";
import Slide from "@material-ui/core/Slide";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import {TransitionProps} from "@material-ui/core/transitions";


interface Props {
  title: string;
  body: string[];
  open: boolean;
  handleClose: () => void;
}

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props) {
  return <Slide direction="up" {...props} />;
});

const PromptDialog = (props: Props) => {
  const {title, open, handleClose, body} = props;
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
    >
      <DialogTitle disableTypography style={{padding: 0}}>
        <AppBar position="relative" style={{padding: 16}}>
          <Typography variant="h6" color="inherit">{title}</Typography>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <List>
          {body.map((b, i) =>
            <ListItem key={i}>
              <Typography variant="body1">{b}</Typography>
            </ListItem>)}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleClose}
          color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default PromptDialog;
