import * as React from 'react';
import {Theme, WithStyles} from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid/Grid';
import TextField from '@material-ui/core/TextField/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import Radio from '@material-ui/core/Radio/Radio';
import {AnalysisInterface, AnalysisStep3} from '../../../types/Analysis';
import Typography from '@material-ui/core/Typography';
import AnalysisRadioGroup from '../components/AnalysisRadioGroup';

interface OwnProps {
  values: AnalysisStep3;
  onChange: (values: AnalysisStep3) => void;
  isStudent: boolean;
  isMarking: boolean;
  correctAnswer: AnalysisInterface | null;
}

const styles = (theme: Theme) =>
  createStyles({
    separator: {
      marginTop: theme.spacing(2),
    },
    leftPadding: {
      paddingLeft: theme.spacing(2),
    },
  });

type Props = OwnProps & WithStyles<typeof styles>;

class Step3 extends React.Component<Props> {
  public render() {
    const {
      classes,
      values: {
        optionalQuestion1, optionalQuestion2, optionalQuestion1Answers, optionalQuestion1CorrectAnswer, optionalQuestion2Answers, optionalQuestion2CorrectAnswer
      },
      isStudent,
      isMarking,
      correctAnswer
    } = this.props;
    const {
      step3: {
        optionalQuestion1CorrectAnswer: correctOptionalQuestion1CorrectAnswer,
        optionalQuestion2CorrectAnswer: correctOptionalQuestion2CorrectAnswer
      }
    } = correctAnswer || {
      step3: {
        optionalQuestion1CorrectAnswer: null,
        optionalQuestion2CorrectAnswer: null
      }
    };
    return (
      <Grid container>
        <Grid item xs={6}>
          {isStudent ?
            optionalQuestion1 ?
              <Typography variant="subtitle1">{optionalQuestion1}</Typography> :
              <div /> :
            <TextField
              label="Optional Question 1"
              multiline
              rowsMax="8"
              value={optionalQuestion1}
              onChange={this.handleChange('optionalQuestion1')}
              margin="normal"
              fullWidth
            />}
          <Grid container direction="column">
            {isMarking ? optionalQuestion1 && correctOptionalQuestion1CorrectAnswer !== null && optionalQuestion1CorrectAnswer !== null ?
              <>
                {correctOptionalQuestion1CorrectAnswer === optionalQuestion1CorrectAnswer ? <Typography>{optionalQuestion1Answers[correctOptionalQuestion1CorrectAnswer]}</Typography> : null}
                {correctOptionalQuestion1CorrectAnswer !== optionalQuestion1CorrectAnswer ? <Typography style={{textDecoration: 'line-through'}}>{optionalQuestion1Answers[optionalQuestion1CorrectAnswer]}</Typography> : null}
                {correctOptionalQuestion1CorrectAnswer !== optionalQuestion1CorrectAnswer ? <Typography style={{fontWeight: 'bold'}}>{optionalQuestion1Answers[correctOptionalQuestion1CorrectAnswer]}</Typography> : null}
              </> : null : isStudent ? optionalQuestion1 ? <AnalysisRadioGroup
              value={optionalQuestion1Answers[optionalQuestion1CorrectAnswer !== null ? optionalQuestion1CorrectAnswer : -1] || ''}
              onChange={this.handleOptionalRadioChange(1)}
              name="optionalQuestion1CorrectAnswer"
              options={optionalQuestion1Answers} /> : <div /> : optionalQuestion1Answers.map((value, index) => {
                const stringIndex: string = String(index);
                return <FormControlLabel
                  key={index}
                  value={stringIndex}
                  control={<Radio
                    checked={optionalQuestion1CorrectAnswer === index}
                    color="primary"
                    onChange={this.handleRadioChange('optionalQuestion1CorrectAnswer', index)}
                  />} label={<TextField
                  value={value}
                  onChange={this.handleOptionalAnswersChange('optionalQuestion1Answers', index)}
                />} />;
              },
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} className={classes.leftPadding}>
          {isStudent ? optionalQuestion2 ? <Typography variant="subtitle1">{optionalQuestion2}</Typography> : <div /> : <TextField
            label="Optional Question 2"
            multiline
            rowsMax="8"
            value={optionalQuestion2}
            onChange={this.handleChange('optionalQuestion2')}
            margin="normal"
            fullWidth
          />}
          <Grid container direction="column">
            {isMarking?optionalQuestion2 && correctOptionalQuestion2CorrectAnswer !== null && optionalQuestion2CorrectAnswer !== null ?
              <>
                {correctOptionalQuestion2CorrectAnswer === optionalQuestion2CorrectAnswer?<Typography>{optionalQuestion2Answers[correctOptionalQuestion2CorrectAnswer]}</Typography>:null}
                {correctOptionalQuestion2CorrectAnswer !== optionalQuestion2CorrectAnswer?<Typography style={{textDecoration: 'line-through'}}>{optionalQuestion2Answers[optionalQuestion2CorrectAnswer]}</Typography>:null}
                {correctOptionalQuestion2CorrectAnswer !== optionalQuestion2CorrectAnswer?<Typography style={{fontWeight: 'bold'}}>{optionalQuestion2Answers[correctOptionalQuestion2CorrectAnswer]}</Typography>:null}
              </>
              : null:isStudent ? optionalQuestion2 ? <AnalysisRadioGroup
              value={optionalQuestion2Answers[optionalQuestion2CorrectAnswer !== null ? optionalQuestion2CorrectAnswer : -1] || ''}
              onChange={this.handleOptionalRadioChange(2)}
              name="optionalQuestion2CorrectAnswer"
              options={optionalQuestion2Answers} /> : <div /> : optionalQuestion2Answers.map((value, index) => {
                const stringIndex: string = String(index);
                return <FormControlLabel
                  key={index}
                  value={stringIndex}
                  control={<Radio
                    checked={optionalQuestion2CorrectAnswer === index}
                    color="primary"
                    onChange={this.handleRadioChange('optionalQuestion2CorrectAnswer', index)}
                  />} label={<TextField
                  value={value}
                  onChange={this.handleOptionalAnswersChange('optionalQuestion2Answers', index)}
                />} />;
              },
            )}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  private handleChange = (name: keyof AnalysisStep3) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values, [name]: value});
  };
  private handleOptionalRadioChange = (question: 1 | 2) => (name: string, value: string | string[]) => {
    const {isMarking} = this.props;
    const {
      values: {
        optionalQuestion1Answers,
        optionalQuestion2Answers
      }
    } = this.props;
    if (!isMarking) {
      if (question === 1) {
        this.props.onChange({...this.props.values, optionalQuestion1CorrectAnswer: optionalQuestion1Answers.findIndex(v => v === value)});
        this.setState({optionalQuestion1CorrectAnswer: optionalQuestion1Answers.findIndex(v => v === value)});
      } else {
        this.props.onChange({...this.props.values, optionalQuestion2CorrectAnswer: optionalQuestion2Answers.findIndex(v => v === value)});
        this.setState({optionalQuestion2CorrectAnswer: optionalQuestion2Answers.findIndex(v => v === value)});
      }
    }
  };

  private handleRadioChange = (name: keyof AnalysisStep3, index: number) => () => {
    const {isMarking} = this.props;
    if (!isMarking) {
      this.props.onChange({...this.props.values, [name]: index});
      this.setState({[name]: index});
    }
  };

  private handleOptionalAnswersChange = (name: keyof AnalysisStep3, index: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget;
    const tmpArr = this.props.values[name] as string[];
    tmpArr[index] = value;
    !isMarking && this.props.onChange({...this.props.values, [name]: tmpArr});
  }
}

// @ts-ignore
export default withStyles(styles)(Step3) as React.ComponentClass<OwnProps>;
