import React, { useState, useEffect } from 'react';
import Typography from "@material-ui/core/Typography/Typography";
import '../../main.css';
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Loader from "../Loader";
import createStyles from "@material-ui/core/styles/createStyles";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import LectureVideo from "../LectureVideo";
import {uri} from "../../constants/API";
import { Player } from 'video-react';
import ReactFileReader from 'react-file-reader';
import '../../../node_modules/video-react/dist/video-react.css';

const Lectures = ({ fnDeleteLecture, fnCreateLecture, appContext, classes, theme, auth, LectureReducer, fnGetLectures }) => {
    const [lecturesIn, setLecturesIn] = useState([]);
    const [addLecture, setAddLecture] = useState(false);
    const [lectureTitle, setLectureTitle] = useState('');
    const [videError, setVideoError] = useState(null);
    const [videoData, setVideoData] = useState({});
    const [imageData, setImageData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const styles = (theme) =>
        createStyles({
            avatar: {
                backgroundColor: theme.palette.secondary.main,
                margin: theme.spacing(1),
            },
            form: {
                marginTop: theme.spacing(1),
                width: "100%", // Fix IE11 issue.
            },
            layout: {
                display: "block", // Fix IE11 issue.
                marginLeft: "50px",
                marginRight: theme.spacing(3),
                width: "80%",
                height: '100%'
            },
            paper: {
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                marginTop: theme.spacing(8),
                padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(3)}px`,
                backgroundColor: '#92ceff',
            },
            submit: {
                marginTop: theme.spacing(3),
            },
            errorText: {
                marginTop: theme.spacing(1),
            },
            imgPerson: {
                width: 300
            },
            card: {
                display: "flex",
                flexDirection: "column",
                height: "100%",
            },
            cardContent: {
                flexGrow: 1,
            },
            titleInput: {
                minWidth: "400px",
                width: "400px"
            },
            fileName: {
              marginLeft: "5px",
            },
            error: {

            },
            coverImageInput: {
                justifyContent: "space-between",
                width: "100%"
            }
        });

    useEffect(() => {
        console.log('before fnGetLectures');
        console.log('appContext ', appContext);
        console.log('theme ', theme);
        fnGetLectures();
        setIsLoading(true);
    }, [])

    useEffect(() => {
        if (!LectureReducer) return;
        if(!LectureReducer.lectures) return;
        // console.log('lectures ', LectureReducer.lectures.videos);
        setLecturesIn(LectureReducer.lectures.videos);
        setIsLoading(false);
    }, [LectureReducer.lectures]);

    useEffect(() => {
        if (!LectureReducer) return;
        if(!LectureReducer.lecture) return;
        console.log('lecture ', LectureReducer.lecture);
        const { status } = LectureReducer.lecture;
        if (status) {
            fnGetLectures();
        }
    }, [LectureReducer.lecture]);

    useEffect(() => {
        if (!LectureReducer) return;
        console.log('LectureReducer.deleted ', LectureReducer.deleted);
        if (LectureReducer.deleted) {
            fnGetLectures();
        }
    }, [LectureReducer.deleted]);

    useEffect(() => {
        if (!auth) return;
        console.log('auth ', auth);
        // setIsStudent(auth.isStudent);
    }, auth);

    const handleAddEditLecture = () => {
        console.log('here');
        setAddLecture(true);
    }
    const handleSubmit = () => {
        // console.log('save',  videoData);
        const { base64, name, size, type } = videoData;
        const { base64: imgBase64, name: imgName, type: imgType } = imageData;
        const payload = {title: lectureTitle, data: base64, name: name, size, type, coverImage: {data: imgBase64, name: imgName, type: imgType}}
        console.log('save ', payload);
        setIsLoading(true);
        fnCreateLecture(payload);
        setAddLecture(false);
    }
    const handleTitle = ({ currentTarget }) => {
        const { value } = currentTarget;
        setLectureTitle(value);
    };
    const getImage = (files) => {
        // console.log("FILES ", files);
        if (!files.type.includes('image')) {
            setVideoError('Not a valid cover image');
        } else {
            setImageData(files);
        }
    }
    const getFiles = (files) => {
        // console.log("FILES ", files);
        if (files.type !== 'video/mp4') {
            setVideoError('Not a valid video file');
        } else {
            setVideoData(files);
        }
    }

    const getBase64 = (file, cb) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            console.log('reader.result',  reader.result);
            cb(reader.result, null)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            cb(error, null);
        };
    }

    // const blobToBase64 = blob => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(blob);
    //     return new Promise(resolve => {
    //         reader.onloadend = () => {
    //             resolve(reader.result);
    //         };
    //     });
    // };
    const arrayBufferToString = (buffer) => {
        let binary = '';
        let bytes = new Uint8Array( buffer );
        let len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode( bytes[ i ] );
        }
        return binary;
    }
    const getFileData = (file, cb) => {
        let reader = new FileReader();
        reader.onloadend = () => {
            let base64data = reader.result;
            console.log(base64data);
            cb(base64data, null);
        }

        // reader.readAsDataURL(file);
        reader.readAsArrayBuffer(file);
        // // reader.readAsDataURL(file);
        // reader.onload = function () {
        //
        //     const arrayBuffer = reader.result;
        //     // let ab = arrayBufferToString(arrayBuffer)
        //     // console.log('arrayBuffer ', arrayBuffer );
        //     // const blob = new Blob([arrayBuffer], {type: 'video/mp4'});
        //     cb(arrayBufferToString(arrayBuffer), null)
        // };
        reader.onerror = function (error) {
            console.log('Error: ', error);
            cb(error, null);
        };
    }
    const handleChange = event => {
        const fileUploaded = event.target.files[0];
        console.log('fileUploaded ', fileUploaded);
        getBase64(fileUploaded , (result, err) =>{
            if (err) {
                setVideoError(err);
            } else {
                // console.log('result ', result);
                setImageData({ base64: result, name: fileUploaded.name, size: fileUploaded.size,
                    type: fileUploaded.type });
            }
        });
    };
    const handleVideoClick = event => {
        document.getElementById('hiddenVideoInput').click();
    };

    const handleVideoChange = event => {
        const fileUploaded = event.target.files[0];
        // console.log('fileUploaded ', fileUploaded);
        getBase64(fileUploaded , (result, err) =>{
            if (err) {
                setVideoError(err);
            } else {
                        setVideoData({
                            base64: result, name: fileUploaded.name, size: fileUploaded.size,
                            type: fileUploaded.type
                        });
            }
        });
        getFileData(fileUploaded , (blobResult, err) =>{
            if (err) {
                setVideoError(err);
            } else {
                console.log('blobResult ', blobResult);
                        setVideoData({
                            base64: blobResult, name: fileUploaded.name, size: fileUploaded.size,
                            type: fileUploaded.type
                        });
                // getBase64(blobResult,(res, err) => {
                //     if (err) {
                //         console.log('Error', err);
                //         setVideoError(err);
                //     } else {
                //         console.log('HERE ', res);
                //         setVideoData({
                //             base64: res, name: fileUploaded.name, size: fileUploaded.size,
                //             type: fileUploaded.type
                //         });
                //     }
                // });
            }
        });
    };
    const handleCoverClick = event => {
        document.getElementById('hiddenCoverInput').click();
    };
    const checkIfDisabled = () => {
        let canProceed = false;
        if (Object.keys(videoData).length < 2) {
            canProceed = true;
        }
        if (Object.keys(imageData).length < 2) {
            canProceed = true;
        }
        if (lectureTitle.length < 3) {
            canProceed = true;
        }
        return canProceed;
    }
    const playLecture = (id) => {
        console.log('ID ', id);
    }
    const deleteVideo = (id) => {
        console.log('ID ', id);
        fnDeleteLecture(id);
    }
    const videoUploaded = (files) => {
        console.log('files ', files);
    }
    return (
        <div style={{marginLeft: "100px", marginTop: "20px"}}>
            {console.log('lecturesIn ', lecturesIn.length)}
           <Typography variant="h4">Lectures</Typography>
            {isLoading && <img src={require("../../assets/ecg.gif")} /> }
            <div >
                {console.log(navigator.userAgent)}
                <Grid style={{marginTop: "10px" }} container={true} spacing={8}>

                    {!addLecture && lecturesIn.length > 0 && lecturesIn.map((v, i) => (
                        // <Player
                        //     key={i}
                        //     playsInline
                        //     width="50%"
                        //     height="50%"
                        //     poster={v.coverImage}
                        //     src={`${uri}/lecturevideo/${v.id}`}
                        // />
                        <LectureVideo isStudent={appContext.isStudent} deleteVideo={deleteVideo} key={i} id={v.id} title={v.title} coverImage={v.coverImage} />
                    ))}
                    {addLecture &&
                    <Grid item xs={12}>
                        <Card className={styles.paper}>
                            <CardContent style={{padding: "5px", justifyContent: "center", backgroundColor: '#92ceff', flex: 1}}>
                                <Grid item xs={12}>
                                <input style={{width: "400px"}} className={styles.titleInput} placeholder="Lecture Title" value={lectureTitle} type="text" onChange={handleTitle}/>
                                </Grid>
                                <Grid style={{display: "flex", justifyContent: "space-between",
                                    maxWidth: "400px"}} item xs={12}>
                                    <Button onClick={handleCoverClick}>
                                        Upload a Cover Image
                                    </Button>
                                    <input type='file'
                                        id="hiddenCoverInput"
                                        style={{display:'none'}}
                                        onChange={handleChange}
                                    />
                                    {imageData && <span style={{maxWidth: "200px", display: "inline-block",
                                        wordWrap: "break-word",padding: "5px", fontSize: "13px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: 500}}>{imageData.name}</span>}
                                </Grid>

                                <Grid style={{display: "flex", justifyContent: "space-between",
                                    maxWidth: "400px"}} item xs={12}>
                                    <Button onClick={handleVideoClick}>
                                        Upload Video
                                    </Button>
                                    <input type='file'
                                           id="hiddenVideoInput"
                                           style={{display:'none'}}
                                           onChange={handleVideoChange}
                                    />
                                    {videoData && <span style={{maxWidth: "200px", display: "inline-block",
                                        wordWrap: "break-word", padding: "5px", fontSize: "13px",
                                        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                                        fontWeight: 500}}>{videoData.name}</span>}
                                </Grid>

                                {videError && <Grid item xs={12}>
                                    <div style={{
                                        color: "red",
                                        border: "1px solid red",
                                        backgroundColor: "white",
                                        justifyContent: "center",
                                        display: "flex"
                                    }}>{videError}</div>
                                </Grid>}
                                <Grid item xs={12}>
                                <Button disabled={checkIfDisabled()} fullWidth={true} onClick={() => handleSubmit()}>{isLoading ?
                                    <Loader height={20} /> : "Save Lecture"}</Button>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>}
                    {!appContext.isStudent && !addLecture &&  <Grid item={true} sm={6} md={4} lg={3}>
                        <Button fullWidth={true} onClick={() => handleAddEditLecture()}>{isLoading ?
                            <Loader  width={20} />: "Add Lecture"}</Button>
                    </Grid>
                    }
                </Grid>
            </div>
        </div>
    );
}

export default Lectures;





