import {AuthActions, EcgActions} from "../../constants/Actions";
import initialSheet from "../../constants/InitialSheet";
import {AnalysisInterface} from "../../types/Analysis";

export interface EcgState {
  answer: AnalysisInterface,
  currentStep: number,
  currentWaveform: number;
  enlargeImage: boolean;
  selectedAnswer: {
    module_id: number;
  };
}

const initialState:EcgState = {
  answer: initialSheet,
  currentStep: 1,
  currentWaveform: 0,
  enlargeImage: false,
  selectedAnswer: {
    module_id: -1,
  },
};

// TODO: change payload type
// TODO: change data type
interface Action {
  type: EcgActions & AuthActions.Logout,
  payload: any,
  data: any
}

export default function reducer(state:EcgState = initialState, action:Action) {
  const {type, payload, data} = action;
  switch (type) {
    case EcgActions.SetAnswer: {
      return {...state, answer: {...state.answer, ...payload}}
    }
    case EcgActions.SetSelectedAnswer: {
      return {...state, selectedAnswer: payload}
    }
    case EcgActions.SetCurrentStep: {
      return {...state, currentStep: payload, currentWaveform: 0}
    }
    case EcgActions.GetStudentAnswerSuccess:
    case EcgActions.GetAnswerSuccess: {
      return {...state, answer: data.analysis}
    }
    case EcgActions.SetCurrentWaveForm: {
      return {...state, currentWaveform: payload}
    }
    case EcgActions.ToggleEnlargeImage: {
      return {...state, enlargeImage: !state.enlargeImage}
    }
    case AuthActions.Logout: {
      return {...initialState}
    }
    default:
      return state;
  }
}
