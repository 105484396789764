import * as React from 'react';
import {Theme, WithStyles} from '@material-ui/core';
import createStyles from '@material-ui/core/styles/createStyles';
import withStyles from '@material-ui/core/styles/withStyles';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Slide from '@material-ui/core/Slide/Slide';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Toolbar from '@material-ui/core/Toolbar/Toolbar';
import Typography from '@material-ui/core/Typography/Typography';
import Button from '@material-ui/core/Button/Button';
import List from '@material-ui/core/List/List';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Loader from '../../components/Loader';
import Drawer from '@material-ui/core/Drawer/Drawer';
import {Colors} from '../../theme/mainTheme';
import {analysisSteps} from '../../constants/AnalysisSteps';
import {blue} from '@material-ui/core/colors';
import Zoom from '@material-ui/core/Zoom/Zoom';
import Step1 from './Step1/Step1';
import Step2 from './Step2/Step2';
import PWaveStep from './Step2/PWaveStep';
import PRIntervalStep from './Step2/PRIntervalStep';
import QRSComplexStep from './Step2/QRSComplexStep';
import STSegmentStep from './Step2/STSegmentStep';
import TWaveStep from './Step2/TWaveStep';
import QTIntervalStep from './Step2/QTIntervalStep';
import Step3 from './Step3/Step3';
import {AnalysisInterface, AnalysisPRInterval, AnalysisPWave, AnalysisQRSComplex, AnalysisQTInterval, AnalysisStep1, AnalysisStep2, AnalysisStep3, AnalysisSTSegment, AnalysisTWave} from '../../types/Analysis';
import AnalysisSummary from './components/AnalysisSummary';
import {WithECGContext, withECGContext} from '../../context/ECGContext';
import {WithModuleContext, withModuleContext} from '../../context/ModuleContext';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import {WithAppContext, withAppContext} from '../../context/AppContext';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import PromptDialog from './components/PromptDialog';
import {TransitionProps} from '@material-ui/core/transitions';
import {EditAnalysisFunction} from '../Main/Main';
import ResizeDetector from 'react-resize-detector';

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface State {
  selectedStep: number | null;
  selectedSubStep: number | null;
  analysis: AnalysisInterface;
  correctAnswer: AnalysisInterface | null;
  ecgImage: string | null;
  analysisLoading: boolean;
  imageLoading: boolean;
  isECGEnlarged: boolean;
  isPromptDialogOpen: boolean;
  promptDialogTitle: string;
  promptDialogBody: string[];
  validTo: number;
  validToSub: number;
  specialQRSRule: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: '#041333 !important'
    },
    listItemRoot: {
      '&:hover': {
        backgroundColor: '#041333 !important'
      }
    },
    textContainer: {
      '&::-webkit-scrollbar': {
        width: '0.4em'
      },
      '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(0,0,0,.5)',
        outline: '1px solid slategrey'
      }
    },
    appBar: {
      position: 'sticky',
      top: 0,
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
    flex: {
      flex: 1,
    },
    root: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden'
    },
    drawer: {
      width: 200,
      flexShrink: 0,
      backgroundColor: theme.palette.primary.main,
    },
    summaryDrawer: {
      width: 340,
      flexShrink: 0,
      borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
    },
    drawerPaper: {
      width: theme.spacing(25),
      backgroundColor: theme.palette.primary.main,
      color: Colors.white,
    },
    summaryDrawerPaper: {
      width: 340,
      // color: Colors.white,
    },
    summaryDrawerContentPaper: {
      padding: theme.spacing(1),
      flex: 1,
    },
    content: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      padding: 0,
    },
    summaryDrawerContent: {
      padding: theme.spacing(1),
    },
    ecgImage: {
      // width: `calc(100% + ${theme.spacing(6)}px)`,
      width: '100%',
      // margin: -3 * theme.spacing(1),
      // marginBottom: theme.spacing(1),
    },
    subStepsItems: {
      backgroundColor: blue['300'],
      '&:hover': {
        backgroundColor: blue['200'],
      },
    },
    instructionText: {
      marginTop: theme.spacing(6),
    },
    toolbar: theme.mixins.toolbar,
  });

interface OwnProps {
  open: number | null;
  studentAnswerId: number | null;
  onClose: (continueMarking: boolean, answerId?: number) => void;
  isMarking: boolean;
  handleEditAnalysis: EditAnalysisFunction;
  createdNewAnswer: (answerId: number)=>void;
}

interface Props extends OwnProps, WithStyles<typeof styles>, WithECGContext, WithModuleContext, WithAppContext {
}

const initialState: State = {
  specialQRSRule: false,
  selectedStep: null,
  selectedSubStep: null,
  correctAnswer: null,
  analysis: {
    moduleId: 0, // module_id
    answerId: 0, // answer_id
    ecgTitle: '', // ecgTitle
    caseScenario: '', // overview
    ecgImage: false, // ecgImage
    calibration: 0, // calibration
    step1: {
      ratePerMinute: '', // rate_per_min
      rate: null,
      regularity: null,
      irregularSelection: [],
      qrsWidth: null, // qrs_width
    },
    step2: {
      waveFormAnalysisMessage: '',
    },
    pWave: {
      pWave: null, // pwaves_present
      pWaveAxis: [], // pwaves
      pInRelationToQRS: [],
      pWaveMorphology: [],
    },
    prInterval: {
      prInterval: null, // pr_interval
      prIntervalMeasure: '', // pr_interval_ms
      prLength: null,
      prSegment: [], // pr_segment
    },
    qrsComplex: {
      qrsComplexWidthMeasurement: '', // qrs_width_ms
      qrsComplexWidth: [], // qrs_complex
      qrsComplexAxisMeasurement: '', // qrs_axes
      qrsComplexAxis: null,
      qrsComplexAxisCol1: [],
      qrsComplexAxisCol2: [],
    },
    stSegment: {
      stSegmentCol1: [], // st_segments
      stSegmentElevation: [],
      stSegmentElevationSubItems: [], // st_segments_elevated
      stSegmentDepression: [],
      stSegmentDepressionSubItems: [], // st_segments_depressed
      stSegmentRepolarisationAbnormality: [],
      stSegmentRepolarisationAbnormalitySubItems: [],
      stSegmentCol3: [],
    },
    tWave: {
      tWaveCol1: [], // t_waves
      tWaveCol2Part1: [],
      tWaveInversion: [],
      tWaveInversionSubItems: [],
      tWaveCol2Part2: [],
      tWaveAbnormality: [],
      tWaveAbnormalitySubItems: [],
      tWaveCol3: [],
    },
    qtInterval: {
      qtIntervalMeasurement: '', // qt_calc
      rrIntervalMeasurement: '', // rr_calc
      qtcIntervalMeasurement: '', // qtc_calc
      wideQRSAdjustedMeasurement: '',
      qtInterval: null,
    },
    step3: {
      optionalQuestion1: '',
      optionalQuestion2: '',
      optionalQuestion1Answers: ['', '', '', ''],
      optionalQuestion1CorrectAnswer: null,
      optionalQuestion2Answers: ['', '', '', ''],
      optionalQuestion2CorrectAnswer: null,
    },
  },
  ecgImage: null,
  analysisLoading: true,
  imageLoading: true,
  isECGEnlarged: false,
  isPromptDialogOpen: false,
  promptDialogTitle: '',
  promptDialogBody: [],
  validTo: 0,
  validToSub: 0,
};

class Analysis extends React.Component<Props, State> {

  readonly state = initialState;

  ecgRef: any = React.createRef<HTMLDivElement>();

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    const {
      open,
      appContext: {
        isStudent
      },
      eCGContext: {
        getAnalysis,
        saveStudentAnswer,
        getStudentAnswer
      },
      moduleContext: {
        selectedModule,
        getECGImageFn,
      },
      studentAnswerId,
      isMarking,
      createdNewAnswer
    } = this.props;
    // Process when Analysis is open
    if (prevProps.open === null && open !== null) {
      const moduleId = selectedModule !== null && selectedModule.id || 0;
      if (isStudent || isMarking) {
        if (studentAnswerId !== null) {
          if (isMarking) {
            getAnalysis(open).then(res => {
              this.setState({correctAnswer: res});
            })
          }
          getStudentAnswer(studentAnswerId).then(res => {
            const a: AnalysisInterface = {
              moduleId: res.moduleId,
              answerId: res.answerId,
              ecgTitle: res.static.ecgName,
              caseScenario: res.static.overview,
              ecgImage: res.static.ecgImage,
              calibration: res.static.calibration,
              step1: res.analysis.step1 as AnalysisStep1,
              step2: res.analysis.step2 as AnalysisStep2,
              pWave: res.analysis.pWave as AnalysisPWave,
              prInterval: res.analysis.prInterval as AnalysisPRInterval,
              qrsComplex: res.analysis.qrsComplex as AnalysisQRSComplex,
              stSegment: res.analysis.stSegment as AnalysisSTSegment,
              tWave: res.analysis.tWave as AnalysisTWave,
              qtInterval: res.analysis.qtInterval as AnalysisQTInterval,
              step3: res.analysis.step3 as AnalysisStep3,
            };
            this.setState({analysisLoading: false, analysis: a});
          });
        } else {
          saveStudentAnswer({
            answerId: open,
            moduleId: moduleId,
          }).then(res => {
            createdNewAnswer(res.studentAnswerId);
            getStudentAnswer(res.studentAnswerId).then(res => {
              const a: AnalysisInterface = {
                moduleId: res.moduleId,
                answerId: res.answerId,
                ecgTitle: res.static.ecgName,
                caseScenario: res.static.overview,
                ecgImage: res.static.ecgImage,
                calibration: res.static.calibration,
                step1: res.analysis.step1 as AnalysisStep1,
                step2: res.analysis.step2 as AnalysisStep2,
                pWave: res.analysis.pWave as AnalysisPWave,
                prInterval: res.analysis.prInterval as AnalysisPRInterval,
                qrsComplex: res.analysis.qrsComplex as AnalysisQRSComplex,
                stSegment: res.analysis.stSegment as AnalysisSTSegment,
                tWave: res.analysis.tWave as AnalysisTWave,
                qtInterval: res.analysis.qtInterval as AnalysisQTInterval,
                step3: res.analysis.step3 as AnalysisStep3,
              };
              this.setState({analysisLoading: false, analysis: a});
            });
          });
        }

        getECGImageFn(moduleId, open).then(res => {
          this.setState({ecgImage: res, imageLoading: false});
        })
      } else {
        getAnalysis(open).then(res => {
          console.log('res ', res);
          this.setState({analysis: res, analysisLoading: false});
          if (res.ecgImage) {
            getECGImageFn(moduleId, open).then(res => {
              this.setState({ecgImage: res, imageLoading: false});
            })
          }
        }).catch(() => {
        });
      }
    }
    prevState.analysis !== this.state.analysis && this.handlePromptStuff();
    (prevState.selectedStep !== this.state.selectedStep ||
      prevState.selectedSubStep !== this.state.selectedSubStep) && this.handlePromptStuff();
  }

  private shouldStepBeDisabled = (step: number, subStep?: number | null) => {
    const {
      analysis: {
        step1: {
          ratePerMinute,
          rate,
          regularity,
          irregularSelection,
          qrsWidth,
        },
        pWave: {
          pWave,
          pInRelationToQRS,
        },
        prInterval: {
          prInterval,
          prIntervalMeasure,
          prLength,
        },
        qrsComplex: {
          qrsComplexWidthMeasurement,
          qrsComplexWidth,
          qrsComplexAxis,
          qrsComplexAxisCol1,
          qrsComplexAxisCol2,
        },
        stSegment: {
          stSegmentCol1,
          stSegmentElevation,
          stSegmentElevationSubItems,
          stSegmentDepression,
          stSegmentDepressionSubItems,
          stSegmentRepolarisationAbnormality,
          stSegmentRepolarisationAbnormalitySubItems,
          stSegmentCol3,
        },
        tWave: {
          tWaveCol1,
          tWaveCol2Part1,
          tWaveInversion,
          tWaveInversionSubItems,
          tWaveCol2Part2,
          tWaveAbnormality,
          tWaveAbnormalitySubItems,
          tWaveCol3,
        },
      },
    } = this.state;
    const isStep1Valid = Boolean((Number(ratePerMinute)) &&
      rate &&
      (regularity === 'Regular' || (regularity === 'Irregular' && irregularSelection && irregularSelection.length)) &&
      qrsWidth);
    const isPWaveValid = Boolean((pWave === 'Absent / unsure if present' ||
      (pWave === 'Present' &&
        pInRelationToQRS && pInRelationToQRS.length )));
    const isPRIntervalValid = Boolean(
      (prInterval && (prInterval === 'Constant' ? (Number(prIntervalMeasure) && prLength) : true)));
    const isQRSComplexValid = Boolean((Number(qrsComplexWidthMeasurement &&
      ((qrsComplexWidth && qrsComplexWidth.length) ||
        (qrsComplexAxisCol1 && qrsComplexAxisCol1.length) ||
        (qrsComplexAxisCol2 && qrsComplexAxisCol2.length) ||
        !!qrsComplexAxis
      ))));
    const isSTSegmentValid = Boolean(
      (stSegmentCol1 && stSegmentCol1.length) ||
      (stSegmentElevation && stSegmentElevation.length) ||
      (stSegmentElevationSubItems && stSegmentElevationSubItems.length) ||
      (stSegmentDepression && stSegmentDepression.length) ||
      (stSegmentDepressionSubItems && stSegmentDepressionSubItems.length) ||
      (stSegmentRepolarisationAbnormality && stSegmentRepolarisationAbnormality.length) ||
      (stSegmentRepolarisationAbnormalitySubItems && stSegmentRepolarisationAbnormalitySubItems.length) ||
      (stSegmentCol3 && stSegmentCol3.length));
    const isTWaveValid = Boolean(
      (tWaveCol1 && tWaveCol1.length) ||
      (tWaveCol2Part1 && tWaveCol2Part1.length) ||
      (tWaveInversion && tWaveInversion.length) ||
      (tWaveInversionSubItems && tWaveInversionSubItems.length) ||
      (tWaveCol2Part2 && tWaveCol2Part2.length) ||
      (tWaveAbnormality && tWaveAbnormality.length) ||
      (tWaveAbnormalitySubItems && tWaveAbnormalitySubItems.length) ||
      (tWaveCol3 && tWaveCol3.length));

    // Return whether a ListItem should be disabled
    switch (step) {
      case 0: {
        // Step 1 will never be disabled
        return false;
      }
      case 1: {
        switch (subStep) {
          case undefined: {
            // Step 2 will be enabled once isStep1Valid === true
            return false;
            // return !isStep1Valid;
          }
          case 0: {
            // P wave will be enabled once isStep1Valid === true
            return !isStep1Valid;
          }
          case 1: {
            // PR interval will be enabled once isStep1Valid === true
            return !(isStep1Valid);
            // PR interval will be enabled once isPWaveValid === true
            // return !(isStep1Valid && isPWaveValid);
          }
          case 2: {
            // QRS complex will be enabled once isPWaveValid === true
            return !(isStep1Valid);
            // return !(isStep1Valid && isPWaveValid);
            // QRS complex will be enabled once isPRIntervalValid === true
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid);
          }
          case 3: {
            // ST Segment will be enabled once isPRIntervalValid === true
            return !(isStep1Valid);
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid);
            // ST Segment will be enabled once isQRSComplexValid === true
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid);
          }
          case 4: {
            // T wave will be enabled once isQRSComplexValid === true
            return !(isStep1Valid);
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid);
            // T wave will be enabled once isSTSegmentValid === true
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid);
          }
          case 5: {
            // QT interval will be enabled once isSTSegmentValid === true
            return !(isStep1Valid);
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid);
            // QT interval will be enabled once isTWaveValid === true
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid && isTWaveValid);
          }
          case 6: {
            // QT interval will be enabled once isSTSegmentValid === true
            return !(isStep1Valid);
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid && isTWaveValid);
            // QT interval will be enabled once isTWaveValid === true
            // return !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid && isTWaveValid);
          }
          default: {
            return true;
          }
        }
      }
      case 2: {
        // return false;
        return qrsWidth === 'Wide complex' && rate === 'Tachycardia' ?
          !(isStep1Valid && isQRSComplexValid) :
          !(isStep1Valid && isPWaveValid && isPRIntervalValid && isQRSComplexValid && isSTSegmentValid && isTWaveValid);
      }
      default: {
        return true;
      }
    }
  };

  private handleClose = () => {
    const {onClose} = this.props;
    this.setState({...initialState});
    onClose(false);
  };

  private handlePromptDialogClose = () => {
    this.setState({isPromptDialogOpen: false})
  };

  handleDownloadTakeHome = () => {
    const {correctAnswer} = this.state;
    const {moduleContext: {getTakeHomeDocFn}, isMarking} = this.props;
    console.log(this.props);
    if (isMarking) {
      this.setState({analysisLoading: true});
      getTakeHomeDocFn(correctAnswer && correctAnswer.answerId || 0, correctAnswer && correctAnswer.moduleId || 0).then(result => {
        const downloadLink = document.createElement('a');
        const fileName = 'TakeHome.pdf';
        downloadLink.href = result;
        downloadLink.download = fileName;
        downloadLink.click();
        this.setState({analysisLoading: false});
      })
    }
  };

  private handleEnlargeECG = () => {
    const {isECGEnlarged} = this.state;
    this.setState({isECGEnlarged: !isECGEnlarged});
  };

  private handleSave = () => {
    const {
      appContext: {
        isStudent,
      },
      eCGContext: {
        saveAnalysis,
        saveStudentAnswer
      },
      studentAnswerId
    } = this.props;
    const {analysis} = this.state;
    if (isStudent) {
      saveStudentAnswer({...analysis, studentAnswerId: studentAnswerId || 0}).then(() => {
        const {onClose} = this.props;
        this.setState({...initialState});
        onClose(false);
      });
    } else {
      saveAnalysis(analysis).then(() => {
        const {onClose} = this.props;
        this.setState({...initialState});
        onClose(false);
      })
    }
  };

  private handleComplete = () => {
    const {
      eCGContext: {
        saveStudentAnswer
      },
      studentAnswerId
    } = this.props;
    const {analysis} = this.state;
    saveStudentAnswer({
      ...analysis,
      studentAnswerId: studentAnswerId || 0,
      completed: true
    }).then(res => {
      console.log(res);
      const {onClose} = this.props;
      this.setState({...initialState});
      onClose(true, res.studentAnswerId);
    });
  };

  checkSpecialQRSRule = (index: number | null = null) => {
    const {analysis} = this.state;
    const {step1: {qrsWidth, rate}, qrsComplex} = analysis;
    if (qrsWidth === 'Wide complex' && rate === 'Tachycardia') {
      if (index !== null && index !== 2) {
        const dialogTitle = 'In the setting of a wide complex tachycardia, it is advised to analyse the QRS complex first. Please';
        let dialogBody = [];
        qrsComplex.qrsComplexWidthMeasurement === '' && dialogBody.push('Measure the QRS width');
        qrsComplex.qrsComplexWidth.length === 0 && dialogBody.push('Describe the QRS morphology');
        qrsComplex.qrsComplexAxisMeasurement === '' && dialogBody.push('Calculate the QRS axis');
        ((qrsComplex.qrsComplexAxis as string) === '' || (qrsComplex.qrsComplexAxis as string | null) === null) && dialogBody.push('Indicate whether the QRS axis is normal');
        qrsComplex.qrsComplexAxisCol1.length === 0 && qrsComplex.qrsComplexAxisCol2.length === 0 && dialogBody.push('Describe the QRS size in the chest leads');
        if (dialogBody.length !== 0) {
          this.setState({
            promptDialogBody: dialogBody,
            promptDialogTitle: dialogTitle,
            selectedStep: 1, selectedSubStep: 2,
            isPromptDialogOpen: true
          });
          return true;
        } else {
          this.setState({
            // promptDialogBody: dialogBody,
            // promptDialogTitle: dialogTitle,
            selectedStep: 1, selectedSubStep: index,
            // isPromptDialogOpen: true
          });
          return true
        }
      }
      this.setState({selectedStep: 1, selectedSubStep: 2});
      return true;
    } else {
      return false;
    }
  };

  private handleStepSelector = (index: number) => () => {
    const {promptDialogBody, validTo} = this.state;
    if (index === 0) {
      this.setState({selectedStep: index, selectedSubStep: null})
    } else if (index === 1) {
      if (!this.checkSpecialQRSRule()) {
        promptDialogBody.length !== 0 && validTo < index ?
          this.setState({isPromptDialogOpen: true}) :
          this.setState({selectedStep: index, selectedSubStep: null});
      }
    } else {
      this.setState({selectedStep: index, selectedSubStep: null});
    }
  };

  private handleSubStepSelector = (index: number) => () => {
    const {promptDialogBody, validToSub} = this.state;
    if (!this.checkSpecialQRSRule(index)) {
      (promptDialogBody.length !== 0 && validToSub < index) ?
        this.setState({isPromptDialogOpen: true}) :
        this.setState({selectedSubStep: index});
    }
  };

  private selectedStepComponent = () => {
    const {selectedStep, selectedSubStep, analysis: {step1, step2, pWave, prInterval, qrsComplex, stSegment, tWave, qtInterval, step3}, correctAnswer} = this.state;
    const {
      classes, appContext: {
        isStudent
      },
      isMarking,
    } = this.props;
    return (isMarking || selectedStep === 2) ?
      <Step3 values={step3} onChange={this.handleChange('step3')} isStudent={isStudent || false} isMarking={isMarking} correctAnswer={correctAnswer} /> :
      selectedStep === 0 ?
        <Step1 values={step1} onChange={this.handleChange('step1')} isMarking={isMarking} /> :
        selectedStep === 1 ?
          selectedSubStep === 0 ?
            <PWaveStep values={pWave} onChange={this.handleChange('pWave')} isMarking={isMarking} /> :
            selectedSubStep === 1 ?
              <PRIntervalStep values={prInterval} onChange={this.handleChange('prInterval')} isMarking={isMarking} /> :
              selectedSubStep === 2 ?
                <QRSComplexStep values={qrsComplex} onChange={this.handleChange('qrsComplex')} isMarking={isMarking} /> :
                selectedSubStep === 3 ?
                  <STSegmentStep values={stSegment} onChange={this.handleChange('stSegment')} isMarking={isMarking} /> :
                  selectedSubStep === 4 ?
                    <TWaveStep values={tWave} onChange={this.handleChange('tWave')} isMarking={isMarking} /> :
                    selectedSubStep === 5 ?
                      <QTIntervalStep
                        values={qtInterval}
                        onChange={this.handleChange('qtInterval')}
                        showWideQRS={Number(qrsComplex.qrsComplexWidthMeasurement) > 100 && Number(qtInterval.qtIntervalMeasurement) > 0}
                        isMarking={isMarking}
                      /> :
                      <Step2 values={step2} onChange={this.handleChange('step2')} isStudent={isStudent || true} isMarking={isMarking} /> :
          <Instruction className={classes.instructionText} />
  };

  private handleChange = (step: keyof AnalysisInterface) => (values: AnalysisStep1 | AnalysisStep2 | AnalysisPWave | AnalysisPRInterval | AnalysisQRSComplex | AnalysisSTSegment | AnalysisTWave | AnalysisQTInterval | AnalysisStep3) => {
    const ratePerMinute = step === 'step1' ? (values as AnalysisStep1).ratePerMinute : this.state.analysis.step1.ratePerMinute;
    const qtInterval = step === 'qtInterval' ? (values as AnalysisQTInterval) : this.state.analysis.qtInterval;
    const qtIntervalValue = Number(qtInterval.qtIntervalMeasurement) / 1000;
    const rrInterval = 60000 / Number(ratePerMinute);
    const qtc = ((qtIntervalValue / Math.sqrt(rrInterval / 1000)) * 1000).toFixed(0);
    let wideQRSAdjustedMeasurement = null;
    const qrsWidth = Number(this.state.analysis.qrsComplex.qrsComplexWidthMeasurement);
    if (qrsWidth > 100 && qtIntervalValue) {
      wideQRSAdjustedMeasurement = Number(qtc) - (qrsWidth - 100);
    }
    this.setState({
      analysis: {
        ...this.state.analysis,
        [step]: values,
        qtInterval: {
          ...qtInterval,
          rrIntervalMeasurement: String(rrInterval),
          qtcIntervalMeasurement: String(qtc),
          wideQRSAdjustedMeasurement: wideQRSAdjustedMeasurement !== null ? String(wideQRSAdjustedMeasurement) : ''
        },
      }
    });
  };

  private handlePromptStuff = () => {
    const {
      analysis: {
        step1,
        step3,
        pWave,
        prInterval,
        qrsComplex,
        stSegment,
        tWave
      },
      selectedStep,
    } = this.state;
    const dialogBody: string[] = [];
    let dialogTitle = 'Before proceeding with the waveform analysis, please';
    let validTo = 0;
    let validToSub = -1;
    // Handle prompts for Step 1
    if (selectedStep === null || selectedStep === 0) {
      (step1.ratePerMinute === '' ||
        (step1.rate !== 'Bradycardia' && step1.rate !== 'Normal rate' && step1.rate !== 'Tachycardia')
      ) && dialogBody.push('Specify the ventricular rate');
      step1.regularity !== 'Irregular' && step1.regularity !== 'Regular' && dialogBody.push('Specify whether the rhythm is regular or irregular');
      step1.regularity === 'Irregular' && (step1.irregularSelection as []).length === 0 && dialogBody.push('Describe the irregularity');
      step1.qrsWidth !== 'Narrow complex' && step1.qrsWidth !== 'Wide complex' && step1.qrsWidth !== 'Various widths' && dialogBody.push('Specify whether the QRS complexes are narrow or wide');
    }
    // Handle prompts for Step 2
    if (dialogBody.length === 0) {
      validTo = 1;
      // step2.waveFormAnalysisMessage === "" && dialogBody.push("Please provide a message for waveform analysis");
    }
    // // Handle prompts for P Wave
    if (dialogBody.length === 0) {
      validToSub = 0;
      dialogTitle = 'Before proceeding with the waveform analysis, please';
      // dialogTitle = "Before analysing the PR interval, please";
      pWave.pWave !== 'Absent / unsure if present' && pWave.pWave !== 'Present' && dialogBody.push('Specify whether P wave is present');
      //pWave.pWave === 'Present' && pWave.pWaveAxis.length === 0 && dialogBody.push('Specify the P wave axis');
      pWave.pWave === 'Present' && pWave.pInRelationToQRS.length === 0 && dialogBody.push('Analyse the P wave relation to the QRS');
      // pWave.pWave === "Present" && pWave.pWaveMorphology.length === 0 && dialogBody.push("Describe the P wave morphology");
    }
    // Handle Prompts for PR Interval
    if (dialogBody.length === 0) {
      validToSub = 1;
      dialogTitle = 'Before proceeding with the waveform analysis, please';
      // dialogTitle = "Before analysing the QRS complex, please";
      ((prInterval.prInterval !== 'Constant' && prInterval.prInterval !== 'Irrelevant' && prInterval.prInterval !== 'Variable') ||
        (prInterval.prInterval === 'Constant' && (prInterval.prIntervalMeasure === '' || (prInterval.prLength as string) === '' || (prInterval.prLength as string | null) === null)))
      && dialogBody.push('Analyse the PR interval');
    }
    // Handle Prompts for QRS Complex
    if (dialogBody.length === 0) {
      validToSub = 2;
      dialogTitle = 'Before proceeding with the waveform analysis, please';
      qrsComplex.qrsComplexWidthMeasurement === '' && dialogBody.push('Measure the QRS width');
      // qrsComplex.qrsComplexWidth.length === 0 && dialogBody.push("Describe the QRS morphology");
      qrsComplex.qrsComplexAxisMeasurement === '' && dialogBody.push('Calculate the QRS axis');
      ((qrsComplex.qrsComplexAxis as string) === '' || (qrsComplex.qrsComplexAxis as string | null) === null) && dialogBody.push('Indicate whether the QRS axis is normal');
      qrsComplex.qrsComplexAxisCol1.length === 0 && qrsComplex.qrsComplexAxisCol2.length === 0 && dialogBody.push('Describe the QRS size in the chest leads');
    }
    // Handle Prompts for ST Segment
    if (dialogBody.length === 0) {
      validToSub = 3;
      dialogTitle = 'Before proceeding with the waveform analysis, please';
      (stSegment.stSegmentCol1.length === 0) &&
      (stSegment.stSegmentCol3.length === 0) &&
      (stSegment.stSegmentDepression.length === 0) &&
      (stSegment.stSegmentDepressionSubItems.length === 0) &&
      (stSegment.stSegmentElevation.length === 0) &&
      (stSegment.stSegmentElevationSubItems.length === 0) &&
      (stSegment.stSegmentRepolarisationAbnormality.length === 0) &&
      (stSegment.stSegmentRepolarisationAbnormalitySubItems.length === 0)
      && dialogBody.push('Analyse the ST segment');
    }
    // Handle Prompts for T Waves
    if (dialogBody.length === 0) {
      validToSub = 4;
      dialogTitle = 'Before proceeding with the waveform analysis, please';
      (tWave.tWaveCol1.length === 0) &&
      (tWave.tWaveAbnormality.length === 0) &&
      (tWave.tWaveAbnormalitySubItems.length === 0) &&
      (tWave.tWaveCol2Part1.length === 0) &&
      (tWave.tWaveCol2Part2.length === 0) &&
      (tWave.tWaveCol3.length === 0) &&
      (tWave.tWaveInversion.length === 0) &&
      (tWave.tWaveInversionSubItems.length === 0)
      && dialogBody.push('Analyse the T waves');
    }
    if (dialogBody.length === 0) {
      validTo = (step3.optionalQuestion1 && (step3.optionalQuestion1CorrectAnswer as number) > -1) ? 2 : validTo;
      validTo = (step3.optionalQuestion2 && (step3.optionalQuestion2CorrectAnswer as number) > -1) ? 2 : validTo;
    } else {
      if (step1.qrsWidth === 'Wide complex' && step1.rate === 'Tachycardia') {
        const isSpecialValid =
          qrsComplex.qrsComplexWidthMeasurement !== '' &&
          qrsComplex.qrsComplexWidth.length !== 0 &&
          qrsComplex.qrsComplexAxisMeasurement !== '' &&
          ((qrsComplex.qrsComplexAxis as string) !== '' || (qrsComplex.qrsComplexAxis as string | null) !== null) &&
          (qrsComplex.qrsComplexAxisCol1.length !== 0 || qrsComplex.qrsComplexAxisCol2.length !== 0);
        validTo = isSpecialValid ? 2 : validTo;
      }
    }
    this.setState({
      promptDialogBody: dialogBody,
      promptDialogTitle: dialogTitle,
      validTo,
      validToSub,
    });
  };

  public render() {
    const {open, classes, appContext: {isStudent}, isMarking} = this.props;
    const {
      selectedStep, selectedSubStep, analysis, ecgImage, analysisLoading,
      imageLoading, isECGEnlarged, isPromptDialogOpen, promptDialogTitle,
      promptDialogBody,
      validTo,
      correctAnswer
    } = this.state;
    if (analysis !== undefined) {
      const {caseScenario} = analysis;
      const selectedComponent = this.selectedStepComponent();
      return (
        <Dialog
          fullScreen
          open={open !== null}
          onClose={this.handleClose}
          TransitionComponent={Transition}
        >
          <AppBar className={classes.appBar}>
            <Toolbar>
              <Loader height={40} width={50} loops={1} />
              <Typography variant="h6" color="inherit" noWrap={true} className={classes.title}>
                ECG Online
              </Typography>
              {isMarking && <Button color="inherit" disabled={analysisLoading || (correctAnswer !== null && correctAnswer.takeHome === false)} onClick={this.handleDownloadTakeHome}>
                Download Take Home Message
              </Button>}
              <Button color="inherit" disabled={ecgImage === null} onClick={this.handleEnlargeECG}>
                enlarge ECG
              </Button>
              {!isMarking && <Button color="inherit" onClick={this.handleSave}>
                save
              </Button>}
              {isStudent && !isMarking && <Button color="inherit" disabled={validTo !== 2} onClick={this.handleComplete}>
                Submit your answer
              </Button>}
              <IconButton onClick={this.handleClose}>
                <Close style={{color: 'white'}} />
              </IconButton>
            </Toolbar>
            <Fade in={analysisLoading || imageLoading}><LinearProgress variant="query" /></Fade>
          </AppBar>
          <div className={classes.root}>
            <Drawer
              className={classes.drawer}
              variant="permanent"
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              <List>
                {analysisSteps.map((step, index) => (
                  <React.Fragment key={index}>
                    <ListItem button selected={((isMarking && index === 2) || (!isMarking && selectedStep === index))} disabled={isMarking || this.shouldStepBeDisabled(index)}
                              onClick={this.handleStepSelector(index)}
                              classes={{
                                selected: classes.selected,
                                root: classes.listItemRoot

                              }}
                    >
                      <ListItemText
                        primary={step.primary}
                        primaryTypographyProps={{color: 'inherit', variant: 'body1'}}
                        secondaryTypographyProps={{color: 'inherit', variant: 'subtitle1'}}
                        secondary={step.secondary} />
                    </ListItem>
                    <div
                      style={{
                        maxHeight: selectedStep === index ? 1000 : 0,
                        transition: `max-height 0.4s ease`,
                      }}>
                      {step.subStep.map((subStep, subStepIndex) =>
                        <Zoom key={subStepIndex} in={selectedStep === index} timeout={{enter: subStepIndex * 100, exit: (step.subStep.length * 100) - (subStepIndex * 100)}}>
                          <ListItem
                            disabled={this.shouldStepBeDisabled(index, subStepIndex)}
                            className={classes.subStepsItems}
                            button
                            selected={selectedSubStep === subStepIndex && selectedStep === index}
                            onClick={this.handleSubStepSelector(subStepIndex)}
                          >
                            <ListItemText
                              primary={subStep}
                              primaryTypographyProps={{color: 'inherit'}} />
                          </ListItem>
                        </Zoom>,
                      )}
                    </div>
                  </React.Fragment>
                ))}
              </List>
            </Drawer>
            <ResizeDetector
              handleWidth
              handleHeight
              render={({width, height}) => (
                <main className={classes.content} style={{height: '100%'}}>
                  <div
                    ref={this.ecgRef}
                    style={{
                      height: `${((width - 200 - 340) / (height)) * 50}%`
                    }}>
                    {ecgImage === null && <img src={require('../../assets/2000x1000.png')} alt="" className={classes.ecgImage} />}
                    {ecgImage !== null &&

                    <PinchZoomPan maxScale={4} doubleTapBehavior="zoom" position="topLeft">
                      <img src={ecgImage} alt="" className={classes.ecgImage} />
                    </PinchZoomPan>
                    }
                  </div>
                  <div className={classes.textContainer}
                       style={{
                         height: `${(100 - ((width - 200 - 340) / (height)) * 50)}%`,
                         overflow: 'scroll'
                       }}
                  >
                    <div style={{margin: 16}}>
                      {selectedComponent}
                    </div>
                  </div>
                </main>
              )}
            />
            <Drawer
              anchor="right"
              className={classes.summaryDrawer}
              variant="permanent"
              classes={{
                paper: classes.summaryDrawerPaper,
              }}
            >
              <div className={classes.toolbar} />
              <List>
                {['Clinical scenario', 'Summary of analysis'].map((text, index) => (
                  <div key={index} style={{marginTop: -4}}>
                    <AppBar position="static">
                      <Toolbar>
                        <Typography variant="subtitle1" color="inherit">{text}</Typography>
                      </Toolbar>
                    </AppBar>
                    <div className={classes.summaryDrawerContent}>
                      {index === 0 && <Typography>{caseScenario}</Typography>}
                      {index === 1 && <AnalysisSummary values={analysis} correctAnswer={correctAnswer} isMarking={isMarking} />}
                    </div>
                  </div>
                ))}
              </List>
            </Drawer>
            <PromptDialog
              open={isPromptDialogOpen}
              handleClose={this.handlePromptDialogClose}
              title={promptDialogTitle}
              body={promptDialogBody}
            />
          </div>
          {ecgImage && <Dialog
            fullScreen
            open={isECGEnlarged}
            onClose={this.handleClose}
            TransitionComponent={Transition}
          >
            <IconButton onClick={this.handleEnlargeECG} style={{position: 'absolute', top: 10, right: 10, zIndex: 1001}}>
              <Close />
            </IconButton>
            <PinchZoomPan maxScale={4} doubleTapBehavior="zoom" position="center">
              <img src={ecgImage} alt="" className={classes.ecgImage} />
            </PinchZoomPan>
          </Dialog>}
        </Dialog>
      )
    } else {
      this.handleClose();
      return <div />
    }
  }
}

const Instruction = (props: { className: string }) => {
  const {className} = props;
  // noinspection HtmlDeprecatedAttribute
  return <Typography variant="subtitle1" align="center" className={className}>
    Start the ECG analysis by analyzing the rate and rhythm first,<br />
    then proceeding to the detailed waveform analysis,<br />
    before coming to your conclusion<br />
  </Typography>;
};

// @ts-ignore
export default (withModuleContext(withECGContext(withAppContext(withStyles(styles)(Analysis))))) as unknown as React.ComponentClass<OwnProps>;
