import { connect } from 'react-redux';
import ConfirmEmail from './ConfirmEmail';
import {confirmEmailFn} from "../../redux/actions/auth";

const mapStateToProps = ({auth}, props) => ({
    ...props,
    auth
});
const mergeProps = (stateProps, { dispatch }, ownProps) => ({
    ...stateProps,
    ...ownProps,
    fnConfirmEmail(payload) {
        console.log('Payload ', payload);
        dispatch(confirmEmailFn(payload));
    },
});
export default connect(mapStateToProps, null, mergeProps)(ConfirmEmail);
