import {applyMiddleware, compose, createStore} from "redux"
import {persistReducer, persistStore} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from 'redux-saga'
import rootReducer from "./reducers"
import rootSaga from "./sagas";

// create the saga middleware
const sagaMiddleware = createSagaMiddleware();

const middleware = [
    sagaMiddleware,
];
const windowIfDefined = typeof window === 'undefined' ? null : window as any;
const composeEnhancers = windowIfDefined.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
    blacklist: ['mainReducer'],
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(...middleware),
    ),
);

export default store;
export const persistor = persistStore(store);

// persistor.purge().then();
sagaMiddleware.run(rootSaga);
