import * as axios from "axios";
import {delay} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {EcgActions} from "../../constants/Actions";
import Api from "../../constants/API";
import {convertObjectFieldsFromJSON} from "../../constants/Helpers";
import store from "../index";

function* getAnswer(action: any) {
  const {auth} = store.getState();
  const {payload: answerId} = action;
  const {authToken, isStudent} = auth;
  try {
    // @ts-ignore
    const {data} = isStudent ? yield call(axios, {
      ...Api.saveStudentAnswer,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.saveStudentAnswer.url}/${answerId}`,
      // @ts-ignore
    }) : yield call(axios, {
      ...Api.answer,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.answer.url}/${answerId}`,
    });
    yield put({type: isStudent ? EcgActions.GetStudentAnswerSuccess : EcgActions.GetAnswerSuccess, data: convertObjectFieldsFromJSON(data)});
  } catch (e) {
    yield put({type: isStudent ? EcgActions.GetStudentAnswerFailed : EcgActions.GetAnswerFailed, message: e.message});
  }
}

function* saveAnswer() {
  yield call(delay, 1000);
  const {auth, moduleReducer, ecg} = store.getState();
  const {selectedModule} = moduleReducer;
  const {answer} = ecg;
  const {authToken, isStudent} = auth;
  const {id: moduleId} = selectedModule;
  try {
    if (isStudent) {
      // throw new Error("Throwing error for now");
    }
    // const data = isStudent ? yield call(axios, {...Api.addStudentAnswer, data: {...answer, module_id, rate_per_min: String(answer.rate_per_min)}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}}) :
    // @ts-ignore
    const data = yield call(axios, {...Api.addAnswer, data: {...answer, module_id: moduleId, rate_per_min: String(answer.rate_per_min)}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: isStudent ? EcgActions.SaveStudentAnswerSuccess : EcgActions.SaveAnswerSuccess, data});
  } catch (e) {
    yield put({type: isStudent ? EcgActions.SaveStudentAnswerFailed : EcgActions.SaveAnswerFailed, message: e.message});
  }
}


export function* ecgSaga() {
  yield takeLatest(EcgActions.GetAnswer, getAnswer);
  yield takeLatest(EcgActions.SaveAnswer, saveAnswer);
}
