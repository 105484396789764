import UserListLayout from "./UserList.layout";
import {WithModuleContext, withModuleContext} from "../../context/ModuleContext";
import * as React from "react";
import {EditAnalysisFunction} from "../Main/Main";
import {WithAppContext, withAppContext} from "../../context/AppContext";

export type UserListProps = {
  handleEditAnalysis: EditAnalysisFunction
} & WithModuleContext & WithAppContext;
const HOC:any = withAppContext(withModuleContext(UserListLayout));

export default HOC;
