import {AnalysisInterface} from "../types/Analysis";

const initialSheet: AnalysisInterface = {
  moduleId: -1, // module_id
  answerId: -1, // answer_id
  ecgTitle: "", // ecgTitle
  caseScenario: "", // overview
  ecgImage: false, // ecgImage
  calibration: 0, // calibration
  step1: {
    ratePerMinute: "", // rate_per_min
    rate: null,
    regularity: null,
    irregularSelection: [],
    qrsWidth: null, // qrs_width
  },
  step2: {
    waveFormAnalysisMessage: "",
  },
  pWave: {
    pWave: null, // pwaves_present
    pWaveAxis: [], // pwaves
    pInRelationToQRS: [],
    pWaveMorphology: [],
  },
  prInterval: {
    prInterval: null, // pr_interval
    prIntervalMeasure: "", // pr_interval_ms
    prLength: null,
    prSegment: [], // pr_segment
  },
  qrsComplex: {
    qrsComplexWidthMeasurement: "", // qrs_width_ms
    qrsComplexWidth: [], // qrs_complex
    qrsComplexAxisMeasurement: "", // qrs_axes
    qrsComplexAxis: null,
    qrsComplexAxisCol1: [],
    qrsComplexAxisCol2: [],
  },
  stSegment: {
    stSegmentCol1: [], // st_segments
    stSegmentElevation: [],
    stSegmentElevationSubItems: [], // st_segments_elevated
    stSegmentDepression: [],
    stSegmentDepressionSubItems: [], // st_segments_depressed
    stSegmentRepolarisationAbnormality: [],
    stSegmentRepolarisationAbnormalitySubItems: [],
    stSegmentCol3: [],
  },
  tWave: {
    tWaveCol1: [], // t_waves
    tWaveCol2Part1: [],
    tWaveInversion: [],
    tWaveInversionSubItems: [],
    tWaveCol2Part2: [],
    tWaveAbnormality: [],
    tWaveAbnormalitySubItems: [],
    tWaveCol3: [],
  },
  qtInterval: {
    qtIntervalMeasurement: "", // qt_calc
    rrIntervalMeasurement: "", // rr_calc
    qtcIntervalMeasurement: "", // qtc_calc
    wideQRSAdjustedMeasurement: "",
    qtInterval: null,
  },
  step3: {
    optionalQuestion1: "",
    optionalQuestion2: "",
    optionalQuestion1Answers: ["", "", "", ""],
    optionalQuestion1CorrectAnswer: null,
    optionalQuestion2Answers: ["", "", "", ""],
    optionalQuestion2CorrectAnswer: null,
  },
};

export default initialSheet;
