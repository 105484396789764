import * as React from "react";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import {Theme} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import AvatarEditor from "react-avatar-editor";
import Slider from "@material-ui/lab/Slider/Slider";
import Switch from "@material-ui/core/Switch/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";

interface OwnProps {
  imageDataUrl: any;
  calibrateMode: boolean;
  calibrateImageScale: number;
  handleSubmit: (e: React.FormEvent) => void;

  toggleCalibrateMode(): void;

  handleChange(e: React.ChangeEvent<any>): void;


  handleSliderChange(e: React.ChangeEvent<{}>, value: number): void;
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      margin: theme.spacing(1),
    },
    form: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    input: {
      display: "none",
    },
    sliderGrid: {
      width: "50%",
    },
    sliderContainer: {
      padding: '22px 0',
    },
    calibrateGrid: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: 0,
      margin: 8,
    },
    gridImage: {
      position: "absolute",
      height: "100%",
      pointerEvents: "none",
    },
    calibrateInput: {
      width: 200,
    },
  });

const calibrateValues = {
  min: 1,
  max: 30,
  step: 0.1,
};

class StepTwoUploadECG extends React.Component<OwnProps & WithStyles<typeof styles>> {

  public editor: AvatarEditor | null = null;

  private formRef = React.createRef<HTMLFormElement>();
  private gridRef = React.createRef<HTMLDivElement>();


  public render() {
    const {classes, handleSubmit, handleChange, imageDataUrl, calibrateMode, toggleCalibrateMode, calibrateImageScale, handleSliderChange} = this.props;
    const formNode: HTMLFormElement | null = this.formRef.current;
    const gridNode: HTMLDivElement | null = this.gridRef.current;
    const gridHeight = gridNode && gridNode.offsetHeight ? gridNode.offsetHeight : 0;
    const imageMaxHeight = formNode && formNode.offsetHeight ? formNode.offsetHeight : 0;
    return (
      <React.Fragment>
        <form onSubmit={handleSubmit} className={classes.form} ref={this.formRef}>
          {!calibrateMode && <div ref={this.gridRef}>
            <Grid container={true} direction="row" alignItems="center" wrap="nowrap" spacing={8}>
              <Grid item={true}>
                <input
                  disabled={calibrateMode}
                  accept="image/jpeg"
                  className={classes.input}
                  id="contained-button-file"
                  multiple={true}
                  type="file"
                  name="ecgUpload"
                  // onChange={this.onImageChange}
                  onChange={handleChange}
                />
                <label htmlFor="contained-button-file">
                  <Button disabled={calibrateMode}
                          variant="contained" component="span" className={classes.button}>
                    Upload
                  </Button>
                </label>
              </Grid>
              <Grid item={true}>
                <Typography variant="caption">Support only *.jpg</Typography>
              </Grid>
              {false && imageDataUrl && <Grid item={true}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={calibrateMode}
                      onChange={toggleCalibrateMode}
                      color="primary"
                    />
                  }
                  label="Calibrate"
                />
              </Grid>}
            </Grid>
          </div>}

          {calibrateMode && imageDataUrl? <Grid
              container={true}
              direction="column"
              alignItems="center"
            >
              <Grid item={true} className={classes.calibrateGrid}>
                <AvatarEditor
                  ref={this.setEditorRef}
                  scale={calibrateImageScale||1}
                  border={[100, 0]}
                  image={imageDataUrl}

                />
                <img alt="" src={require("../../../../../assets/calibrateGrid.png")} className={classes.gridImage} />
              </Grid>
              <Grid item={true} className={classes.sliderGrid}>
                <Slider
                  value={calibrateImageScale || 1}
                  onChange={handleSliderChange}
                  min={calibrateValues.min}
                  max={calibrateValues.max}
                  classes={{container: classes.sliderContainer}}
                />
              </Grid>
              <TextField
                label="1000 ms Scale"
                variant="outlined"
                className={classes.calibrateInput}
                type="number"
                name="calibrateImageScale"
                value={calibrateImageScale || 1}
                onChange={handleChange}
                style={{MozAppearance: "textfield"}}
                inputProps={{
                  min: calibrateValues.min,
                  max: calibrateValues.max,
                  step: calibrateValues.step,
                }}
                InputProps={{
                  startAdornment: <InputAdornment position="start">1:</InputAdornment>,
                }}
              />
            </Grid> :
            <img alt="" id="target" src={imageDataUrl} style={{maxHeight: imageMaxHeight - (gridHeight + 1) - 60, maxWidth: "100%", objectFit: "contain"}} />}

        </form>
      </React.Fragment>
    );
  }

  private setEditorRef = (editor: AvatarEditor) => this.editor = editor;
}

export default withStyles(styles)(StepTwoUploadECG) as React.ComponentClass<OwnProps>;
