import Card from '@material-ui/core/Card/Card';
import CardHeader from '@material-ui/core/CardHeader/CardHeader';
import CardContent from '@material-ui/core/CardContent/CardContent';
import Grid from '@material-ui/core/Grid/Grid';
import CardActions from '@material-ui/core/CardActions/CardActions';
import Button from '@material-ui/core/Button/Button';
import React, {Fragment, useState} from 'react';
import ReactPlayer from 'react-player'
import { uri } from '../../constants/API';
import Loader from "../Loader";


const LectureVideo = ({title, id, theme, coverImage, deleteVideo, isStudent}) => {
    const [showVideo, setShowVideo] = useState(false);

    const isSafari = () => {
        console.log(navigator.userAgent);
        console.log(navigator.userAgent.indexOf("Safari"));
        return navigator.userAgent.indexOf("Safari") !== -1 &&
            navigator.userAgent.indexOf("Chrome") === -1;
    }
    return (
    <Grid style={{transition: 'width 0.7s',
        width: 'auto'}} item={true}>
        <Card style={{backgroundColor: theme.palette.primary.light,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',}}>
            <CardHeader style={{fontSize: '14px',
                fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
                fontWeight: 500}}
                title={title}
            />
            <CardContent style={{display: "flex", justifyContent: "center", maxWidth: "450px", maxHeight: "450px", height: 'auto'}}>
                { !showVideo && <img style={{maxWidth: '400px', height: 'auto'}} src={coverImage} /> }
                { showVideo && !isSafari() && <ReactPlayer url={`${uri}/lecturevideo/${id}`} width="100%" playing controls={true} /> }
                { showVideo && isSafari() && (
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        height: '100%',}}>
                    <span>To view this lecture within the browser, please use Google Chrome. </span>
                        <span>Alternatively, if you prefer using Safari, </span>
                    <a style={{display: "table-cell"}} href={`${uri}/lecturevideo/${id}`} download target="_blank">
                        <span> Right click here to download the file,</span>
                </a><span> to view this lecture in the media player of your choice.</span></div>)}
            </CardContent>
            <CardActions>
                <Grid container={true} direction='row' justify='space-evenly' style={{maxWidth: "400px", paddingBottom: 8}}>
                   <Button
                        size='small'
                        color='primary'
                        aria-haspopup='true'
                        onClick={() => setShowVideo(!showVideo)}
                    >
                       {!showVideo ? 'Watch Lecture' : 'Close Lecture'}
                    </Button>
                    {!isStudent &&
                    <Button
                        size='small'
                        color='primary'
                        aria-haspopup='true'
                        onClick={() => deleteVideo(id)}
                    >Delete Lecture</Button>
                    }
                </Grid>
            </CardActions>
        </Card>
    </Grid>
    );
}

export default LectureVideo;
