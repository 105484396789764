import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide/Slide";
import * as React from "react";
import {TransitionProps} from "@material-ui/core/transitions";

const Transition = React.forwardRef<unknown, TransitionProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
// TODO: fix close type
// TODO: fix confirm type
// TODO: fix module type
interface Props {
  close: any;
  confirm: any;
  module: any;
  showRemoveDialog: boolean;
}

const RemoveDialog = (props:Props) => {
  const {close, confirm, module, showRemoveDialog} = props;

  const handleClose = () => {
    close();
  };

  const handleConfirm = () => {
    confirm(module);
    close();
  };

  return (
      <React.Fragment>
        <Dialog
            TransitionComponent={Transition}
            open={showRemoveDialog}
            onClose={handleClose}
        >
          <DialogTitle id="alert-dialog-title">Are you sure you want to remove {module.code}?</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are about to remove <b>{module.code} - {module.name}.</b> This cannot be undone, are you sure you want to proceed?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus={true}>
              Remove
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
  );
};

export default RemoveDialog;
