export type Rate = null | "Normal rate" | "Bradycardia" | "Tachycardia";
export type Regularity = null | "Regular" | "Irregular";
export type IrregularSelection = null | "no pattern" | "group beating" | "pauses" | "premature beat" | "respiratory variation";
export type QRSWidth = null | "Narrow complex" | "Wide complex" | "Various widths";
export type PWave = null | "Present" | "Absent / unsure if present";
export type PWaveAxis = null | "Upright in II / inverted in aVR" | "Inverted in II / upright in aVR" | "Inverted in I";
export type PInRelationToQRS = null | "P wave before every QRS" | "QRS after every P wave" | "P wave after QRS" | "More P waves than QRS" | "More QRS than P waves" | "P wave superimposed on QRS" | "P wave superimposed on T wave" | "Premature atrial complexes" | "AV dissociation"
export type PWaveMorphology = null | "Normal morphology" | "Tall initial part of P wave in V1" | "Increased negativity of P wave in V1" | "Widened / bifid in II" | "Increased amplitude" | "Decreased amplitude" | "Flutter waves" | "Fibrillatory waves" | "Variable P wave morphology";
export type PRInterval = null | "Constant" | "Variable" | "Irrelevant";
export type PRLength = null | "Normal" | "Short" | "Long";
export type PRSegment = null | "PR segment elevated in aVR" | "PR segment depressed in II";
export type QRSComplexWidth = null | "Normal morphology" | "Complete RBBB" | "Incomplete RBBB" | "Complete LBBB" | "Incomplete LBBB" | "Atypical / bizarre wide QRS" | "Delta waves" | "J waves" | "Epsilon waves" | "Variable QRS morphology";
export type QRSComplexAxis = null | "Normal axis" | "Right axis deviation" | "Left axis deviation" | "Extreme axis deviation";
export type QRSComplexAxisCol1 = null | "Normal R wave progression" | "Poor R wave progression" | "Dominant R in V1" | "Increased left ventricular voltage" | "Low QRS amplitude";
export type QRSComplexAxisCol2 = null | "Pathological Q in inferior leads" | "Pathological Q in anterior leads" | "QS complexes in lateral leads" | "Premature narrow complex" | "Premature wide complex" | "Narrow escape beat / rhythm" | "Wide escape beat / rhythm" | "Capture beat" | "Fusion beat" | "Electrical alternans";
export type STSegmentCol1 = null | "Normal ST segments";
export type STSegmentElevation = null | "ST elevation";
export type STSegmentElevationSubItems = null | "I" | "aVR" | "V1" | "V4" | "II" | "aVL" | "V2" | "V5" | "III" | "aVF" | "V3" | "V6";
export type STSegmentDepression = null | "ST depression";
export type STSegmentDepressionSubItems = null | "I" | "aVR" | "V1" | "V4" | "II" | "aVL" | "V2" | "V5" | "III" | "aVF" | "V3" | "V6";
export type STSegmentRepolarisationAbnormality = null | "Repolarisation abnormality";
export type STSegmentRepolarisationAbnormalitySubItems = null | "secondary to wide QRS" | "secondary to RVH / LVH";
export type STSegmentCol3 = null | "Early depolarisation pattern" | "Brugada pattern";
export type TWaveCol1 = null| "Normal T waves";
export type TWaveCol2Part1 = null | "Peaked T waves" | "Flattened T waves";
export type TWaveInversion = null  | "T wave inversion";
export type TWaveInversionSubItems = null | "I" | "aVR" | "V1" | "V4" | "II" | "aVL" | "V2" | "V5" | "III" | "aVF" | "V3" | "V6";
export type TWaveCol2Part2 = null | "Biphasic T waves" | "T wave alternans";
export type TWaveAbnormality = null |"T wave abnormality";
export type TWaveAbnormalitySubItems = null  |"secondary to wide QRS" |"secondary to RVH / LVH";
export type TWaveCol3 = null | "U waves";
export type QTInterval = null | "Normal" | "Short" | "Prolonged";


const RateTypedOptions: Rate[] = ["Normal rate", "Bradycardia", "Tachycardia"];
const RegularityTypedOptions: Regularity[] = ["Regular", "Irregular"];
const IrregularSelectionTypedOptions: IrregularSelection[] = ["no pattern","group beating","pauses","premature beat","respiratory variation"];
const QRSWidthTypedOptions: QRSWidth[] = ["Narrow complex", "Wide complex", "Various widths"];
const PWaveTypedOptions: PWave[] = ["Present", "Absent / unsure if present"];
const PWaveAxisTypedOptions: PWaveAxis[] = ["Upright in II / inverted in aVR", "Inverted in II / upright in aVR", "Inverted in I"];
const PInRelationToQRSTypedOptions: PInRelationToQRS[] = ["P wave before every QRS", "QRS after every P wave", "P wave after QRS", "More P waves than QRS", "More QRS than P waves", "P wave superimposed on QRS","P wave superimposed on T wave", "Premature atrial complexes", "AV dissociation"];
const PWaveMorphologyTypedOptions: PWaveMorphology[] = ["Normal morphology", "Tall initial part of P wave in V1","Increased negativity of P wave in V1", "Widened / bifid in II", "Increased amplitude", "Decreased amplitude", "Flutter waves", "Fibrillatory waves", "Variable P wave morphology"];
const PRIntervalTypedOptions: PRInterval[] = ["Constant", "Variable", "Irrelevant"];
const PRLengthTypedOptions: PRLength[] = ["Normal", "Short", "Long"];
const PRSegmentTypedOptions: PRSegment[] = ["PR segment elevated in aVR", "PR segment depressed in II"];
const QRSComplexWidthTypedOptions: QRSComplexWidth[] = ["Normal morphology", "Complete RBBB", "Incomplete RBBB", "Complete LBBB", "Incomplete LBBB", "Atypical / bizarre wide QRS", "Delta waves", "J waves", "Epsilon waves", "Variable QRS morphology"];
const QRSComplexAxisTypedOptions: QRSComplexAxis[] = ["Normal axis", "Right axis deviation", "Left axis deviation", "Extreme axis deviation"];
const QRSComplexAxisCol1TypedOptions: QRSComplexAxisCol1[] = ["Normal R wave progression", "Poor R wave progression", "Dominant R in V1", "Increased left ventricular voltage", "Low QRS amplitude"];
const QRSComplexAxisCol2TypedOptions: QRSComplexAxisCol2[] = ["Pathological Q in inferior leads", "Pathological Q in anterior leads", "QS complexes in lateral leads", "Premature narrow complex", "Premature wide complex", "Narrow escape beat / rhythm", "Wide escape beat / rhythm", "Capture beat", "Fusion beat", "Electrical alternans"];
const STSegmentCol1TypedOptions: STSegmentCol1[] = ["Normal ST segments"];
const STSegmentElevationTypedOptions: STSegmentElevation[] = ["ST elevation"];
const STSegmentElevationSubItemsTypedOptions: STSegmentElevationSubItems[] = ["I", "aVR", "V1", "V4", "II", "aVL", "V2", "V5", "III", "aVF", "V3", "V6"];
const STSegmentDepressionTypedOptions: STSegmentDepression[] = ["ST depression"];
const STSegmentDepressionSubItemsTypedOptions: STSegmentDepressionSubItems[] = ["I", "aVR", "V1", "V4", "II", "aVL", "V2", "V5", "III", "aVF", "V3", "V6"];
const STSegmentRepolarisationAbnormalityTypedOptions: STSegmentRepolarisationAbnormality[] = ["Repolarisation abnormality"];
const STSegmentRepolarisationAbnormalitySubTypeTypedOptions: STSegmentRepolarisationAbnormalitySubItems[] = ["secondary to wide QRS", "secondary to RVH / LVH"];
const STSegmentCol3TypedOptions: STSegmentCol3[] = ["Early depolarisation pattern", "Brugada pattern"];
const TWaveCol1TypedOptions: TWaveCol1[] = ["Normal T waves"];
const TWaveCol2Part1TypedOptions: TWaveCol2Part1[] = ["Peaked T waves","Flattened T waves"];
const TWaveInversionTypedOptions: TWaveInversion[] = ["T wave inversion"];
const TWaveInversionSubItemsTypedOptions: TWaveInversionSubItems[] = ["I", "aVR", "V1", "V4", "II", "aVL", "V2", "V5", "III", "aVF", "V3", "V6"];
const TWaveCol2Part2TypedOptions: TWaveCol2Part2[] = ["Biphasic T waves","T wave alternans"];
const TWaveAbnormalityTypedOptions: TWaveAbnormality[] = ["T wave abnormality"];
const TWaveAbnormalitySubItemsTypedOptions: TWaveAbnormalitySubItems[] = ["secondary to wide QRS","secondary to RVH / LVH"];
const TWaveCol3TypedOptions: TWaveCol3[] = ["U waves"];
const QTIntervalTypedOptions: QTInterval[] = ["Normal","Short","Prolonged"];

export const RateOptions: string[] = RateTypedOptions as string[];
export const RegularityOptions: string[] = RegularityTypedOptions as string[];
export const IrregularSelectionOptions: string[] = IrregularSelectionTypedOptions as string[];
export const QRSWidthOptions: string[] = QRSWidthTypedOptions as string[];
export const PWaveOptions: string[] = PWaveTypedOptions as string[];
export const PWaveAxisOptions: string[] = PWaveAxisTypedOptions as string[];
export const PInRelationToQRSOptions: string[] = PInRelationToQRSTypedOptions as string[];
export const PWaveMorphologyOptions: string[] = PWaveMorphologyTypedOptions as string[];
export const PRIntervalOptions: string[] = PRIntervalTypedOptions as string[];
export const PRLengthOptions: string[] = PRLengthTypedOptions as string[];
export const PRSegmentOptions: string[] = PRSegmentTypedOptions as string[];
export const QRSComplexWidthOptions: string[] = QRSComplexWidthTypedOptions as string[];
export const QRSComplexAxisOptions: string[] = QRSComplexAxisTypedOptions as string[];
export const QRSComplexAxisCol1Options: string[] = QRSComplexAxisCol1TypedOptions as string[];
export const QRSComplexAxisCol2Options: string[] = QRSComplexAxisCol2TypedOptions as string[];
export const STSegmentCol1Options: string[] = STSegmentCol1TypedOptions as string[];
export const STSegmentElevationOptions: string[] = STSegmentElevationTypedOptions as string[];
export const STSegmentElevationSubItemsOptions: string[] = STSegmentElevationSubItemsTypedOptions as string[];
export const STSegmentDepressionOptions: string[] = STSegmentDepressionTypedOptions as string[];
export const STSegmentDepressionSubItemsOptions: string[] = STSegmentDepressionSubItemsTypedOptions as string[];
export const STSegmentRepolarisationAbnormalityOptions: string[] = STSegmentRepolarisationAbnormalityTypedOptions as string[];
export const STSegmentRepolarisationAbnormalitySubItemsOptions: string[] = STSegmentRepolarisationAbnormalitySubTypeTypedOptions as string[];
export const STSegmentCol3Options: string[] = STSegmentCol3TypedOptions as string[];
export const TWaveCol1Options: string[] = TWaveCol1TypedOptions as string[];
export const TWaveCol2Part1Options: string[] = TWaveCol2Part1TypedOptions as string[];
export const TWaveInversionOptions: string[] = TWaveInversionTypedOptions as string[];
export const TWaveInversionSubItemsOptions: string[] = TWaveInversionSubItemsTypedOptions as string[];
export const TWaveCol2Part2Options: string[] = TWaveCol2Part2TypedOptions as string[];
export const TWaveAbnormalityOptions: string[] = TWaveAbnormalityTypedOptions as string[];
export const TWaveAbnormalitySubItemsOptions: string[] = TWaveAbnormalitySubItemsTypedOptions as string[];
export const TWaveCol3Options: string[] = TWaveCol3TypedOptions as string[];
export const QTIntervalOptions: string[] = QTIntervalTypedOptions as string[];

export interface AnalysisStep1 {
  ratePerMinute: string | undefined;
  rate?: Rate;
  regularity?: Regularity;
  irregularSelection?: IrregularSelection[];
  qrsWidth?: QRSWidth;
}
export interface AnalysisStep2 {
  waveFormAnalysisMessage: string | undefined;
}

export interface AnalysisPWave {
  pWave: PWave;
  pWaveAxis: PWaveAxis[];
  pInRelationToQRS: PInRelationToQRS[];
  pWaveMorphology: PWaveMorphology[];
}

export interface AnalysisPRInterval {
  prInterval: PRInterval;
  prIntervalMeasure: string | undefined;
  prLength: PRLength;
  prSegment: PRSegment[];
}

export interface AnalysisQRSComplex {
  qrsComplexWidthMeasurement: string | undefined;
  qrsComplexWidth: QRSComplexWidth[];
  qrsComplexAxisMeasurement: string | undefined;
  qrsComplexAxis: QRSComplexAxis;
  qrsComplexAxisCol1: QRSComplexAxisCol1[];
  qrsComplexAxisCol2: QRSComplexAxisCol2[];
}

export interface AnalysisSTSegment {
  stSegmentCol1: STSegmentCol1[];
  stSegmentElevation: STSegmentElevation[];
  stSegmentElevationSubItems: STSegmentElevationSubItems[];
  stSegmentDepression: STSegmentDepression[];
  stSegmentDepressionSubItems: STSegmentDepressionSubItems[];
  stSegmentRepolarisationAbnormality: STSegmentRepolarisationAbnormality[];
  stSegmentRepolarisationAbnormalitySubItems: STSegmentRepolarisationAbnormalitySubItems[];
  stSegmentCol3: STSegmentCol3[];
}
export interface AnalysisTWave {
  tWaveCol1: TWaveCol1[];
  tWaveCol2Part1: TWaveCol2Part1[];
  tWaveInversion: TWaveInversion[];
  tWaveInversionSubItems: TWaveInversionSubItems[];
  tWaveCol2Part2: TWaveCol2Part2[];
  tWaveAbnormality: TWaveAbnormality[];
  tWaveAbnormalitySubItems: TWaveAbnormalitySubItems[];
  tWaveCol3: TWaveCol3[];
}
export interface AnalysisQTInterval {
  qtIntervalMeasurement: string | undefined;
  rrIntervalMeasurement: string | undefined;
  qtcIntervalMeasurement: string | undefined;
  wideQRSAdjustedMeasurement: string | undefined;
  qtInterval: QTInterval;
}

export interface AnalysisStep3 {
  optionalQuestion1: string | undefined;
  optionalQuestion2: string | undefined;
  optionalQuestion1Answers: string[];
  optionalQuestion1CorrectAnswer: null | number;
  optionalQuestion2Answers: string[];
  optionalQuestion2CorrectAnswer: null | number;
}

export interface AnalysisInterface {
  moduleId: number; // module_id
  answerId: number|null; // answer_id
  caseScenario: string; // overview
  ecgTitle: string; // ecgTitle
  calibration: number; // calibration
  ecgImage: boolean; // ecgImage
  takeHome?: boolean; // ecgImage
  step1: AnalysisStep1;
  step2: AnalysisStep2;
  pWave: AnalysisPWave;
  prInterval: AnalysisPRInterval;
  qrsComplex: AnalysisQRSComplex;
  stSegment: AnalysisSTSegment;
  tWave: AnalysisTWave;
  qtInterval: AnalysisQTInterval;
  step3: AnalysisStep3;
}

export interface StudentAnalysisInterface extends AnalysisInterface {
  studentAnswerId: number;
  completed: boolean;
}

export interface GetStudentAnswerResponse {
  analysis: {
    step1: AnalysisStep1;
    step2: AnalysisStep2;
    pWave: AnalysisPWave;
    prInterval: AnalysisPRInterval;
    qrsComplex: AnalysisQRSComplex;
    stSegment: AnalysisSTSegment;
    tWave: AnalysisTWave;
    qtInterval: AnalysisQTInterval;
    step3: AnalysisStep3;
  };
  answerId: number;
  completed: boolean;
  lastModified: string;
  moduleId: number;
  studentAnswerId: number;
  static: {
    ecgName: string;
    ecgImage: true;
    takeHome: true;
    overview: string;
    calibration: number
  }
}
