import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";

interface OwnProps {
  value: string | null;
  name: string;
  options: string[];
  onChange(name: string, value: string): void;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
    });

type Props = OwnProps & WithStyles<typeof styles>;

class AnalysisRadioGroup extends React.Component<Props> {
  public render() {
    const {classes, value,options} = this.props;
    return (
        <div className={classes.separator}>
          <RadioGroup
              name="rate"
              value={value === null ? "" : value}
              onChange={this.handleChange}
          >
            {options.map((option:string,index)=> <FormControlLabel key={index} value={option} control={<Radio color="primary" />} label={option} />)}
          </RadioGroup>
        </div>
    );
  }

  private handleChange = (e: React.ChangeEvent<any>) => {
    const {onChange, name} = this.props;
    onChange(name, e.target.value);
  }
}

// @ts-ignore
export default withStyles(styles)(AnalysisRadioGroup) as React.ComponentClass<OwnProps>;
