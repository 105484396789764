import * as crypto from "crypto";
import * as moment from "moment";
import {call, put, takeLatest} from "redux-saga/effects";
import {AuthActions, LectureActions} from "../../constants/Actions";
import Api from "../../constants/API";
import {AuthLoginFn, AuthLoginFnPayload} from "../actions/auth";
import {makeAxiosCall} from "./index";
import store from "../index";
import axios from "axios";

const generateApiKey = (payload: AuthLoginFnPayload) => {
  // @ts-ignore
  const newD = moment().format("YYYY-MM-DD HH:mm:ss");
  return {
    apiKey: crypto.createHash("sha512").update(String(`${newD}.${crypto.createHash("sha512").update(String(JSON.stringify(payload))).digest("hex")}.${Api.secret}`)).digest("hex"),
    newDate: newD,
  }
};

function* authLogin(action: AuthLoginFn) {
  const {payload} = action;
  const generatedApiKey = generateApiKey(payload);
  try {
    const {data} = yield call(makeAxiosCall, {
      ...Api.login,
      data: {...payload},
      headers: {
        ...Api.login.headers,
        "API-KEY": generatedApiKey.apiKey,
        "REQUEST-TIME": generatedApiKey.newDate,
      },
    });
    yield put({type: AuthActions.LoginSuccess, data});
  } catch (e) {
    yield put({type: AuthActions.LoginFailed, message: e.message});
  }
}

function* confirmEmail(action:any) {
  const {auth} = store.getState();
  const {authToken} = auth;
  const {payload} = action;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {
      ...Api.confirmEmail,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.confirmEmail.url}/${payload}`,
    });
    yield put({type: AuthActions.ConfirmEmailSuccess, confirmed: true});
  } catch (e) {
    console.log(e);
    yield put({type: AuthActions.ConfirmEmailFailed, confirmed: false});
  }
}

export function* authSaga() {
  yield takeLatest(AuthActions.Login, authLogin);
  yield takeLatest(AuthActions.ConfirmEmail, confirmEmail);
}
