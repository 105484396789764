import {Theme} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import * as React from "react";
import Loader from "../../../../components/Loader";
import RemoveSheetDialog from "../RemoveSheetDialog";
import AddECG from "./AddECG";
import ECGCard from "./ECGCard";
import {WithAppContext, withAppContext} from "../../../../context/AppContext";
import {WithModuleContext, withModuleContext} from "../../../../context/ModuleContext";
import {ECGSheet, StudentResult} from "../../../../types/module";
import {EditAnalysisFunction} from "../../Main";

const styles = (theme: Theme) =>
  createStyles({
    layout: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
  });

interface State {
  modifyGroup: any;
  rowHovered: number;
  addEditSheet: boolean;
  anchorEl: any;
  removeSheet: ECGSheet | null;
  showRemoveDialog: boolean;
  sheetToEdit: ECGSheet | null;
  initialStep: number;
  calibrateMode: boolean;
}

interface OwnProps {
  editAnalysisFunction: EditAnalysisFunction;
  studentResults: StudentResult[];
}

interface Props extends OwnProps, WithStyles<typeof styles>, WithAppContext, WithModuleContext {
}

class ECGs extends React.Component<Props, State> {
  public state: State = {
    addEditSheet: false,
    anchorEl: null,
    initialStep: 0,
    modifyGroup: [],
    removeSheet: null,
    rowHovered: -1,
    sheetToEdit: null,
    showRemoveDialog: false,
    calibrateMode: false,
  };

  public componentDidMount() {
    const {moduleContext: {getECGsFn, selectedModule}} = this.props;
    selectedModule !== null && getECGsFn(selectedModule.id);
  }

  public render() {
    const {moduleContext: {selectedModule, ecgThumbs}, appContext: {loading, isStudent}, classes, editAnalysisFunction, studentResults} = this.props;
    const ecgSheets = selectedModule !== null ? selectedModule.ecgSheets : [];
    const moduleId = selectedModule !== null ? selectedModule.id: null;
    const {addEditSheet, showRemoveDialog, removeSheet, sheetToEdit, initialStep, calibrateMode} = this.state;
    const filteredStudentResults = (studentResults.filter(v => v.moduleId === moduleId));
    return (
      <main>
        <div className={classes.layout}>
          <Grid container={true} spacing={8}>
            {ecgSheets && ecgSheets.map((v: ECGSheet, i: number) =>
              <ECGCard
                image={ecgThumbs.find(image => image.id === v.id)}
                sheet={v}
                key={i}
                handleAddEditSheet={this.handleAddEditSheet}
                removeFn={this.removeSheet}
                editAnalysisFunction={editAnalysisFunction}
                studentResults={filteredStudentResults.filter(result=>result.answerId === v.id)}
              />,
            )}
            {!isStudent && <Grid item={true} sm={6} md={4} lg={3}>
              <Button fullWidth={true} onClick={this.handleAddEditSheet.bind(this, true)}>{loading ? <Loader height={20} /> : "Add ECG"}</Button>
            </Grid>}
          </Grid>
        </div>
        {addEditSheet && <AddECG close={this.closeAddEditSheet} sheet={sheetToEdit} initialStep={initialStep} calibrateMode={calibrateMode} />}
        <RemoveSheetDialog sheet={removeSheet} {...{showRemoveDialog}} close={this.closeRemoveDialog} confirm={this.confirmRemove} />
      </main>
    );
  }

  private closeAddEditSheet = () => {
    this.setState({addEditSheet: false});
  };

  private removeSheet = (module: any) => {
    this.setState({removeSheet: module, showRemoveDialog: true})
  };
  private closeRemoveDialog = () => {
    this.setState({removeSheet: null, showRemoveDialog: false})
  };
  private confirmRemove = () => {
    const {removeSheet} = this.state;
    const {moduleContext: {removeECGFn}} = this.props;
    if (removeSheet !== null) {
      removeECGFn(removeSheet.id);
    }
  };

  private handleAddEditSheet = (val: any, sheet: any = {}, initialStep: number = 0, calibrateMode?: boolean) => {
    this.setState({addEditSheet: val, sheetToEdit: sheet, initialStep, calibrateMode: typeof calibrateMode === "boolean" && calibrateMode});
  };
}


// @ts-ignore
export default withAppContext(withModuleContext(withStyles(styles)(ECGs))) as unknown as React.ComponentClass<OwnProps>;
