import * as React from "react";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Loader from "../../components/Loader";
import Typography from "@material-ui/core/Typography/Typography";
import AppBar from "@material-ui/core/AppBar/AppBar";
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import {WithAppContext, withAppContext} from "../../context/AppContext";
import {withModuleContext, WithModuleContext} from "../../context/ModuleContext";
import {AnalysesList} from "../AnalysesList";
import Modules from "./Modules/Modules";
import Analysis from "../Analysis";
import {StudentResult} from "../../types/module";
import {UserList} from "../UserList";
import UserDialog from "../UserList/UserDialog";
import {User} from "../../types/app";
import { onMobile } from "../../constants/Helpers";
// @ts-ignore
import AppReference from "../../assets/appreference.pdf";
import About from "../../components/About/About";
import Lectures from "../../components/Lectures/index";
const version = require("../../../package.json").version;

export type EditAnalysisFunction = (id: number, studentAnswerId: number | null, isMarking?: boolean) => () => void;

enum TabValues {
  ANSWERS,
  MODULES,
  USERS,
  ACCOUNT,
  LOGOUT,
  REFERENCE,
  HELP,
  ABOUT,
  LECTURES
}

type State = {
  activeTab: TabValues;
  showAnalysisDialog: number | null;
  studentAnswerId: null | number;
  studentResults: StudentResult[];
  isMarking: boolean;
  showMyAccount: boolean;
}

const styles = (theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    icon: {
      marginRight: theme.spacing(2),
    },
    tabs: {
      minHeight: 64,
    },
    title: {
      flexGrow: 1,
      marginLeft: theme.spacing(2),
    },
  });

interface Props extends WithStyles<typeof styles>, WithAppContext, WithModuleContext {
}

class Main extends React.Component<Props, State> {
  public state: State = {
    activeTab: TabValues.MODULES,
    // activeTab: TabValues.USERS,
    showAnalysisDialog: null,
    studentAnswerId: null,
    studentResults: [],
    isMarking: false,
    showMyAccount: false,
  };

  componentWillMount(): void {
    const {
      appContext: {
        isStudent
      },
      moduleContext: {getModulesFn, getStudentResults}
    } = this.props;
    getModulesFn().then(async modules => {
      if (isStudent) {
        const studentResults: StudentResult[] = [];
        await modules.forEach(module => {
          // console.log(module);
          getStudentResults(module.id).then(results => {
            results.forEach(result => {
              studentResults.push(result);
            });
            this.setState({studentResults});
          })
        });
      }
    });
  }

  public render() {
    const {
      classes, appContext: {
        isStudent,
        email,
        firstname,
        surname,
        id,
        userType,
        title
      }
    } = this.props;

    // console.log(this.props);
    const {
      activeTab,
      showAnalysisDialog,
      studentAnswerId,
      studentResults,
      isMarking,
      showMyAccount
    } = this.state;
    return (
      <React.Fragment>
        {onMobile() && <div>
          <Typography variant="h6" color="inherit" noWrap={true} className={classes.title}>
            ECG ONLINE
          </Typography>
          ECG is only suitable for larger screens
        </div>}
        {!onMobile() && <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <Loader height={40} width={50} loops={1} />
            <Typography variant="h6" color="inherit" noWrap={true} className={classes.title}>
              ECG ONLINE
            </Typography>
            <Tabs value={activeTab} onChange={this.handleTabChange} classes={{flexContainer: classes.tabs}}>
              <Tab value={TabValues.LECTURES} label={isStudent?'View Lectures':'Upload Lectures'} />
              <Tab value={TabValues.MODULES} label={isStudent?'Analyse ECGs':'Upload ECGs'} />
              <Tab value={TabValues.ANSWERS} label={isStudent?'Previous ECG analyses':'View ECG Analyses'} />
              <Tab value={TabValues.REFERENCE} label="ECG Reference" />
              <Tab value={TabValues.HELP} label="Help" />
              <Tab value={TabValues.ABOUT} label="About" />
              {!isStudent && <Tab value={TabValues.USERS} label="Users" />}
              <Tab value={TabValues.ACCOUNT} label="My Account" />
              <Tab value={TabValues.LOGOUT} label="Logout" />
            </Tabs>
          </Toolbar>
        </AppBar>}
        {!onMobile() && <div style={{position: "relative"}}>
          {activeTab === TabValues.ANSWERS && <AnalysesList handleEditAnalysis={this.handleEditAnalysis} />}
          {activeTab === TabValues.USERS && <UserList handleEditAnalysis={this.handleEditAnalysis} />}
          {activeTab === TabValues.MODULES && <Modules studentResults={studentResults} handleEditAnalysis={this.handleEditAnalysis} />}
          {activeTab === TabValues.ABOUT && <About />}
          {activeTab === TabValues.LECTURES && <Lectures />}
          <div style={{position: "absolute", bottom: 10, right: 10}}><Typography variant="caption">V {version}</Typography></div>
        </div>}
        {!onMobile() && <Analysis open={showAnalysisDialog} handleEditAnalysis={this.handleEditAnalysis} studentAnswerId={studentAnswerId} onClose={this.closeAnalysisDialog} isMarking={isMarking} createdNewAnswer={this.createdNewAnswer} />}
        {!onMobile() && showMyAccount &&
        <UserDialog
          open={showMyAccount}
          onClose={this.closeMyAccount}
          action="myAccount"
          user={{
            email,
            firstname,
            surname,
            id,
            userType,
            title
          } as User}
          handleSave={this.handleMyAccountSave}
        />
        }
      </React.Fragment>
    )
  }

  closeMyAccount = () => {
    this.setState({showMyAccount: false});
  };

  handleMyAccountSave = (user: User) => {
    const {
      appContext: {
        setUser
      }
    } = this.props;
    this.setState({showMyAccount: false});
    const {
      moduleContext: {
        postUsersFn
      }
    } = this.props;
    postUsersFn(user).then(() => {
      setUser(user);
      // console.log(res);
    });
  };

  handleTabChange = ({}, value: TabValues) => {
    const {appContext: {logoutFn}, moduleContext: {selectModule}} = this.props;
    switch (value) {
      case TabValues.LOGOUT:
        logoutFn();
        break;
      case TabValues.ACCOUNT:
        this.setState({showMyAccount: true});
        break;
      case TabValues.USERS:
      case TabValues.MODULES:
      case TabValues.ANSWERS:
      case TabValues.ABOUT:
      case TabValues.LECTURES:
        selectModule(null).then();
        this.setState({activeTab: value});
        break;
      case TabValues.REFERENCE:
        window.open(
            require("../../assets/appreference.pdf"),
            '_blank'
        );
        break;
      case TabValues.HELP:
        window.open(
            require("../../assets/help.pdf"),
            '_blank'
        );
        break;
    }
  };

  createdNewAnswer = (answerId: number) => {
    this.setState({studentAnswerId:answerId})
  };

  closeAnalysisDialog = (gotoMarking: boolean = false,answerId: number|null = null) => {
    const {
      appContext: {
        isStudent
      },
      moduleContext: {getModulesFn, getStudentResults}
    } = this.props;
    const {showAnalysisDialog,studentAnswerId} = this.state;
    // noinspection UnnecessaryLocalVariableJS
    const tmpShowAnalysisDialog = showAnalysisDialog;
    if (gotoMarking) {
      this.setState({showAnalysisDialog: null,studentAnswerId: answerId || studentAnswerId});
      this.setState({showAnalysisDialog: tmpShowAnalysisDialog, isMarking: true});
    } else {
      this.setState({showAnalysisDialog: null, studentAnswerId: null});
      getModulesFn().then(async modules => {
        if (isStudent) {
          const studentResults: StudentResult[] = [];
          await modules.forEach(module => {
            // console.log(module);
            getStudentResults(module.id).then(results => {
              results.forEach(result => {
                studentResults.push(result);
              });
              this.setState({studentResults});
            })
          });
        }
      });
    }
  };

  handleEditAnalysis: EditAnalysisFunction = (id: number, studentAnswerId: number | null, isMarking: boolean = false) => () => {
    // Sheet ID
    this.setState({showAnalysisDialog: id, studentAnswerId, isMarking});
  };
}

// @ts-ignore
export default withStyles(styles)(withAppContext(withModuleContext(Main))) as React.ComponentClass<{}>;
