interface AnalysisSteps {
  primary: string;
  secondary: string;
  subStep: string[];
}

export const analysisSteps: AnalysisSteps[] = [
  {
    primary: "Step 1",
    secondary: "Rhythm analysis",
    subStep: [],
  },
  {
    primary: "Step 2",
    secondary: "Waveform analysis",
    subStep: [
      "P wave",
      "PR interval",
      "QRS complex",
      "ST segment",
      "T wave",
      "QT interval",
    ],
  },
  {
    primary: "Step 3",
    secondary: "Conclusion",
    subStep: [],
  },
];
