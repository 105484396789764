import {AuthActions} from "../../constants/Actions";
import {validatorIsEmail, validatorIsPassword} from "../../constants/Helpers";

const isStudent = (val: string) => val.trim() === "Student";

const validator = {
  email: validatorIsEmail,
  password: validatorIsPassword,
};

export interface AuthState {
  authToken: string | null;
  email: string;
  isStudent: boolean;
  loading: boolean;
  password: string;
  valid: {
    email: boolean;
    password: boolean;
  },
  confirmed: boolean | null
}

const initialState: AuthState = {
  authToken: null,
  email: "jason@uct.ac.za",
  isStudent: true,
  loading: false,
  password: "password",
  valid: {
    email: true,
    password: true,
  },
  confirmed: null
};

// TODO: change payload type
interface Action {
  type: AuthActions,
  payload: any,
  data: {
    "X-AUTH-TOKEN": string;
    email: string;
    firstname: string;
    status: string;
    surname: string;
    userType: string;
  }
}

export default function reducer(state:AuthState = initialState, action: Action) {
  const {type, payload,data} = action;
  switch (type) {
    case AuthActions.SetDetails: {
      const valid = {};
      // @ts-ignore
      Object.keys(payload).map(v=> valid[v] = validator[v](payload[v]));
      return {...state, loading: false, ...payload, valid: {...state.valid, ...valid}}
    }
    case AuthActions.Login: {
      return {...state, loading: true}
    }
    case AuthActions.Logout: {
      return {...initialState}
    }
    case AuthActions.LoginFailed: {
      return {...state, loading: false}
    }
    case AuthActions.LoginSuccess: {
        return {
          ...state,
          authToken: data["X-AUTH-TOKEN"],
          isStudent: isStudent(data.userType),
          loading: false,
          password: "",
        }
    }
    case AuthActions.ConfirmEmailSuccess: {
      console.log('data here', payload);
      return {
        ...state,
        confirmed: true
      }
    }
    case AuthActions.ConfirmEmailFailed: {
      return {
        ...state,
        confirmed: false
      }
    }
    default:
      return state;
  }
}
