import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide/Slide";
import * as React from "react";
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import Stepper from "@material-ui/core/Stepper/Stepper";
import Step from "@material-ui/core/Step/Step";
import StepLabel from "@material-ui/core/StepLabel/StepLabel";
import {ECGActions} from "../types";
import Grid from "@material-ui/core/Grid/Grid";
import StepOneName from "./StepOneName";
import StepTwoUploadECG from "./StepTwoUploadECG";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Close from "@material-ui/icons/Close";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import Fade from "@material-ui/core/Fade/Fade";
import StepThreeUploadTakeHome from "./StepThreeUploadTakeHome";
import 'react-pdf/dist/Page/AnnotationLayer.css';
import StepFourCaseScenario from "./StepFourCaseScenario";
import {ECGSheet, Module} from "../../../../../types/module";
import {WithModuleContext, withModuleContext} from "../../../../../context/ModuleContext";
import {WithAppContext, withAppContext} from "../../../../../context/AppContext";

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

interface State {
  id: number | null;
  name: string;
  caseScenario: string;
  ecgUpload: File | null;
  takeHomeDoc: File | null;
  activeStep: number;
  imageDataUrl: any;
  takeHomeDataUrl: string;
  closeWhenDone: boolean;
  nextWhenDone: boolean;
  calibrateMode: boolean;
  calibrateImageScale: number;
}

// TODO: fix types
interface OwnProps {
  sheet: ECGSheet | null;
  close: any;
  initialStep: number;
  calibrateMode: boolean;
}

const steps = [
  ECGActions.EDIT_NAME,
  ECGActions.UPLOAD_ECG,
  ECGActions.UPLOAD_TAKE_HOME_MESSAGE,
  ECGActions.EDIT_CASE_SCENARIO,
];

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(3),
    },
    buttonSpacer: {
      flex: 1,
    },
    buttons: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    contentInnerGrid: {
      flex: 1,
      overflow: "hidden",
    },
    contentOuterGrid: {
      flex: 1,
    },
    dialog: {
      height: "95%",
      width: "95%",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
    },
    stepper: {
      padding: `${theme.spacing(3)}px 0 ${theme.spacing(5)}px`,
    },
  });

interface Props extends OwnProps, WithStyles<typeof styles>, WithModuleContext, WithAppContext {
}

class AddECG extends React.Component<Props, State> {

  public state: State = {
    activeStep: 0,
    ecgUpload: null,
    takeHomeDoc: null,
    id: null,
    imageDataUrl: undefined,
    takeHomeDataUrl: "",
    name: "",
    caseScenario: "",
    closeWhenDone: false,
    nextWhenDone: false,
    calibrateMode: false,
    calibrateImageScale: 1,
  };

  public componentDidMount() {
    // console.log(...helperRenderConsoleText('componentDidMount', 'lightBlue'));
    const {
      sheet, initialStep, calibrateMode,
      moduleContext: {
        ecgImages,
        getTakeHomeDocFn,
        getECGImageFn,
        selectedModule,
        clearLastCreatedECGIdFn
      }
    } = this.props;
    clearLastCreatedECGIdFn();
    if (sheet !== null) {
      const {id, name, overview, calibration} = sheet;
      const image = ecgImages.find(ecgImage => ecgImage.id === id);
      initialStep === 1 && !image && getECGImageFn(selectedModule !== null ? selectedModule.id : 0,
        id).then(res=>this.setState({imageDataUrl:res}));
      initialStep === 2 && getTakeHomeDocFn(id, selectedModule !== null ? selectedModule.id : 0).then(res => {
        console.log(res);
        this.setState({takeHomeDataUrl: res});
      });
      id !== undefined && this.setState({
        activeStep: initialStep,
        id,
        name,
        caseScenario: overview ? overview : "",
        imageDataUrl: image ? image.image : "",
        calibrateMode,
        calibrateImageScale: calibration,
      })
    }
  };

  // public componentWillReceiveProps(nextProps: Props) {
  //   const {id} = this.state;
  //   const {moduleContext: {lastCreatedECGId}} = nextProps;
  //   lastCreatedECGId !== id && this.setState({id: lastCreatedECGId});
  // }

  public componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.takeHomeDataUrl !== this.state.takeHomeDataUrl) {
      // console.log("Different TakeHomes");
    }
    const {appContext: {loading: prevLoading}} = prevProps;
    const {appContext: {loading}} = this.props;
    if (!loading && prevLoading) {
      const {closeWhenDone, nextWhenDone} = this.state;
      if (closeWhenDone) {
        this.handleClose();
      } else if (nextWhenDone) {
        // console.log("Going Next");
        this.handleNext();
      }
      this.setState({closeWhenDone: false, nextWhenDone: false});
    }
  }

  public getStepContent(step: number) {
    const {name, imageDataUrl, takeHomeDataUrl, caseScenario, calibrateMode, calibrateImageScale} = this.state;

    switch (step) {
      case 0:
        return <StepOneName
          value={name}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit("Done")}
        />;
      case 1:
        return <StepTwoUploadECG
          calibrateMode={calibrateMode}
          calibrateImageScale={calibrateImageScale}
          toggleCalibrateMode={this.toggleCalibrateMode}
          imageDataUrl={imageDataUrl}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit("Done")}
          handleSliderChange={this.handleSliderChange}
        />;
      case 2:
        return <StepThreeUploadTakeHome
          docData={takeHomeDataUrl}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit("Done")}
        />;
      case 3:
        return <StepFourCaseScenario
          value={caseScenario}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit("Done")}
        />;
      default:
        throw new Error('Unknown step');
    }
  }

  public render() {
    const {
      classes,
      appContext: {loading},
    } = this.props;
    const {activeStep, id, calibrateMode} = this.state;
    return (
      <Dialog
        TransitionComponent={Transition}
        open={true}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
        fullScreen={true}
        classes={{paperFullScreen: classes.dialog}}
      >
        <Fade in={loading}><LinearProgress variant="query" /></Fade>
        <DialogTitle
          id="form-dialog-title"
          disableTypography={true}
        >
          <Grid container={true} direction="row" alignItems="center" justify="space-between" wrap="nowrap">
            <Grid item={true}>
              <Typography variant="h6">
                {calibrateMode ? "Calibrate ECG" : id === -1 ? "Add" : "Edit"} ECG
              </Typography>
            </Grid>
            <Grid item={true}>
              <IconButton onClick={this.handleClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent classes={{root: classes.dialogContent}}>
          {!calibrateMode && <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>}
          <React.Fragment>
            <Grid container={true} direction="column" className={classes.contentOuterGrid} wrap="nowrap">
              <Grid item={true} className={classes.contentInnerGrid} container={true}>
                {this.getStepContent(activeStep)}
              </Grid>
              <Grid item={true} container={true} direction="row">
                <div className={classes.buttonSpacer} />
                <div className={classes.buttons}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleClose}
                    className={classes.button}
                    disabled={loading}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit("Close")}
                    className={classes.button}
                    disabled={!this.canSubmit() || loading}
                  >
                    Submit and Close
                  </Button>
                  {!calibrateMode && !id && <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleSubmit(activeStep === steps.length - 1 ? 'Done' : 'Next')}
                    className={classes.button}
                    disabled={!this.canSubmit() || loading}
                  >
                    {activeStep === steps.length - 1 ? 'Done' : 'Next'}
                  </Button>}
                </div>
              </Grid>
            </Grid>
          </React.Fragment>
        </DialogContent>
      </Dialog>
    );
  }

  private canSubmit = () => {
    const {activeStep, name, ecgUpload, takeHomeDoc, caseScenario, calibrateMode, calibrateImageScale} = this.state;

    switch (activeStep) {
      case 0: {
        return name.length > 2
      }
      case 1: {
        return calibrateMode ? calibrateImageScale : !!ecgUpload;
      }
      case 2: {
        return !!takeHomeDoc;
      }
      case 3: {
        return !!caseScenario;
      }
      default: {
        return false;
      }
    }
  };

  private toggleCalibrateMode = () => this.setState({calibrateMode: !this.state.calibrateMode});

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name: string = e.currentTarget.name;
    switch (name) {
      case "ecgUpload": {
        if (e.target.files && e.target.files[0]) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.setState({
              imageDataUrl: event.target.result,
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
        this.setState({ecgUpload: e.target.files ? e.target.files[0] : null});
        break;
      }
      case "takeHomeUpload": {
        if (e.target.files && e.target.files[0]) {
          console.log('here');
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.setState({
              takeHomeDataUrl: event.target.result,
            });
          };
          reader.readAsDataURL(e.target.files[0]);
        }
        this.setState({takeHomeDoc: e.target.files ? e.target.files[0] : null});
        break;
      }
      case "name": {
        this.setState({name: e.currentTarget.value});
        break;
      }
      case "caseScenario": {
        this.setState({caseScenario: e.currentTarget.value});
        break;
      }
      case "calibrateImageScale": {
        this.setState({calibrateImageScale: Number(e.currentTarget.value)});
        break;
      }
    }
  };

  private handleSliderChange = (e: React.ChangeEvent<{}>, value: number) => {
    // console.log(this.state.id);
    this.setState({calibrateImageScale: value});
  };

  private handleSubmit = (button: "Next" | "Done" | "Close") => (e: React.FormEvent) => {
    const {activeStep, id} = this.state;
    const {moduleContext:{lastCreatedECGId}} = this.props;
    let answerId = id;
    if (this.props.sheet) {
      answerId = this.props.sheet.id;
    }
    if (lastCreatedECGId) {
      answerId = lastCreatedECGId;
    }
    e.preventDefault();
    const {moduleContext: {addEditECGFn, selectedModule}} = this.props;
    const moduleId = (selectedModule as Module).id;
    if (this.canSubmit()) {
      switch (activeStep) {
        case 0: {
          const {name: ecgTitle} = this.state;
          answerId === -1 ?
            addEditECGFn({ecgTitle, moduleId}) :
            addEditECGFn({ecgTitle, answerId, moduleId});
          break;
        }
        case 1: {
          const {ecgUpload, calibrateMode, calibrateImageScale} = this.state;
          const {moduleContext: {uploadECGFn}} = this.props;
          if (calibrateMode) {
            addEditECGFn({calibration: calibrateImageScale, answerId, moduleId});
          } else {
            // console.log("answerId", answerId);
            ecgUpload !== null && uploadECGFn(ecgUpload, answerId || 0, moduleId);
          }
          break;
        }
        case 2: {
          const {takeHomeDoc} = this.state;
          const {moduleContext: {uploadTakeHomeFn}} = this.props;
          takeHomeDoc !== null && uploadTakeHomeFn(takeHomeDoc, answerId || 0, moduleId);
          break;
        }
        case 3: {
          const {caseScenario} = this.state;
          caseScenario !== null && addEditECGFn({caseScenario, answerId, moduleId});
          break;
        }
      }
      button === "Next" ? this.setState({nextWhenDone: true, closeWhenDone: false}) : this.setState({closeWhenDone: true, nextWhenDone: false});
    }
  };

  private handleNext = () => {
    this.state.activeStep === steps.length - 1 ? this.handleClose() :
      this.setState(state => ({
        activeStep: state.activeStep + 1,
      }));
  };

  private handleClose = () => {
    const {closeWhenDone} = this.state;
    const {close, appContext: {loading}} = this.props;
    ((!loading && !closeWhenDone) || (!loading && closeWhenDone)) && close();
  };
}

// @ts-ignore
export default withStyles(styles)(withModuleContext(withAppContext(AddECG))) as unknown as React.ComponentClass<OwnProps>;
