import {Theme} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import LinearProgress from "@material-ui/core/LinearProgress/LinearProgress";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import * as React from "react";
import Loader from "../../../components/Loader";
import AddEditModule from "./AddEditModule";
import ModuleCard from "./ModuleCard";
import RemoveDialog from "./RemoveDialog";
import Typography from "@material-ui/core/Typography/Typography";
import Fade from "@material-ui/core/Fade/Fade";
import {getAnswerFn} from "../../../redux/actions/ecg";
import {WithModuleContext, withModuleContext} from "../../../context/ModuleContext";
import {WithAppContext, withAppContext} from "../../../context/AppContext";
import {Module, StudentResult} from "../../../types/module";
import {EditAnalysisFunction} from "../Main";

const styles = (theme: Theme) =>
  createStyles({
    cardContent: {
      flexGrow: 1,
    },
    cardMedia: {
      paddingTop: "56.25%", // 16:9
    },
    grid: {
      transition: "width 0.7s",
      width: "auto",
    },
    layout: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(4),
      width: "auto",
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 1100,
      },
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  });

// TODO: change type of modifyGroup
interface State {
  showAddEditModuleDialog: boolean;
  modifyGroup: any;
  moduleToEdit: null | Module;
  removeModule: {};
  rowHovered: number;
  showRemoveDialog: boolean;
}

interface OwnProps {
  studentResults: StudentResult[];
  handleEditAnalysis: EditAnalysisFunction;
}

interface DispatchProps {
  getAnswerFunction: typeof getAnswerFn;
}

type Props = {}
  & OwnProps
  & DispatchProps
  & WithStyles<typeof styles>
  & WithModuleContext
  & WithAppContext;

class Modules extends React.Component<Props, State> {
  public state: State = {
    showAddEditModuleDialog: false,
    modifyGroup: [],
    moduleToEdit: null,
    removeModule: {},
    rowHovered: -1,
    showRemoveDialog: false,
  };

  public render() {
    const {
      moduleContext: {
        selectedModule,
        modules
      }, appContext: {
        isStudent,
        loading
      }, classes,
      handleEditAnalysis,
      studentResults,
    } = this.props;
    const {showAddEditModuleDialog, removeModule, showRemoveDialog, moduleToEdit} = this.state;
    return (
      <main>
        {loading && <LinearProgress variant="query" />}
        <div className={classes.layout}>
          <Grid container={true} direction="column">
            <Typography variant="h4">{selectedModule === null ? "Modules" : `Module: ${selectedModule.name}`}</Typography>
            <Fade in={selectedModule !== null}>
              <Typography variant="subtitle1">{selectedModule && selectedModule.code}</Typography>
            </Fade>
            <Fade in={selectedModule !== null}>
              <Typography variant="caption" className={classes.title}>by {selectedModule && selectedModule.lecturer && selectedModule.lecturer.name}</Typography>
            </Fade>
            <Grid container={true} spacing={8}>
              {modules.length === 0 && !loading && <Typography>There are no modules to display</Typography>}
              {modules.map((v, i) => (
                <ModuleCard
                  key={i}
                  module={v}
                  selected={selectedModule}
                  removeFunction={this.removeModule}
                  addEditModuleFunction={this.handleAddEditModule(true, v)}
                  editAnalysisFunction={handleEditAnalysis}
                  studentResults={studentResults}
                />
              ))}
              {!isStudent && !loading && !selectedModule && <Grid item={true} sm={6} md={4} lg={3}>
                <Button fullWidth={true} onClick={this.handleAddEditModule(true)}>{loading ? <Loader height={20} /> : "Add Module"}</Button>
              </Grid>
              }
            </Grid>
          </Grid>
        </div>
        {/*{!loading && data.length === 0 && <NoResults>Sorry no modules found</NoResults>}*/}
        {showAddEditModuleDialog && <AddEditModule close={this.handleAddEditModule(false)} module={moduleToEdit} />}
        <RemoveDialog module={removeModule} {...{showRemoveDialog}} close={this.closeRemoveDialog} confirm={this.confirmRemove} />
      </main>
    );
  }

  private removeModule = (mod: Module) => {
    // console.log(mod);
    this.setState({removeModule: mod, showRemoveDialog: true})
  };
  private closeRemoveDialog = () => {
    this.setState({removeModule: {}, showRemoveDialog: false})
  };
  private confirmRemove = (mod: Module) => {
    const {moduleContext: {removeModuleFn}} = this.props;
    removeModuleFn(mod);
  };

  private handleAddEditModule = (showAddEditModuleDialog: boolean, module: Module | null = null) => () => {
    this.setState({showAddEditModuleDialog, moduleToEdit: module});
  };
}

// @ts-ignore
export default withStyles(styles)(withModuleContext(withAppContext(Modules))) as unknown as React.ComponentClass<OwnProps>;
