import React, {useEffect, useState} from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {createStyles, Theme} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import {makeStyles} from "@material-ui/styles";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import {UserListProps} from "./UserList.container";
import LinearProgress from "@material-ui/core/LinearProgress";
import Grid from "@material-ui/core/Grid";
import Icon from "../../assets/icons";
import {User, UserTypesString} from "../../types/app";
import UserDialog, {UserDialogActions} from "./UserDialog";
import {Button} from "@material-ui/core";

type Data = User;

function createData(
  id: number,
  title: string,
  surname: string,
  firstname: string,
  email: string,
  userType?: UserTypesString,
  activated?: boolean
): Data {
  return {id, title, surname, firstname, email, userType, activated};
}

function desc<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort<T>(array: T[], cmp: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

type Order = "asc" | "desc";

function getSorting<K extends keyof any>(
  order: Order,
  orderBy: K,
): (a: { [key in K]: number | string }, b: { [key in K]: number | string }) => number {
  return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

interface HeadRow {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headRows: HeadRow[] = [
  {id: "title", numeric: false, disablePadding: false, label: "Title"},
  {id: "surname", numeric: false, disablePadding: false, label: "Surname"},
  {id: "firstname", numeric: false, disablePadding: false, label: "First Name"},
  {id: "email", numeric: false, disablePadding: false, label: "Email"},
  {id: "userType", numeric: false, disablePadding: false, label: "User Role"},
  {id: "activated", numeric: false, disablePadding: false, label: "Activated"},
];

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  isStudent: boolean;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell
            key={row.id}
            align={row.numeric ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id)}
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell />
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
        : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
    spacer: {
      flex: "1 1 100%",
    },
    actions: {
      color: theme.palette.text.secondary,
    },
    title: {
      flex: "0 0 auto",
    },
  }),
);


interface EnhancedTableToolbarProps {
  numSelected: number;
}

const EnhancedTableToolbar = (props: EnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const {numSelected} = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography variant="h6" id="tableTitle">
            Nutrition
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="Filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: theme.spacing(3),
    },
    paper: {
      width: "100%",
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    tableWrapper: {
      overflowX: "auto",
    },
    layout: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      marginTop: theme.spacing(4),
      width: "auto",
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 1100,
      },
    },
    searchLine: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      padding: "5px"
    },
    searchButton: {
      marginLeft: "5px"
    }
  }),
);

const UserListLayout = (props: UserListProps) => {
  // console.log(props);

  /* State definitions */
  const [users, setUsers] = useState<User[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults,setSearchResults] = useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("id");
  const [page, setPage] = React.useState(0);
  const [totalUsers, setTotalUsers] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [showUserDialog, setShowUserDialog] = React.useState(true);
  const [selectedAction, setSelectedAction] = React.useState<UserDialogActions>("edit");
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const {
    moduleContext: {
      getUsersFn,
      postUsersFn,
      deleteUserFn,
    },
    appContext: {
      isStudent
    },
  } = props;
  const loading = false;
  const classes = useStyles();

  const searchUsers = () => {
    getUsersFn(searchTerm, 1).then((value) => {
      setSearchResults(true);
      setUsers(value.users || []);
      setTotalUsers(value.totalUsers);
      // setPage(value.currentPage - 1);
    });
  };
  const fetchUsers = (page: number = 1) => {
    getUsersFn('', page).then((value) => {
      setSearchResults(false);
      console.log('users are ', value.users);
      setUsers(value.users || []);
      setTotalUsers(value.totalUsers);
      // setPage(value.currentPage - 1);
    });
  };
  useEffect(() => {
    fetchUsers()
  }, []);

  function handleRequestSort(event: React.MouseEvent<unknown>, property: keyof Data) {
    const isDesc = orderBy === property && order === "desc";
    setOrder(isDesc ? "asc" : "desc");
    setOrderBy(property);
  }

  const handleSave = (localUser: User) => {
    postUsersFn(localUser).then(() => {
      closeUserDialog();
      fetchUsers(page +1);
    });
  };
  const handleDelete = (localUser: User) => {
    deleteUserFn(String(localUser.id)).then(()=> {
      closeUserDialog();
      fetchUsers(page +1);
    })
    console.log('add code to remove user',localUser);
  };

  const handleClick = (user: User, action: UserDialogActions = "edit") => () => {
    setSelectedUser(user);
    setSelectedAction(action);
    setShowUserDialog(true);
  };

  const handleAddUser = () => {
    setSelectedUser({} as User);
    setSelectedAction("add");
    setShowUserDialog(true);
  };

  function handleChangePage(event: unknown, newPage: number) {
    setPage(newPage);
    fetchUsers(newPage + 1);
  }

  function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
    setRowsPerPage(+event.target.value);
  }

  const closeUserDialog = () => {
    setShowUserDialog(false);
  };

  const rows = users.map(result => {
    return createData(
      result.id || 0,
      result.title || "",
      result.surname,
      result.firstname,
      result.email,
      result.userType || "Student",
      result.activated
    );
  });
  const setSearch = (e: any) => {
    console.log(e);
    setSearchTerm(e.target.value);
  }
  const emptyRows = rowsPerPage - rows.length;
  return (
    <React.Fragment>
      <main>
        {loading && <LinearProgress variant="query" />}
        <div className={classes.layout}>
          <Grid container={true} direction="column">
            <Grid container item direction="row" alignItems="center" justify="center">
              <Grid item style={{flex: 1}}>
                <Typography variant="h4">Users</Typography>
              </Grid>
              <Grid>
                <Button onClick={handleAddUser}>Add User</Button>
              </Grid>
            </Grid>
            <Grid container={true} spacing={8} style={{marginTop: 8}}>
              <div className={classes.root}>
                <Paper className={classes.paper}>
                  <div className={classes.tableWrapper}>
                    <div className={classes.searchLine}>
                      <div><input type='text' value={searchTerm} onChange={setSearch}/></div>
                      <div><button className={classes.searchButton} onClick={() => searchUsers()}>Search</button></div>
                      {searchResults && <div><button className={classes.searchButton} onClick={() =>
                          fetchUsers()}>All users</button></div>}
                    </div>
                    <Table
                      className={classes.table}
                      aria-labelledby="tableTitle"
                    >
                      <EnhancedTableHead
                        isStudent={isStudent || true}
                        order={order}
                        numSelected={0}
                        orderBy={orderBy}
                        onSelectAllClick={()=>{}}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                      />
                      <TableBody>
                        {stableSort(rows as any, getSorting(order, orderBy))
                        .map((row) => {
                          const isItemSelected = false;
                          // @ts-ignore
                          // const dateTime = moment(row.datetime).format("DD/MM/YYYY HH:MM");
                          // const dateTime = moment(row.datetime).format("YYYY-MM-DD HH:MM");
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              aria-checked={isItemSelected}
                              tabIndex={-1}
                              key={String(row.id)}
                              selected={isItemSelected}
                            >
                              <TableCell>{row.title}</TableCell>
                              <TableCell>{row.surname}</TableCell>
                              <TableCell>{row.firstname}</TableCell>
                              <TableCell>{row.email}</TableCell>
                              {/*<TableCell>{getUserType(row.userType as UserTypes)}</TableCell>*/}
                              <TableCell>{row.userType}</TableCell>
                              { row.activated && <TableCell>Yes</TableCell>}
                              { !row.activated && <TableCell>No</TableCell>}
                              <TableCell>
                                <IconButton onClick={handleClick(row as unknown as User)}>
                                  <Icon variant="edit" />
                                </IconButton>
                                <IconButton onClick={handleClick(row as unknown as User,"delete")}>
                                  <Icon variant="delete" />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {emptyRows > 0 && (
                          <TableRow style={{height: 49 * emptyRows}}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </div>
                  <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={totalUsers}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                      "aria-label": "Previous Page",
                    }}
                    nextIconButtonProps={{
                      "aria-label": "Next Page",
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
              </div>
            </Grid>
          </Grid>
        </div>
      </main>
      {selectedUser !== null && showUserDialog && <UserDialog open={showUserDialog} onClose={closeUserDialog} action={selectedAction} user={selectedUser} handleSave={handleSave} handleDelete={handleDelete} />}
    </React.Fragment>
  );
};

export default UserListLayout;
