interface DefaultApi {
  headers: {},
  responseType: "json" | "arraybuffer";
  timeout: number;
}

export interface ApiCall extends DefaultApi {
  method: "POST" | "GET" | "DELETE";
  url: string;
}

// export const uri = "http://ecgonlinev2.duckeggdigital.com/api";
// export const uri = "http://localhost:9005"
export const uri = "https://ecgonline.uct.ac.za/api";

const defaultApi: DefaultApi = {
  headers: {
    "Content-Type": "application/json",
  },
  responseType: "json",
  timeout: 20000,
};

const login: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/login`,
};
const register: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/register`,
};

const modules: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/modules`,
};
const getLectures: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/lecturevideo`,
};
const createLecture: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/lecturevideo`,
};
const confirmEmail: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/confirm`,

}
const deleteLecture: ApiCall = {
  ...defaultApi,
  method: "DELETE",
  url: `${uri}/lecturevideo`,
};
const getUsers: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/user`,
};

const deleteUser: ApiCall = {
  ...defaultApi,
  method: "DELETE",
  url: `${uri}/user`,
};

const postUsers: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/user`,
};

const addEditModule: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/addeditmodule`,
};

const publishUnPublishModule: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/publishunpublishmodule`,
};

const removeModule: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/deletemodule`,
};

const sheets: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/answers`,
};

const fetchECGImage: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/ecgimage`,
  responseType: "arraybuffer",
};
const fetchECGThumb: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/ecgthumb`,
  responseType: "arraybuffer",
};

const addAnswer: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/addanswer`,
};

const uploadECG: ApiCall = {
  ...defaultApi,
  headers: {
    "Content-Type": "application/form-data",
  },
  method: "POST",
  url: `${uri}/ecgupload`,
};

const uploadTakeHomeDoc: ApiCall = {
  ...defaultApi,
  headers: {
    "Content-Type": "application/form-data",
  },
  method: "POST",
  url: `${uri}/takehomeupload`,
};

const fetchTakeHomeDoc: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/takehome`,
  responseType: "arraybuffer",
};
const answer: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/answer`,
};
const getStudentAnswers: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/answers`,
};
const getStudentResults: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/studentresults`,
};

const deleteEcgSheet: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/deleteecgsheet`,
};

const saveStudentAnswer: ApiCall = {
  ...defaultApi,
  method: "POST",
  url: `${uri}/studentanswer`,
};

const getStudentAnswer: ApiCall = {
  ...defaultApi,
  method: "GET",
  url: `${uri}/studentanswer`,
};

export default {
  addAnswer,
  addEditModule,
  addStudentAnswer: {
    ...defaultApi,
    method: "POST",
    url: `${uri}/studentanswer`,
  },
  answer,
  deleteEcgSheet,
  login,
  register,
  modules,
  getUsers,
  deleteUser,
  postUsers,
  publishUnPublishModule,
  removeModule,
  secret: "019e47cd-ad19-4f52-884a-76885e2695a6",
  sheets,
  saveStudentAnswer,
  fetchECGImage,
  fetchECGThumb,
  getStudentAnswers,
  getStudentAnswer,
  getStudentResults,
  fetchTakeHomeDoc,
  uploadECG,
  uploadTakeHomeDoc,
  getLectures,
  createLecture,
  deleteLecture,
  confirmEmail
}
