import axios from "axios";
import {call, put, select, takeEvery, takeLatest} from "redux-saga/effects";
import {LectureActions, ModuleActions} from "../../constants/Actions";
import Api from "../../constants/API";
// import {ModuleFetchECGImageAction, ModuleFetchTakeHomeAction, ModuleSetECGCalibration, ModuleUpdateCaseScenarioAction, ModuleUploadECGAction, ModuleUploadTakeHomeDocAction, PublishUnPublishModuleFn} from "../actions/moduleActions";
import store from "../index";
import {makeAxiosCall} from "./index";
import {AppState} from "../reducers";

const getModuleState = (state: AppState) => state.moduleReducer;

function* setLoading(val: boolean) {
  yield put({type: LectureActions.SetLoading, payload: {loading: val}});
}

// TODO: fix types
// function* createModule(action: any) {
//   const {module} = action;
//   const {auth} = store.getState();
//   const {authToken} = auth;
//   try {
//     // @ts-ignore
//     const data = yield call(axios, {...Api.addEditModule, data: {...module}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
//     yield put({type: ModuleActions.CreateModuleSuccess, data});
//   } catch (e) {
//     yield put({type: ModuleActions.CreateModuleFailed, message: e.message});
//   }
// }

// function* updateModule(action: any) {
//   const {module} = action;
//   const {auth} = store.getState();
//   const {authToken} = auth;
//   try {
//     // @ts-ignore
//     const data = yield call(axios, {...Api.addEditModule, data: {...module}, headers: {...Api.addEditModule.headers, "X-AUTH-TOKEN": authToken}});
//     yield put({type: ModuleActions.UpdateSuccess, data});
//   } catch (e) {
//     yield put({type: ModuleActions.UpdateFailed, message: e.message});
//   }
// }

function* getLectures() {
  console.log('In saga');
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {...Api.getLectures, headers: {...Api.modules.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: LectureActions.getLecturesSuccess, data});
  } catch (e) {
    yield put({type: LectureActions.getLecturesError, message: e.message});
  }
}

function* createLecture(action:any) {
  console.log('In saga');
  const {payload} = action;
  const {auth} = store.getState();
  const {authToken} = auth;
  try {
    console.log('payload in saga ', payload);
    // @ts-ignore
    const data = yield call(axios, {...Api.createLecture, data: {...payload}, headers: {...Api.modules.headers, "X-AUTH-TOKEN": authToken}});
    yield put({type: LectureActions.createLectureSuccess, data});
  } catch (e) {
    yield put({type: LectureActions.createLectureError, message: e.message});
  }
}

function* deleteLecture(action: any) {
  const {auth} = store.getState();
  const {authToken} = auth;
  const {payload} = action;
  try {
    // @ts-ignore
    const {data} = yield call(axios, {
      ...Api.deleteLecture,
      headers: {...Api.sheets.headers, "X-AUTH-TOKEN": authToken},
      url: `${Api.deleteLecture.url}/${payload}`,
    });
    yield put({type: LectureActions.deleteLectureSuccess, deleted: true});
  } catch (e) {
    yield put({type: LectureActions.deleteLectureError, deleted: false});
  }
}

export function* lectureSagas() {
  yield takeEvery(LectureActions.getLectures, getLectures);
  yield takeEvery(LectureActions.createLecture, createLecture);
  yield takeEvery(LectureActions.deleteLecture, deleteLecture);
}
