import * as React from 'react';
import * as crypto from "crypto";
import API from "../constants/API";
import {APICallResponse, LoginCall, User} from "../types/app";
import moment from "moment";
import axios from "axios";
import {persistor} from "../redux";

export const generateApiKey = (payload: any) => {
  // @ts-ignore
  const newD = moment().format("YYYY-MM-DD HH:mm:ss");
  return {
    apiKey: crypto.createHash("sha512").update(String(`${newD}.${crypto.createHash("sha512").update(String(JSON.stringify(payload))).digest("hex")}.${API.secret}`)).digest("hex"),
    newDate: newD,
  }
};

const initialUser: User = {
  authToken: "",
  email: "",
  firstname: "",
  surname: "",
  isStudent: false,
  id: undefined
};

interface AppContextInterface extends User {
  loading: boolean;
  terms: boolean;
  termsAccepted: (status: boolean) => void
  loginFn: (
    email: string,
    password: string
  ) => Promise<{}>
  logoutFn: () => void
  // callApi: (url: string, call: RequestInit, postData?: any,dataType?:"json"|"form") => Promise<APICallResponse>
  callApi: (url: string, call: RequestInit, postData?: any, dataType?: "json" | "form") => Promise<any>
  setUser: (user: User) => void
}


const ctxt = React.createContext<AppContextInterface | null>(null);

const AppContextProvider = ctxt.Provider;

const AppContextConsumer = ctxt.Consumer;

class AppContext extends React.Component<{}, AppContextInterface> {
  // noinspection JSUnusedGlobalSymbols
  readonly state: AppContextInterface = {
    ...initialUser,
    loading: false,
    terms: false,
    loginFn: (email: string, password: string) => {
      const {callApi} = this.state;
      const generatedApiKey = generateApiKey({email, password});
      return callApi(API.login.url, {
        ...API.login,
        headers: {
          ...API.login.headers,
          "API-KEY": generatedApiKey.apiKey,
          "REQUEST-TIME": generatedApiKey.newDate,
        },
      }, {email, password}).then(res => {
        console.log(res);
        const {status, response} = (res as APICallResponse);
        const {email, firstname, surname, userType,id,title} = (response as LoginCall);
        const xAuthToken = (response as LoginCall)["X-AUTH-TOKEN"];
        if (status === "ok") {
          const user: User = {
            authToken: xAuthToken,
            firstname,
            surname,
            email,
            id,
            userType,
            title,
            isStudent: userType === "Student"
          };
          this.setState({...user});
          sessionStorage.setItem("ecg_user", JSON.stringify(user))
        }
        return status === "ok";
      });
    },
    logoutFn: () => {
      persistor.purge().then();
      sessionStorage.removeItem("ecg_user");
      sessionStorage.removeItem("ecg_selectedModule");
      this.setState({...initialUser, terms: false})
      window.location.reload();
    },
    setUser: (user) => {
      this.setState({...user});
    },
    termsAccepted: (status) => {
      console.log(status);
      this.setState({terms: status});
    },
    callApi: (url, call, postData, dataType = "json") => {
      this.setState({loading: true});
      return axios({
        ...call,
        // json: true,
        url: url,
        data: postData !== undefined && dataType === "json" ? JSON.stringify(postData) :
        postData !== undefined && dataType === "form" ? postData : null
      })
      .then(res => {
        this.setState({loading: false});
        return ({
          status: "ok",
          response: (call as any).responseType === "json" ?res.data:res.data}
          )
      })
      .catch(error => {
        this.setState({loading: false});
        const {logoutFn} = this.state;
        this.setState({loading: false});
        // console.log(error);
        if (error.response) {
          switch (error.response.status) {
            case 403:
              return {status: "forbidden", response: {}};
            case 401:
              logoutFn();
              return {status: "expired", response: {}};
            default:
              return {status: "error", response: {error: error}}
          }
        } else {
          logoutFn();
          return {status: "error", response: {error: error}}
        }
      })
    }
  };

  componentWillMount(): void {
    const user: User = JSON.parse(sessionStorage.getItem("ecg_user") || "{}");
    user && this.setState(user);
  }

  public render() {
    const {children} = this.props;
    return (
      <AppContextProvider value={this.state}>
        {children}
      </AppContextProvider>
    )
  }

}

export interface WithAppContext {
  appContext: AppContextInterface;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withAppContext<P extends { appContext?: AppContextInterface },
  R = Omit<P, 'appContext'>>(
  Component: React.ComponentClass<P> | React.StatelessComponent<P>
): React.SFC<R> {
  return function BoundComponent(props: R) {
    return (
      <AppContextConsumer>
        {value => {
          // @ts-ignore
          return <Component {...props} appContext={value} />;
        }}
      </AppContextConsumer>
    );
  };
}

export default AppContext;
