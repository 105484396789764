import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Slide from "@material-ui/core/Slide/Slide";
import TextField from "@material-ui/core/TextField/TextField";
import * as React from "react";
import {createModuleFn, updateModuleFn} from "../../../redux/actions/moduleActions";
import {Module} from "../../../types/module";
import {WithModuleContext, withModuleContext} from "../../../context/ModuleContext";

function Transition(props: any) {
  return <Slide direction="up" {...props} />;
}

interface State {
  code: string;
  id: number;
  name: string;
}

// TODO: fix module type
interface OwnProps {
  module: Module|null;
  close: any;
}

interface DispatchProps {
  createModuleFunction: typeof createModuleFn;
  updateModuleFunction: typeof updateModuleFn;
}


interface Props extends DispatchProps, OwnProps, WithModuleContext{}

class AddEditModule extends React.Component<Props, State> {
  public state = {
    code: "",
    id: -1,
    name: "",
  };

  public componentWillMount() {
    const {module} = this.props;
    module !== null && this.setState({...module});
  };

  public render() {
    const {name, code, id} = this.state;
    return (<React.Fragment>
          <Dialog
              TransitionComponent={Transition}
              open={true}
              onClose={this.handleClose}
              aria-labelledby="form-dialog-title"
          >
            <form onSubmit={this.handleSubmit}>
              <DialogTitle id="form-dialog-title">{id === -1 ? "Add" : "Edit"} Module</DialogTitle>
              <DialogContent>
                <TextField
                    autoFocus={true}
                    margin="dense"
                    id="name"
                    label="Module Name"
                    type="text"
                    fullWidth={true}
                    value={name}
                    name="name"
                    onChange={this.handleChange}
                    error={name.length > 0 && name.length < 3}
                    required={true}
                    helperText="Need to be at least 3 characters"
                />
                <TextField
                    margin="dense"
                    id="name"
                    label="Module Code"
                    type="text"
                    fullWidth={true}
                    value={code}
                    name="code"
                    onChange={this.handleChange}
                    error={code.length > 0 && code.length < 3}
                    required={true}
                    helperText="Need to be at least 3 characters"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  Cancel
                </Button>
                <Button
                    disabled={!(name.length >= 3 && code.length >= 3)}
                    type="submit"
                    color="primary"
                    onSubmit={this.handleSubmit}
                >
                  {id === -1 ? "Add" : "Update"}
                </Button>
              </DialogActions>
            </form>
          </Dialog>
        </React.Fragment>
    )
  }

  private handleChange = ({currentTarget}: React.ChangeEvent<HTMLInputElement>) => {
    const {name, value} = currentTarget;
    this.setState({...this.state, [name]: value});
  };

  private handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const {name, code, id} = this.state;
    const {moduleContext:{addEditModuleFn}, close} = this.props;
    id === -1 ?
        addEditModuleFn({name, code}) :
        addEditModuleFn({name, code, id});
    close();
  };

  private handleClose = () => {
    const {close} = this.props;
    close();
  };
}

export default withModuleContext(AddEditModule) as unknown as React.ComponentClass<OwnProps>;
