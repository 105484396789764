import {AuthActions, ModuleActions} from "../../constants/Actions";


export interface Lecturer {
  id: number;
  name: string;
}

export interface ModuleReducer {
  id: number;
  code: string;
  name: string;
  isPublished: boolean;
  ecgSheets: [],
  lecturer: Lecturer
}
export interface ECGImage{
  id: number;
  image: string;
}

export interface TakeHomeDoc {
  id: number;
  doc: string
}

export interface ModuleState {
  data: [];
  error: string;
  loading: boolean;
  modifyAction: string;
  selectedModule: -1 | ModuleReducer;
  lastCreatedECGId: number | null;
  ecgImages: ECGImage[],
  takeHomeDocs: TakeHomeDoc[],
}

const initialState: ModuleState = {
  data: [],
  error: "",
  lastCreatedECGId: null,
  loading: false,
  modifyAction: "",
  selectedModule: -1,
  ecgImages: [],
  takeHomeDocs: [],
};

// TODO: change payload type
// TODO: change data type
// TODO: change ecgSheets type
interface Action {
  type: ModuleActions & AuthActions.Logout;
  payload: any;
  data: any;
  ecgSheets: any;
}

export default function reducer(state: ModuleState = initialState, action: Action) {
  const {type, payload, data, ecgSheets} = action;
  switch (type) {

    case ModuleActions.CreateSheet:
    case ModuleActions.CreateModule:
    case ModuleActions.GetModules:
    case ModuleActions.Update:
    case ModuleActions.UpdateSheet:
    case ModuleActions.Remove:
    case ModuleActions.RemoveSheet:
    case ModuleActions.PublishUnPublish:
    case ModuleActions.GetSheets: {
      return {...state, loading: true}
    }
    case ModuleActions.SetLoading: {
      return {...state,loading: payload.loading}
    }
    case ModuleActions.SelectModule: {
      return {...state, selectedModule: payload.item, modifyAction: payload.action}
    }
    case ModuleActions.DeselectModule: {
      return {...state, selectedModule: -1, modifyAction: ""}
    }
    case ModuleActions.CreateSheetSuccess: {
      return {...state, lastCreatedECGId: data.answer_id}
    }
    case ModuleActions.UpdateSuccess:
    case ModuleActions.UpdateFailed:
    case ModuleActions.UpdateSheetSuccess:
    case ModuleActions.UpdateSheetFailed:
    case ModuleActions.RemoveSuccess:
    case ModuleActions.RemoveFailed:
    case ModuleActions.RemoveSheetSuccess:
    case ModuleActions.RemoveSheetFailed:
    case ModuleActions.PublishUnPublishSuccess:
    case ModuleActions.PublishUnPublishFailed:
    case ModuleActions.GetSheetsFailed: {
      return {...state, loading: false}
    }
    case ModuleActions.GetModulesSuccess: {
      return {...state, loading: false, data}
    }
    case ModuleActions.GetSheetsSuccess: {
      return {...state, loading: false, selectedModule: state.selectedModule !== -1 ? {...state.selectedModule, ecgSheets} : {ecgSheets}}
    }
    case AuthActions.Logout: {
      return {...initialState}
    }
    case ModuleActions.FetchECGImageSuccess: {
      return {...state,ecgImages: [...state.ecgImages.filter(image=>image.id !== data.id),{id: data.id,image: data.image}]}
    }
    case ModuleActions.FetchTakeHomeDocSuccess: {
      return {...state,takeHomeDocs: [...state.takeHomeDocs.filter(doc=>doc.id !== data.id),{id: data.id,doc: data.doc}]}
    }
    default:
      return state;
  }
}
