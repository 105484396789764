import * as React from 'react';
import API from "../constants/API";
import {WithAppContext, withAppContext} from "./AppContext";
import {AnalysisInterface, GetStudentAnswerResponse, StudentAnalysisInterface} from "../types/Analysis";

interface ECGContextInterface {
  getAnalysis: (answerId:number)=>Promise<AnalysisInterface>;
  saveAnalysis: (answer:AnalysisInterface)=>Promise<boolean>;
  saveStudentAnswer: (answer:Partial<StudentAnalysisInterface>)=>Promise<{
    answerId: number,
    moduleId: number,
    studentAnswerId: number
  }>;
  getStudentAnswer: (studentAnswerId:number)=>Promise<GetStudentAnswerResponse>;
}

const ctxt = React.createContext<ECGContextInterface | null>(null);

const ECGContextProvider = ctxt.Provider;

const ECGContextConsumer = ctxt.Consumer;

class ECGContext extends React.Component<WithAppContext, ECGContextInterface> {
  // noinspection JSUnusedGlobalSymbols
  public state:ECGContextInterface = {
    getAnalysis: answerId=> {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.answer.url}/${answerId}`, {
        ...API.modules, headers: {
          ...API.modules.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }).then(res => {
        const {response:{analysis}} = res;
        const tmpAnalysis = analysis as AnalysisInterface;
        if (tmpAnalysis && typeof tmpAnalysis.qrsComplex.qrsComplexAxisMeasurement === "object") {
          tmpAnalysis.qrsComplex.qrsComplexAxisMeasurement = "";
        }
        if (tmpAnalysis && typeof tmpAnalysis.qtInterval.wideQRSAdjustedMeasurement === "object") {
          tmpAnalysis.qtInterval.wideQRSAdjustedMeasurement = "";
        }
        if (tmpAnalysis && tmpAnalysis.step3.optionalQuestion1Answers.length !== 4) {
          for (const item in ["", "", "", ""]) {
            if (tmpAnalysis.step3.optionalQuestion1Answers[item] === undefined) {
              tmpAnalysis.step3.optionalQuestion1Answers.push("");
            }
          }
        }
        if (tmpAnalysis && tmpAnalysis.step3.optionalQuestion2Answers.length !== 4) {
          for (const item in ["", "", "", ""]) {
            if (tmpAnalysis.step3.optionalQuestion2Answers[item] === undefined) {
              tmpAnalysis.step3.optionalQuestion2Answers.push("");
            }
          }
        }
        if (tmpAnalysis && (tmpAnalysis.step3.optionalQuestion1CorrectAnswer as unknown === "" || tmpAnalysis.step3.optionalQuestion1CorrectAnswer)) {
          if (tmpAnalysis.step3.optionalQuestion1CorrectAnswer as unknown === "") {
            tmpAnalysis.step3.optionalQuestion1CorrectAnswer = -1;
          } else {
            tmpAnalysis.step3.optionalQuestion1CorrectAnswer = Number(tmpAnalysis.step3.optionalQuestion1CorrectAnswer);
          }
        }
        if (tmpAnalysis && (tmpAnalysis.step3.optionalQuestion2CorrectAnswer as unknown === "" || tmpAnalysis.step3.optionalQuestion2CorrectAnswer)) {
          if (tmpAnalysis.step3.optionalQuestion2CorrectAnswer as unknown === "") {
            tmpAnalysis.step3.optionalQuestion2CorrectAnswer = -1;
          } else {
            tmpAnalysis.step3.optionalQuestion2CorrectAnswer = Number(tmpAnalysis.step3.optionalQuestion2CorrectAnswer);
          }
        }
        return (tmpAnalysis);
      });
    },
    saveAnalysis: analysis => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(API.addAnswer.url, {
        ...API.addAnswer, headers: {
          ...API.addAnswer.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, {analysis:{...analysis,answerId:Number(analysis.answerId)}}).then(() => {
        // console.log(res);
        return true;
      });
    },
    saveStudentAnswer: async analysis => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      // console.log(analysis);
      return callApi(API.saveStudentAnswer.url, {
        ...API.saveStudentAnswer, headers: {
          ...API.saveStudentAnswer.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, {analysis}).then(res => {
        // console.log(res);
        return res.response;
      });
    },
    getStudentAnswer: async studentAnswerId => {
      const {
        appContext: {
          callApi,
          authToken
        },
      } = this.props;
      return callApi(`${API.getStudentAnswer.url}/${studentAnswerId}`, {
        ...API.getStudentAnswer, headers: {
          ...API.getStudentAnswer.headers,
          "X-AUTH-TOKEN": authToken || "",
        },
      }, null).then(res => {
        return res.response;
      });
    },
  };

  public render() {
    const {children} = this.props;
    return (
        <ECGContextProvider value={this.state}>
          {children}
        </ECGContextProvider>
    )
  }

}

export interface WithECGContext {
  eCGContext: ECGContextInterface;
}

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export function withECGContext<P extends { eCGContext?: ECGContextInterface },
    R = Omit<P, 'eCGContext'>>(
    Component: React.ComponentClass<P> | React.StatelessComponent<P>
): React.SFC<R> {
  return function BoundComponent(props: R) {
    return (
        <ECGContextConsumer>
          {value => {
            // @ts-ignore
            return <Component {...props} eCGContext={value} />;
          }}
        </ECGContextConsumer>
    );
  };
}

export default withAppContext(ECGContext);
