import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";
import {
  AnalysisSTSegment,
    STSegmentCol1Options,
    STSegmentCol3Options,
    STSegmentDepressionOptions,
    STSegmentDepressionSubItemsOptions,
    STSegmentElevationOptions,
    STSegmentElevationSubItemsOptions,
    STSegmentRepolarisationAbnormalityOptions,
    STSegmentRepolarisationAbnormalitySubItemsOptions,
} from "../../../types/Analysis";

interface OwnProps {
  values: AnalysisSTSegment;
  onChange: (values:AnalysisSTSegment)=>void;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
      leftPadding: {
        paddingLeft: theme.spacing(2),
      },
    });

type Props = OwnProps & WithStyles<typeof styles>;

const initialState = {
  stSegmentCol1: [],
  stSegmentElevation: [],
  stSegmentElevationSubItems:[],
  stSegmentDepression: [],
  stSegmentDepressionSubItems:[],
  stSegmentRepolarisationAbnormality: [],
  stSegmentRepolarisationAbnormalitySubItems: [],
  stSegmentCol3: [],
};

class STSegmentStep extends React.Component<Props> {

  public render() {
    const {classes,values:{stSegmentCol1,stSegmentCol3,stSegmentDepression,stSegmentElevation,stSegmentRepolarisationAbnormality,stSegmentDepressionSubItems,stSegmentElevationSubItems,stSegmentRepolarisationAbnormalitySubItems}} = this.props;
    const disabled = this.checkStSegmentCol1Value();
    return (
        <Grid container>
          <Grid item xs={3}>
            <AnalysisCheckboxGroup value={stSegmentCol1 as string[]} onChange={this.handleChange} name="stSegmentCol1" options={STSegmentCol1Options}/>
          </Grid>
          <Grid item xs={5} className={classes.leftPadding}>
            <AnalysisCheckboxGroup disabled={disabled} value={stSegmentElevation as string[]} onChange={this.handleChange} name="stSegmentElevation" options={STSegmentElevationOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} subSection cols={4} value={stSegmentElevationSubItems as string[]} onChange={this.handleChange} name="stSegmentElevationSubItems" options={STSegmentElevationSubItemsOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} value={stSegmentDepression as string[]} onChange={this.handleChange} name="stSegmentDepression" options={STSegmentDepressionOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} subSection cols={4} value={stSegmentDepressionSubItems as string[]} onChange={this.handleChange} name="stSegmentDepressionSubItems" options={STSegmentDepressionSubItemsOptions}/>
          </Grid>
          <Grid item xs={4} className={classes.leftPadding}>
            <AnalysisCheckboxGroup disabled={disabled} value={stSegmentRepolarisationAbnormality as string[]} onChange={this.handleChange} name="stSegmentRepolarisationAbnormality" options={STSegmentRepolarisationAbnormalityOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} subSection value={stSegmentRepolarisationAbnormalitySubItems as string[]} onChange={this.handleChange} name="stSegmentRepolarisationAbnormalitySubItems" options={STSegmentRepolarisationAbnormalitySubItemsOptions}/>
            <AnalysisCheckboxGroup disabled={disabled} noSeparator value={stSegmentCol3 as string[]} onChange={this.handleChange} name="stSegmentCol3" options={STSegmentCol3Options}/>
          </Grid>
        </Grid>
    );
  }

  private checkStSegmentCol1Value = () => {
    const {values: {stSegmentCol1}} = this.props;
    return stSegmentCol1.indexOf("Normal ST segments") !== -1;
  };

  private handleChange = (name: keyof AnalysisSTSegment, value:string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "stSegmentCol1") {
        if (value.indexOf("Normal ST segments") !== -1) {
          this.props.onChange({...initialState,[name]:["Normal ST segments"]});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  }

}

// @ts-ignore
export default withStyles(styles)(STSegmentStep) as React.ComponentClass<OwnProps>;
