import * as React from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import AnalysisCheckboxGroup from "../components/AnalysisCheckboxGroup";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {AnalysisQRSComplex, QRSComplexAxisCol1Options, QRSComplexAxisCol2Options, QRSComplexAxisOptions, QRSComplexWidthOptions} from "../../../types/Analysis";
import AnalysisRadioGroup from "../components/AnalysisRadioGroup";
import Typography from "@material-ui/core/Typography";

interface OwnProps {
  values: AnalysisQRSComplex;
  onChange: (values:AnalysisQRSComplex)=>void;
  isMarking: boolean;
}

const styles = (theme: Theme) =>
    createStyles({
      separator: {
        marginTop: theme.spacing(2),
      },
      leftPadding: {
        paddingLeft: theme.spacing(2),
      },
    });


type Props = OwnProps & WithStyles<typeof styles>;

class QRSComplexStep extends React.Component<Props> {

  public render() {
    const {classes,values:{qrsComplexAxis,qrsComplexAxisCol1,qrsComplexAxisCol2,qrsComplexAxisMeasurement,qrsComplexWidth,qrsComplexWidthMeasurement}} = this.props;
    return (
        <Grid container>
          <Grid item xs={4}>
            <TextField
              label="QRS width"
              InputProps={{endAdornment:<InputAdornment position="end" ><Typography style={{fontSize: 12}}>ms</Typography></InputAdornment>}}
              inputProps={{min: 0, type: "number",style: {fontSize: 12}}}
              value={qrsComplexWidthMeasurement || ""}
              onChange={this.handleTextInputChange("qrsComplexWidthMeasurement")}
            />
            <AnalysisCheckboxGroup disabledFrom={this.checkQrsComplexWidthValue()} value={qrsComplexWidth as string[]} onChange={this.handleChange} name="qrsComplexWidth" options={QRSComplexWidthOptions}/>
          </Grid>
          <Grid item xs={8} className={classes.leftPadding}>
            <Grid container>
            <Grid item xs={6}>
            <TextField
                label="QRS axis"
                InputProps={{endAdornment:<InputAdornment position="end" ><Typography style={{fontSize: 12}}>degrees</Typography></InputAdornment>}}
                inputProps={{min: 0, type: "number",style: {fontSize: 12}}}
                value={qrsComplexAxisMeasurement || ""}
                onChange={this.handleTextInputChange("qrsComplexAxisMeasurement")}
            />
            </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <AnalysisRadioGroup value={qrsComplexAxis} onChange={this.handleChange} name="qrsComplexAxis" options={QRSComplexAxisOptions}/>
                <div style={{padding: 11}} />
                <AnalysisCheckboxGroup noSeparator value={qrsComplexAxisCol1 as string[]} onChange={this.handleChange} name="qrsComplexAxisCol1" options={QRSComplexAxisCol1Options}/>
              </Grid>
              <Grid item xs={6}>
                <AnalysisCheckboxGroup value={qrsComplexAxisCol2 as string[]} onChange={this.handleChange} name="qrsComplexAxisCol2" options={QRSComplexAxisCol2Options}/>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
    );
  }

  private checkQrsComplexWidthValue = () => {
    const {values:{qrsComplexWidth}} = this.props;
    return qrsComplexWidth.indexOf("Normal morphology") !== -1?1:null;
  };

  private handleTextInputChange = (name: keyof AnalysisQRSComplex) => (e: React.ChangeEvent<HTMLInputElement | any>) => {
    const {isMarking} = this.props;
    const {value} = e.currentTarget || e.target;
    !isMarking && this.props.onChange({...this.props.values,[name]:value});
  };

  private handleChange = (name: keyof AnalysisQRSComplex, value:string|string[]) => {
    const {isMarking} = this.props;
    if (!isMarking) {
      if (name === "qrsComplexWidth") {
        if (value.indexOf("Normal morphology") !== -1) {
          this.props.onChange({...this.props.values,[name]:["Normal morphology"]});
          return;
        }
      }
      this.props.onChange({...this.props.values,[name]:value});
    }
  };
}

// @ts-ignore
export default withStyles(styles)(QRSComplexStep) as React.ComponentClass<OwnProps>;
