import {Theme} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import Card from "@material-ui/core/Card/Card";
import CardActions from "@material-ui/core/CardActions/CardActions";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CardHeader from "@material-ui/core/CardHeader/CardHeader";
import Grid from "@material-ui/core/Grid/Grid";
import Hidden from "@material-ui/core/Hidden/Hidden";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import createStyles from "@material-ui/core/styles/createStyles";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography/Typography";
import Zoom from "@material-ui/core/Zoom/Zoom";
import Close from "@material-ui/icons/Close";
import * as React from "react";
import ECGs from "./ECGs";
import {Module, StudentResult} from "../../../types/module";
import {WithModuleContext, withModuleContext} from "../../../context/ModuleContext";
import {WithAppContext, withAppContext} from "../../../context/AppContext";
import {green} from "@material-ui/core/colors";
import {EditAnalysisFunction} from "../Main";
import {Fragment} from "react";

const styles = (theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.primary.light,
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },
    cardContent: {
      flexGrow: 1,
    },
    cardGrid: {
      padding: `${theme.spacing(8)}px 0`,
    },
    cardMedia: {
      paddingTop: "56.25%", // 16:9
    },
    grid: {
      transition: "width 0.7s",
      width: "auto",
    },
    layout: {
      marginLeft: theme.spacing(3),
      marginRight: theme.spacing(3),
      width: "auto",
      [theme.breakpoints.up(1100 + theme.spacing(6))]: {
        marginLeft: "auto",
        marginRight: "auto",
        width: 1100,
      },
    },
  });

//  TODO: fix anchorEl type
interface State {
  anchorEl: any;
}

// TODO: fix module type
// TODO: fix selected type
interface OwnProps {
  module: Module;
  selected: Module | null;
  removeFunction: (module: any) => void;
  addEditModuleFunction: () => void
  editAnalysisFunction: EditAnalysisFunction;
  studentResults: StudentResult[];
}

interface Props extends OwnProps, WithStyles<typeof styles>, WithModuleContext, WithAppContext {
}

class ModuleCard extends React.Component<Props, State> {

  public state = {
    anchorEl: null,
  };

  public render() {
    const {anchorEl} = this.state;
    const {
      module: mod, classes, editAnalysisFunction,
      appContext: {
        isStudent
      },
      studentResults
    } = this.props;
    const {id, code, isPublished, lecturer, name} = mod;
    const thisSelected = this.props.selected !== null && id === this.props.selected.id;
    const otherSelected = this.props.selected !== null && id !== this.props.selected.id;
    return (
      <Hidden only={!otherSelected ? [] : ["xs", "sm", "md", "lg", "xl"]}>
        <Grid className={classes.grid} item={true} sm={12} md={thisSelected ? 12 : 6}>
          <Card className={classes.card}>
            <CardHeader
              title={thisSelected ? "ECGs" : name}
              subheader={thisSelected ? "" : lecturer.name}
              action={
                <Fragment>
                  {!thisSelected && <Typography style={{padding: 8}}>
                    {code}
                  </Typography>}
                  <Zoom in={thisSelected}>
                    <IconButton onClick={this.handleSelectModule(null, "")}>
                      <Close />
                    </IconButton>
                  </Zoom>
                </Fragment>
              }
            />
            <CardContent className={classes.cardContent}>
              {thisSelected ?
                <ECGs editAnalysisFunction={editAnalysisFunction} studentResults={studentResults} /> :
                <Grid container={true} direction="row" spacing={8}>
                  <Grid item={true}>
                    {!isStudent && <Typography style={{color: isPublished ? green["500"] : ""}} color={isPublished ? "textPrimary" : "error"}>
                      {isPublished ? "Published" : "Not published"}
                    </Typography>}
                  </Grid>
                </Grid>
              }
            </CardContent>
            {!thisSelected && <CardActions>
              <Grid container={true} direction="row" justify="space-evenly" style={{paddingBottom: 8}}>
                {!isStudent && <Button
                  size="small"
                  color="primary"
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  Edit Module
                </Button>}
                <Button size="large" color="primary" variant="contained" onClick={this.handleSelectModule(mod, "modifyContent")}>
                  {isStudent ? "Analyse" : "Add / Edit"} ECGs
                </Button>
                {/*<Button size="small" color="primary" disabled={true}>*/}
                {/*  View Analysis*/}
                {/*</Button>*/}
              </Grid>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={this.handleAddEditModule}>Edit module name / code</MenuItem>
                <MenuItem onClick={this.handlePublishUnPublish}>{isPublished ? "Unpublish" : "Publish"} module</MenuItem>
                <MenuItem onClick={this.handleRemoveModule(mod)}>Remove module</MenuItem>
              </Menu>
            </CardActions>}
          </Card>
        </Grid>
      </Hidden>
    )
  }

  private handlePublishUnPublish = () => {
    const {moduleContext: {publishUnPublishModuleFn}, module} = this.props;
    this.handleClose();
    publishUnPublishModuleFn(module);
  };

  private handleAddEditModule = () => {
    const {addEditModuleFunction} = this.props;
    this.handleClose();
    addEditModuleFunction()
  };

  private handleRemoveModule = (mod: Module) => () => {
    const {removeFunction} = this.props;
    removeFunction(mod);
  };

  private handleSelectModule = (mod: Module | null, action: any, close: boolean = false) => () => {
    const {moduleContext: {selectModule}} = this.props;
    close && this.handleClose();
    selectModule(mod);
  };
  private handleClick = (event: React.MouseEvent) => {
    this.setState({anchorEl: event.currentTarget});
  };

  private handleClose = () => {
    this.setState({anchorEl: null});
  };
}

// @ts-ignore
export default withModuleContext(withStyles(styles)(withAppContext(ModuleCard))) as unknown as React.ComponentClass<OwnProps>;
