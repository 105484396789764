import AnalysesListLayout from "./AnalysesList.layout";
import {WithModuleContext, withModuleContext} from "../../context/ModuleContext";
import * as React from "react";
import {EditAnalysisFunction} from "../Main/Main";
import {WithAppContext, withAppContext} from "../../context/AppContext";

export type AnalysesListProps = {
  handleEditAnalysis: EditAnalysisFunction
} & WithModuleContext & WithAppContext;
const HOC:any = withAppContext(withModuleContext(AnalysesListLayout));

export default HOC;
