import TextField from "@material-ui/core/TextField/TextField";
import * as React from "react";
import withStyles, {WithStyles} from "@material-ui/core/styles/withStyles";
import createStyles from "@material-ui/core/styles/createStyles";

interface OwnProps {
  value: string;
  handleChange(e:React.ChangeEvent<any>):void;
  handleSubmit: (e: React.FormEvent)=>void;
}

const styles = () =>
    createStyles({
      form: {
        width: "100%",
      },
    });

const StepOneName = (props:OwnProps & WithStyles<typeof styles>) => {
  const {classes,value,handleSubmit,handleChange} = props;
  return(
      <React.Fragment>
        <form onSubmit={handleSubmit} className={classes.form}>
          <TextField
              autoFocus={true}
              margin="dense"
              id="name"
              label="ECG Name"
              type="text"
              fullWidth={true}
              value={value}
              name="name"
              onChange={handleChange}
              error={value.length > 0 && value.length < 3}
              required={true}
              helperText="Need to be at least 3 characters"
          />
        </form>
      </React.Fragment>
  );
};

// @ts-ignore
export default withStyles(styles)(StepOneName) as React.ComponentClass<OwnProps>;
