import { connect } from 'react-redux';
import Lectures from './Lectures';
import { listLecturesFn, createLectureFn, deleteLectureFn } from '../../redux/actions/lecture';
import { withTheme } from '@material-ui/core/styles';
import {withAppContext} from "../../context/AppContext";
const mapStateToProps = ({ LectureReducer, auth, appContext }, props) => ({
    ...props,
    LectureReducer,
    auth,
    appContext,
});

const mergeProps = (stateProps, { dispatch }, ownProps) => ({
    ...stateProps,
    ...ownProps,
    fnGetLectures() {
        console.log('do list');
        dispatch(listLecturesFn());
    },
    fnCreateLecture(payload) {
        console.log('Payload ', payload);
        dispatch(createLectureFn(payload));
    },
    fnDeleteLecture(payload) {
        dispatch(deleteLectureFn(payload));
    }
});

export default connect(mapStateToProps, null, mergeProps)(withTheme(withAppContext(Lectures)));
